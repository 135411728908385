import { grpc } from "@improbable-eng/grpc-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import styled, { ThemeProvider } from "styled-components/macro";
import { Account } from "./account/Account";
import { Activities } from "./account/Activities";
import { Authenticator } from "./account/Authenticator";
import { Sessions } from "./account/Sessions";
import { Users } from "./admin/Users";
import { getInjectedData, idpc } from "./api";
import {
  AuthContext,
  Branding,
  ClientProfile,
  GetBrandingRequest,
  UserProfile,
} from "./generated/idp/api/idp";
import { VerifyEmailAddressCallback } from "./login/Mail";
import { LoginFlow } from "./LoginFlow";
import { OAuth } from "./OAuth";
import { useLocation } from "./router";
import { SAML2 } from "./SAML2";

const Container = styled.div`
  font-family: sans-serif;
`;

interface AppState {
  loggedIn: boolean;
  profile: UserProfile | null;
  clientProfile?: ClientProfile;
  authCtx?: AuthContext;
}

function App(): JSX.Element {
  const location = useLocation();
  const [state, setState] = useState<AppState>({
    loggedIn: true,
    profile: null,
  });
  const inFrame = useMemo(() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }, []);

  const authCb = useCallback(
    (authCtx: AuthContext | null, clientProfile?: ClientProfile) => {
      setState((x) => ({
        loggedIn: false,
        clientProfile,
        profile: x.profile,
        authCtx: authCtx ?? x.authCtx,
      }));
    },
    []
  );
  const withLogin = useCallback(
    (
      X: React.FC<{
        profile: UserProfile | null;
        onLogout: () => void;
        authenticate: (a: AuthContext | null) => void;
      }>
    ) =>
      state.loggedIn ? (
        <X
          profile={state.profile}
          onLogout={async () => {
            try {
              await idpc.Logout({});
            } catch (err) {
              console.log(err);
            }
            setState((x) => ({
              loggedIn: false,
              profile: null,
              authCtx: x.authCtx,
            }));
          }}
          authenticate={authCb}
        />
      ) : (
        <LoginFlow
          onDone={(p) => {
            setState((x) => ({
              loggedIn: true,
              profile: p,
              authCtx: x.authCtx,
              clientProfile: x.clientProfile,
            }));
          }}
          authCtx={state.authCtx}
          clientProfile={state.clientProfile}
        />
      ),
    [state, authCb]
  );
  switch (location) {
    case "/":
      return withLogin(Account);
    case "/authenticator":
      return withLogin(Authenticator);
    case "/oauth2/authorize":
      return withLogin(OAuth);
    case "/saml2/sso":
      return withLogin(SAML2);
    case "/saml2/logout":
      return withLogin(SAML2);
    case "/sessions":
      return withLogin(Sessions);
    case "/activities":
      return withLogin(Activities);
    case "/admin/users":
      return withLogin(Users);
    case "/verify/email":
      return <VerifyEmailAddressCallback />;
    default:
      return <div>u got lost m8?</div>;
  }
}

function DisplayError(props: FallbackProps): JSX.Element {
  fetch("/api/collecterror", {
    method: "POST",
    body: new URLSearchParams({
      message: props.error.message,
      stack: props.error.stack ?? "",
    }),
  })
    .then(() => {})
    .catch(() => {});
  return (
    <>
      <h2>Es ist ein Fehler aufgetreten</h2>
      <pre>{props.error.message}</pre>
      <pre>{props.error.stack ?? ""}</pre>
    </>
  );
}

function AppWrapper(): JSX.Element {
  const [branding, setBranding] = useState<Branding | null>(null);
  useEffect(() => {
    const injected = getInjectedData(Branding);
    if (injected !== null) {
      setBranding(injected);
      return;
    }
    let ignore = false;
    const fetchData = async () => {
      try {
        const branding = await idpc.GetBranding(
          GetBrandingRequest.fromPartial({})
        );
        if (!ignore) setBranding(branding);
      } catch (err) {
        switch (err.code) {
          case grpc.Code.DeadlineExceeded:
          case grpc.Code.Canceled:
          case grpc.Code.Unavailable:
            if (!ignore) setTimeout(fetchData, 10000);
            break;
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <Container>
      <ErrorBoundary FallbackComponent={DisplayError}>
        <ThemeProvider
          theme={{
            branding: branding ?? undefined,
          }}
        >
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </Container>
  );
}

export default AppWrapper;
