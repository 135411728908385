/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../../google/protobuf/empty";
import { BrowserHeaders } from "browser-headers";
import { SignatureAlgorithm, PublicKey } from "../../../idp/api/cryptopb/cryptopb";
import { ClientProfile } from "../../../idp/api/idp";

export const protobufPackage = "idp.saml2";

export enum Binding {
  HTTP_POST = 0,
  HTTP_POST_SIMPLE_SIGN = 1,
  HTTP_REDIRECT = 2,
  UNRECOGNIZED = -1,
}

export enum NameIDFormat {
  UNSPECIFIED = 0,
  TRANSIENT = 1,
  PERSISTENT = 2,
  EMAIL_ADDRESS = 3,
  SHIBBOLETH = 4,
  UNRECOGNIZED = -1,
}

export interface AssertionConsumerService {
  $type: "idp.saml2.AssertionConsumerService";
  url: string;
  binding: Binding;
  index: number;
}

export interface SingleLogoutService {
  $type: "idp.saml2.SingleLogoutService";
  url: string;
  binding: Binding;
}

export interface AttributeSpec {
  $type: "idp.saml2.AttributeSpec";
  /**
   * If set to true, a multi-valued attribute named "groups" with all groups the
   * user is a member of is added.
   */
  enableGroups: boolean;
  enableName: boolean;
  enableEmail: boolean;
  /**
   * A JavaScript script which gets the User in protojson form as the "user"
   * global and can add attributes to the "attributes" global.
   */
  mappingScript: string;
}

/** SAML2 ServiceProvider config */
export interface ServiceProvider {
  $type: "idp.saml2.ServiceProvider";
  /** SAML2 Entity ID */
  id: string;
  /**
   * List of assertion consumer services and associated bindings and indices.
   * The first acceptable service is used, so list them in order of preference.
   */
  assertionConsumerService: AssertionConsumerService[];
  /** Acceptable NameIDFormats in order of preference. */
  nameIdFormat: NameIDFormat[];
  /**
   * Supported signature algorithms in order of preference. Note that only the
   * RS* family of RSA-based signature algorithms is supported currently.
   */
  signatureAlgorithm: SignatureAlgorithm[];
  /** Service to which Logout requests and responses should be directed to. */
  singleLogoutService: SingleLogoutService | undefined;
  /** Keys used to sign requests */
  signingKey: PublicKey[];
  /**
   * URL to redirect the user to to get a new request. This is used to resolve
   * situations where we don't have a valid request from the SP anymore like
   * when it expired or the user used the back button to get to the IDP.
   */
  newLoginUrl: string;
  /** Controls attributes passed to this SP */
  attributes: AttributeSpec | undefined;
  /** Only issue when user is a member of any of these groups */
  restrictToGroup: string[];
  /** Only issue when authentication fulfills any of these classes */
  restrictToAcr: string[];
  profile: ClientProfile | undefined;
}

/**
 * RedirectMessage contains a SAML message represended for the redirect binding.
 * These are the raw, url-encoded values as they are needed for verifying the
 * signature. See 3.4.4.1 of the SAML 2.0 Bindings standard.
 */
export interface RedirectMessage {
  $type: "idp.saml2.RedirectMessage";
  request: string;
  relayState: string;
  signatureAlgorithm: string;
  /** URL and Base64-decoded */
  signature: Uint8Array;
}

/**
 * POSTMessage contains a SAML message represented for the POST binding (See
 * Section 3.5).
 */
export interface POSTMessage {
  $type: "idp.saml2.POSTMessage";
  /** request contains the raw XML obtained by decoding the base64 payload. */
  request: Uint8Array;
  relayState: string;
}

/** Message contains a SAML message in any of the supported bindings. */
export interface Message {
  $type: "idp.saml2.Message";
  binding?: { $case: "post"; post: POSTMessage } | { $case: "redirect"; redirect: RedirectMessage };
}

export interface SSORequest {
  $type: "idp.saml2.SSORequest";
  request: Message | undefined;
}

export interface SSOResponse {
  $type: "idp.saml2.SSOResponse";
  response: Message | undefined;
  url: string;
}

export interface LogoutRequest {
  $type: "idp.saml2.LogoutRequest";
  request: Message | undefined;
}

export interface LogoutResponse {
  $type: "idp.saml2.LogoutResponse";
  response: Message | undefined;
  url: string;
}

export interface Consent {
  $type: "idp.saml2.Consent";
  serviceProviderId: string;
  subject: string;
}

export interface ListServiceProvidersRequest {
  $type: "idp.saml2.ListServiceProvidersRequest";
}

export interface ListServiceProvidersResponse {
  $type: "idp.saml2.ListServiceProvidersResponse";
  serviceProvider: ServiceProvider[];
}

export interface CreateServiceProviderRequest {
  $type: "idp.saml2.CreateServiceProviderRequest";
  serviceProvider: ServiceProvider | undefined;
  failIfAlreadyExists: boolean;
}

export interface DeleteServiceProviderRequest {
  $type: "idp.saml2.DeleteServiceProviderRequest";
  id: string;
}

const baseAssertionConsumerService: object = {
  $type: "idp.saml2.AssertionConsumerService",
  url: "",
  binding: 0,
  index: 0,
};

export const AssertionConsumerService = {
  $type: "idp.saml2.AssertionConsumerService" as const,

  encode(message: AssertionConsumerService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.binding !== 0) {
      writer.uint32(16).int32(message.binding);
    }
    if (message.index !== 0) {
      writer.uint32(24).int32(message.index);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertionConsumerService {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAssertionConsumerService } as AssertionConsumerService;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.url = reader.string();
          break;
        case 2:
          message.binding = reader.int32() as any;
          break;
        case 3:
          message.index = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AssertionConsumerService>): AssertionConsumerService {
    const message = { ...baseAssertionConsumerService } as AssertionConsumerService;
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    }
    if (object.binding !== undefined && object.binding !== null) {
      message.binding = object.binding;
    }
    if (object.index !== undefined && object.index !== null) {
      message.index = object.index;
    }
    return message;
  },
};

messageTypeRegistry.set(AssertionConsumerService.$type, AssertionConsumerService);

const baseSingleLogoutService: object = {
  $type: "idp.saml2.SingleLogoutService",
  url: "",
  binding: 0,
};

export const SingleLogoutService = {
  $type: "idp.saml2.SingleLogoutService" as const,

  encode(message: SingleLogoutService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.binding !== 0) {
      writer.uint32(16).int32(message.binding);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleLogoutService {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSingleLogoutService } as SingleLogoutService;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.url = reader.string();
          break;
        case 2:
          message.binding = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SingleLogoutService>): SingleLogoutService {
    const message = { ...baseSingleLogoutService } as SingleLogoutService;
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    }
    if (object.binding !== undefined && object.binding !== null) {
      message.binding = object.binding;
    }
    return message;
  },
};

messageTypeRegistry.set(SingleLogoutService.$type, SingleLogoutService);

const baseAttributeSpec: object = {
  $type: "idp.saml2.AttributeSpec",
  enableGroups: false,
  enableName: false,
  enableEmail: false,
  mappingScript: "",
};

export const AttributeSpec = {
  $type: "idp.saml2.AttributeSpec" as const,

  encode(message: AttributeSpec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enableGroups === true) {
      writer.uint32(8).bool(message.enableGroups);
    }
    if (message.enableName === true) {
      writer.uint32(16).bool(message.enableName);
    }
    if (message.enableEmail === true) {
      writer.uint32(24).bool(message.enableEmail);
    }
    if (message.mappingScript !== "") {
      writer.uint32(42).string(message.mappingScript);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttributeSpec {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAttributeSpec } as AttributeSpec;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.enableGroups = reader.bool();
          break;
        case 2:
          message.enableName = reader.bool();
          break;
        case 3:
          message.enableEmail = reader.bool();
          break;
        case 5:
          message.mappingScript = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AttributeSpec>): AttributeSpec {
    const message = { ...baseAttributeSpec } as AttributeSpec;
    if (object.enableGroups !== undefined && object.enableGroups !== null) {
      message.enableGroups = object.enableGroups;
    }
    if (object.enableName !== undefined && object.enableName !== null) {
      message.enableName = object.enableName;
    }
    if (object.enableEmail !== undefined && object.enableEmail !== null) {
      message.enableEmail = object.enableEmail;
    }
    if (object.mappingScript !== undefined && object.mappingScript !== null) {
      message.mappingScript = object.mappingScript;
    }
    return message;
  },
};

messageTypeRegistry.set(AttributeSpec.$type, AttributeSpec);

const baseServiceProvider: object = {
  $type: "idp.saml2.ServiceProvider",
  id: "",
  nameIdFormat: 0,
  signatureAlgorithm: 0,
  newLoginUrl: "",
  restrictToGroup: "",
  restrictToAcr: "",
};

export const ServiceProvider = {
  $type: "idp.saml2.ServiceProvider" as const,

  encode(message: ServiceProvider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.assertionConsumerService) {
      AssertionConsumerService.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.nameIdFormat) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.signatureAlgorithm) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.singleLogoutService !== undefined) {
      SingleLogoutService.encode(message.singleLogoutService, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.signingKey) {
      PublicKey.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.newLoginUrl !== "") {
      writer.uint32(82).string(message.newLoginUrl);
    }
    if (message.attributes !== undefined) {
      AttributeSpec.encode(message.attributes, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.restrictToGroup) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.restrictToAcr) {
      writer.uint32(74).string(v!);
    }
    if (message.profile !== undefined) {
      ClientProfile.encode(message.profile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceProvider {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseServiceProvider } as ServiceProvider;
    message.assertionConsumerService = [];
    message.nameIdFormat = [];
    message.signatureAlgorithm = [];
    message.signingKey = [];
    message.restrictToGroup = [];
    message.restrictToAcr = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.assertionConsumerService.push(
            AssertionConsumerService.decode(reader, reader.uint32())
          );
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.nameIdFormat.push(reader.int32() as any);
            }
          } else {
            message.nameIdFormat.push(reader.int32() as any);
          }
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.signatureAlgorithm.push(reader.int32() as any);
            }
          } else {
            message.signatureAlgorithm.push(reader.int32() as any);
          }
          break;
        case 6:
          message.singleLogoutService = SingleLogoutService.decode(reader, reader.uint32());
          break;
        case 8:
          message.signingKey.push(PublicKey.decode(reader, reader.uint32()));
          break;
        case 10:
          message.newLoginUrl = reader.string();
          break;
        case 11:
          message.attributes = AttributeSpec.decode(reader, reader.uint32());
          break;
        case 7:
          message.restrictToGroup.push(reader.string());
          break;
        case 9:
          message.restrictToAcr.push(reader.string());
          break;
        case 4:
          message.profile = ClientProfile.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ServiceProvider>): ServiceProvider {
    const message = { ...baseServiceProvider } as ServiceProvider;
    message.assertionConsumerService = [];
    message.nameIdFormat = [];
    message.signatureAlgorithm = [];
    message.signingKey = [];
    message.restrictToGroup = [];
    message.restrictToAcr = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.assertionConsumerService !== undefined && object.assertionConsumerService !== null) {
      for (const e of object.assertionConsumerService) {
        message.assertionConsumerService.push(AssertionConsumerService.fromPartial(e));
      }
    }
    if (object.nameIdFormat !== undefined && object.nameIdFormat !== null) {
      for (const e of object.nameIdFormat) {
        message.nameIdFormat.push(e);
      }
    }
    if (object.signatureAlgorithm !== undefined && object.signatureAlgorithm !== null) {
      for (const e of object.signatureAlgorithm) {
        message.signatureAlgorithm.push(e);
      }
    }
    if (object.singleLogoutService !== undefined && object.singleLogoutService !== null) {
      message.singleLogoutService = SingleLogoutService.fromPartial(object.singleLogoutService);
    }
    if (object.signingKey !== undefined && object.signingKey !== null) {
      for (const e of object.signingKey) {
        message.signingKey.push(PublicKey.fromPartial(e));
      }
    }
    if (object.newLoginUrl !== undefined && object.newLoginUrl !== null) {
      message.newLoginUrl = object.newLoginUrl;
    }
    if (object.attributes !== undefined && object.attributes !== null) {
      message.attributes = AttributeSpec.fromPartial(object.attributes);
    }
    if (object.restrictToGroup !== undefined && object.restrictToGroup !== null) {
      for (const e of object.restrictToGroup) {
        message.restrictToGroup.push(e);
      }
    }
    if (object.restrictToAcr !== undefined && object.restrictToAcr !== null) {
      for (const e of object.restrictToAcr) {
        message.restrictToAcr.push(e);
      }
    }
    if (object.profile !== undefined && object.profile !== null) {
      message.profile = ClientProfile.fromPartial(object.profile);
    }
    return message;
  },
};

messageTypeRegistry.set(ServiceProvider.$type, ServiceProvider);

const baseRedirectMessage: object = {
  $type: "idp.saml2.RedirectMessage",
  request: "",
  relayState: "",
  signatureAlgorithm: "",
};

export const RedirectMessage = {
  $type: "idp.saml2.RedirectMessage" as const,

  encode(message: RedirectMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== "") {
      writer.uint32(10).string(message.request);
    }
    if (message.relayState !== "") {
      writer.uint32(18).string(message.relayState);
    }
    if (message.signatureAlgorithm !== "") {
      writer.uint32(26).string(message.signatureAlgorithm);
    }
    if (message.signature.length !== 0) {
      writer.uint32(34).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RedirectMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRedirectMessage } as RedirectMessage;
    message.signature = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.request = reader.string();
          break;
        case 2:
          message.relayState = reader.string();
          break;
        case 3:
          message.signatureAlgorithm = reader.string();
          break;
        case 4:
          message.signature = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RedirectMessage>): RedirectMessage {
    const message = { ...baseRedirectMessage } as RedirectMessage;
    if (object.request !== undefined && object.request !== null) {
      message.request = object.request;
    }
    if (object.relayState !== undefined && object.relayState !== null) {
      message.relayState = object.relayState;
    }
    if (object.signatureAlgorithm !== undefined && object.signatureAlgorithm !== null) {
      message.signatureAlgorithm = object.signatureAlgorithm;
    }
    if (object.signature !== undefined && object.signature !== null) {
      message.signature = object.signature;
    }
    return message;
  },
};

messageTypeRegistry.set(RedirectMessage.$type, RedirectMessage);

const basePOSTMessage: object = { $type: "idp.saml2.POSTMessage", relayState: "" };

export const POSTMessage = {
  $type: "idp.saml2.POSTMessage" as const,

  encode(message: POSTMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request.length !== 0) {
      writer.uint32(10).bytes(message.request);
    }
    if (message.relayState !== "") {
      writer.uint32(18).string(message.relayState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): POSTMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePOSTMessage } as POSTMessage;
    message.request = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.request = reader.bytes();
          break;
        case 2:
          message.relayState = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<POSTMessage>): POSTMessage {
    const message = { ...basePOSTMessage } as POSTMessage;
    if (object.request !== undefined && object.request !== null) {
      message.request = object.request;
    }
    if (object.relayState !== undefined && object.relayState !== null) {
      message.relayState = object.relayState;
    }
    return message;
  },
};

messageTypeRegistry.set(POSTMessage.$type, POSTMessage);

const baseMessage: object = { $type: "idp.saml2.Message" };

export const Message = {
  $type: "idp.saml2.Message" as const,

  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.binding?.$case === "post") {
      POSTMessage.encode(message.binding.post, writer.uint32(10).fork()).ldelim();
    }
    if (message.binding?.$case === "redirect") {
      RedirectMessage.encode(message.binding.redirect, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMessage } as Message;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.binding = { $case: "post", post: POSTMessage.decode(reader, reader.uint32()) };
          break;
        case 2:
          message.binding = {
            $case: "redirect",
            redirect: RedirectMessage.decode(reader, reader.uint32()),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Message>): Message {
    const message = { ...baseMessage } as Message;
    if (
      object.binding?.$case === "post" &&
      object.binding?.post !== undefined &&
      object.binding?.post !== null
    ) {
      message.binding = { $case: "post", post: POSTMessage.fromPartial(object.binding.post) };
    }
    if (
      object.binding?.$case === "redirect" &&
      object.binding?.redirect !== undefined &&
      object.binding?.redirect !== null
    ) {
      message.binding = {
        $case: "redirect",
        redirect: RedirectMessage.fromPartial(object.binding.redirect),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(Message.$type, Message);

const baseSSORequest: object = { $type: "idp.saml2.SSORequest" };

export const SSORequest = {
  $type: "idp.saml2.SSORequest" as const,

  encode(message: SSORequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Message.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSORequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSSORequest } as SSORequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.request = Message.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SSORequest>): SSORequest {
    const message = { ...baseSSORequest } as SSORequest;
    if (object.request !== undefined && object.request !== null) {
      message.request = Message.fromPartial(object.request);
    }
    return message;
  },
};

messageTypeRegistry.set(SSORequest.$type, SSORequest);

const baseSSOResponse: object = { $type: "idp.saml2.SSOResponse", url: "" };

export const SSOResponse = {
  $type: "idp.saml2.SSOResponse" as const,

  encode(message: SSOResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== undefined) {
      Message.encode(message.response, writer.uint32(26).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SSOResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSSOResponse } as SSOResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.response = Message.decode(reader, reader.uint32());
          break;
        case 4:
          message.url = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SSOResponse>): SSOResponse {
    const message = { ...baseSSOResponse } as SSOResponse;
    if (object.response !== undefined && object.response !== null) {
      message.response = Message.fromPartial(object.response);
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    }
    return message;
  },
};

messageTypeRegistry.set(SSOResponse.$type, SSOResponse);

const baseLogoutRequest: object = { $type: "idp.saml2.LogoutRequest" };

export const LogoutRequest = {
  $type: "idp.saml2.LogoutRequest" as const,

  encode(message: LogoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Message.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLogoutRequest } as LogoutRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.request = Message.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<LogoutRequest>): LogoutRequest {
    const message = { ...baseLogoutRequest } as LogoutRequest;
    if (object.request !== undefined && object.request !== null) {
      message.request = Message.fromPartial(object.request);
    }
    return message;
  },
};

messageTypeRegistry.set(LogoutRequest.$type, LogoutRequest);

const baseLogoutResponse: object = { $type: "idp.saml2.LogoutResponse", url: "" };

export const LogoutResponse = {
  $type: "idp.saml2.LogoutResponse" as const,

  encode(message: LogoutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== undefined) {
      Message.encode(message.response, writer.uint32(10).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLogoutResponse } as LogoutResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.response = Message.decode(reader, reader.uint32());
          break;
        case 2:
          message.url = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<LogoutResponse>): LogoutResponse {
    const message = { ...baseLogoutResponse } as LogoutResponse;
    if (object.response !== undefined && object.response !== null) {
      message.response = Message.fromPartial(object.response);
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    }
    return message;
  },
};

messageTypeRegistry.set(LogoutResponse.$type, LogoutResponse);

const baseConsent: object = { $type: "idp.saml2.Consent", serviceProviderId: "", subject: "" };

export const Consent = {
  $type: "idp.saml2.Consent" as const,

  encode(message: Consent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceProviderId !== "") {
      writer.uint32(10).string(message.serviceProviderId);
    }
    if (message.subject !== "") {
      writer.uint32(18).string(message.subject);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseConsent } as Consent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceProviderId = reader.string();
          break;
        case 2:
          message.subject = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Consent>): Consent {
    const message = { ...baseConsent } as Consent;
    if (object.serviceProviderId !== undefined && object.serviceProviderId !== null) {
      message.serviceProviderId = object.serviceProviderId;
    }
    if (object.subject !== undefined && object.subject !== null) {
      message.subject = object.subject;
    }
    return message;
  },
};

messageTypeRegistry.set(Consent.$type, Consent);

const baseListServiceProvidersRequest: object = { $type: "idp.saml2.ListServiceProvidersRequest" };

export const ListServiceProvidersRequest = {
  $type: "idp.saml2.ListServiceProvidersRequest" as const,

  encode(_: ListServiceProvidersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListServiceProvidersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListServiceProvidersRequest } as ListServiceProvidersRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListServiceProvidersRequest>): ListServiceProvidersRequest {
    const message = { ...baseListServiceProvidersRequest } as ListServiceProvidersRequest;
    return message;
  },
};

messageTypeRegistry.set(ListServiceProvidersRequest.$type, ListServiceProvidersRequest);

const baseListServiceProvidersResponse: object = {
  $type: "idp.saml2.ListServiceProvidersResponse",
};

export const ListServiceProvidersResponse = {
  $type: "idp.saml2.ListServiceProvidersResponse" as const,

  encode(
    message: ListServiceProvidersResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.serviceProvider) {
      ServiceProvider.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListServiceProvidersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListServiceProvidersResponse } as ListServiceProvidersResponse;
    message.serviceProvider = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceProvider.push(ServiceProvider.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListServiceProvidersResponse>): ListServiceProvidersResponse {
    const message = { ...baseListServiceProvidersResponse } as ListServiceProvidersResponse;
    message.serviceProvider = [];
    if (object.serviceProvider !== undefined && object.serviceProvider !== null) {
      for (const e of object.serviceProvider) {
        message.serviceProvider.push(ServiceProvider.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(ListServiceProvidersResponse.$type, ListServiceProvidersResponse);

const baseCreateServiceProviderRequest: object = {
  $type: "idp.saml2.CreateServiceProviderRequest",
  failIfAlreadyExists: false,
};

export const CreateServiceProviderRequest = {
  $type: "idp.saml2.CreateServiceProviderRequest" as const,

  encode(
    message: CreateServiceProviderRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.serviceProvider !== undefined) {
      ServiceProvider.encode(message.serviceProvider, writer.uint32(10).fork()).ldelim();
    }
    if (message.failIfAlreadyExists === true) {
      writer.uint32(16).bool(message.failIfAlreadyExists);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateServiceProviderRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateServiceProviderRequest } as CreateServiceProviderRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceProvider = ServiceProvider.decode(reader, reader.uint32());
          break;
        case 2:
          message.failIfAlreadyExists = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<CreateServiceProviderRequest>): CreateServiceProviderRequest {
    const message = { ...baseCreateServiceProviderRequest } as CreateServiceProviderRequest;
    if (object.serviceProvider !== undefined && object.serviceProvider !== null) {
      message.serviceProvider = ServiceProvider.fromPartial(object.serviceProvider);
    }
    if (object.failIfAlreadyExists !== undefined && object.failIfAlreadyExists !== null) {
      message.failIfAlreadyExists = object.failIfAlreadyExists;
    }
    return message;
  },
};

messageTypeRegistry.set(CreateServiceProviderRequest.$type, CreateServiceProviderRequest);

const baseDeleteServiceProviderRequest: object = {
  $type: "idp.saml2.DeleteServiceProviderRequest",
  id: "",
};

export const DeleteServiceProviderRequest = {
  $type: "idp.saml2.DeleteServiceProviderRequest" as const,

  encode(
    message: DeleteServiceProviderRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteServiceProviderRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteServiceProviderRequest } as DeleteServiceProviderRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<DeleteServiceProviderRequest>): DeleteServiceProviderRequest {
    const message = { ...baseDeleteServiceProviderRequest } as DeleteServiceProviderRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(DeleteServiceProviderRequest.$type, DeleteServiceProviderRequest);

export interface SAML2 {
  SSO(request: DeepPartial<SSORequest>, metadata?: grpc.Metadata): Promise<SSOResponse>;
  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse>;
}

export class SAML2ClientImpl implements SAML2 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  SSO(request: DeepPartial<SSORequest>, metadata?: grpc.Metadata): Promise<SSOResponse> {
    return this.rpc.unary(SAML2SSODesc, SSORequest.fromPartial(request), metadata);
  }

  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse> {
    return this.rpc.unary(SAML2LogoutDesc, LogoutRequest.fromPartial(request), metadata);
  }
}

export const SAML2Desc = {
  serviceName: "idp.saml2.SAML2",
};

export const SAML2SSODesc: UnaryMethodDefinitionish = {
  methodName: "SSO",
  service: SAML2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SSORequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SSOResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const SAML2LogoutDesc: UnaryMethodDefinitionish = {
  methodName: "Logout",
  service: SAML2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...LogoutResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface SAML2Admin {
  ListServiceProviders(
    request: DeepPartial<ListServiceProvidersRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListServiceProvidersResponse>;
  CreateServiceProvider(
    request: DeepPartial<CreateServiceProviderRequest>,
    metadata?: grpc.Metadata
  ): Promise<ServiceProvider>;
  DeleteServiceProvider(
    request: DeepPartial<DeleteServiceProviderRequest>,
    metadata?: grpc.Metadata
  ): Promise<Empty>;
}

export class SAML2AdminClientImpl implements SAML2Admin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  ListServiceProviders(
    request: DeepPartial<ListServiceProvidersRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListServiceProvidersResponse> {
    return this.rpc.unary(
      SAML2AdminListServiceProvidersDesc,
      ListServiceProvidersRequest.fromPartial(request),
      metadata
    );
  }

  CreateServiceProvider(
    request: DeepPartial<CreateServiceProviderRequest>,
    metadata?: grpc.Metadata
  ): Promise<ServiceProvider> {
    return this.rpc.unary(
      SAML2AdminCreateServiceProviderDesc,
      CreateServiceProviderRequest.fromPartial(request),
      metadata
    );
  }

  DeleteServiceProvider(
    request: DeepPartial<DeleteServiceProviderRequest>,
    metadata?: grpc.Metadata
  ): Promise<Empty> {
    return this.rpc.unary(
      SAML2AdminDeleteServiceProviderDesc,
      DeleteServiceProviderRequest.fromPartial(request),
      metadata
    );
  }
}

export const SAML2AdminDesc = {
  serviceName: "idp.saml2.SAML2Admin",
};

export const SAML2AdminListServiceProvidersDesc: UnaryMethodDefinitionish = {
  methodName: "ListServiceProviders",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListServiceProvidersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ListServiceProvidersResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const SAML2AdminCreateServiceProviderDesc: UnaryMethodDefinitionish = {
  methodName: "CreateServiceProvider",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateServiceProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ServiceProvider.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const SAML2AdminDeleteServiceProviderDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteServiceProvider",
  service: SAML2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteServiceProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Empty.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
