import { useCallback, useEffect, useState } from "react";
import { Nav } from "./Nav";
import Icon from "@mdi/react";
import styled from "styled-components/macro";
import {
  AuthContext,
  TOTPAuthenticator,
  User,
  WebauthnAuthenticator,
} from "../generated/idp/api/idp";
import { getErrorDetail, idpc } from "../api";
import { grpc } from "@improbable-eng/grpc-web";
import {
  mdiCellphoneAndroid,
  mdiDeleteOutline,
  mdiKey,
  mdiWindowClose,
} from "@mdi/js";
import { lighten } from "polished";
import Modal from "react-modal";
import { AddTOTPAuthenticator } from "../login/TOTP";
import { Button, Primary } from "../Button";
import { AddWebauthn } from "../login/Webauthn";

const FaList = styled.div`
  margin-top: 10px;
  max-width: 300px;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "sans-serif",
  },
};

const Title = styled.h2`
  margin-bottom: 6px;
  margin-top: 25px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  padding-top: 5px;
`;

export function Authenticator(props: {
  authenticate: (a: AuthContext) => void;
}) {
  const [user, setUser] = useState<User | null>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [nonce, setNonce] = useState<Uint8Array | undefined>();
  const [modalTOTPOpen, setModalTOTPOpen] = useState(false);
  const [modalKeyIsOpen, setModalKeyOpen] = useState(false);

  const authenticate = props.authenticate;

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await idpc.GetSelf({});
        if (!ignore || update) {
          setUser(res.user ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          authenticate({
            $type: "idp.AuthContext",
            authenticationContextClass: [],
            maxAgeSeconds: 99999999,
          });
        }
        let ia = getErrorDetail(err, AuthContext);
        if (ia !== null) {
          authenticate(ia);
        }
      }
      try {
        const res = await idpc.AuthStart({
          authCtx: {
            authenticationContextClass: [],
            maxAgeSeconds: 99999999,
          },
        });
        if (!ignore || update) {
          setNonce(res.authContinue?.nonce);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    return () => {
      ignore = true;
      setUpdate(false);
    };
  }, [authenticate, update]);

  //check if user is null and display loading message
  if (user === null) {
    return <div></div>;
  }

  return (
    <div>
      <Title>TOTP</Title>
      <Modal
        isOpen={modalTOTPOpen}
        onRequestClose={() => setModalTOTPOpen(false)}
        style={customStyles}
      >
        <ModalTitle>
          <h1>TOTP</h1>
          <CloseButton onClick={() => setModalTOTPOpen(false)}>
            <Icon path={mdiWindowClose} size={1} />
          </CloseButton>
        </ModalTitle>
        <AddTOTPAuthenticator
          user={user.profile?.name ?? ""}
          authRecoverCb={() => {
            setModalTOTPOpen(false);
            setUpdate(true);
          }}
        ></AddTOTPAuthenticator>
      </Modal>
      <FaList>
        {user.totpAuthenticator.map((ta) => (
          <TOTPAuthenticatorItem spec={ta} />
        ))}
      </FaList>

      <Button color={Primary} onClick={() => setModalTOTPOpen(true)}>
        TOTP Hinzufügen
      </Button>

      <Title>USB Sicherheitsschlüssel</Title>
      <FaList>
        {user?.webauthnAuthenticator.map((wa) => (
          <WebauthnFactorItem spec={wa} />
        ))}
      </FaList>
      <Modal
        isOpen={modalKeyIsOpen}
        onRequestClose={() => setModalKeyOpen(false)}
        style={customStyles}
      >
        <ModalTitle>
          <h1>USB Sicherheitsschlüssel</h1>
          <CloseButton onClick={() => setModalKeyOpen(false)}>
            <Icon path={mdiWindowClose} size={1} />
          </CloseButton>
        </ModalTitle>

        {user.profile !== undefined && nonce !== undefined ? (
          <AddWebauthn
            nonce={nonce}
            userProfile={user.profile!}
            userId={user.id}
            otherCreds={user.webauthnAuthenticator.map((wa) => wa.id)}
            authRecover={() => {
              setModalKeyOpen(false);
              setUpdate(true);
            }}
          ></AddWebauthn>
        ) : (
          <div>
            <p>Bitte warten, während dein Profil lädt.</p>
          </div>
        )}
      </Modal>
      <Button color={Primary} onClick={() => setModalKeyOpen(true)}>
        USB-Sicherheitsschlüssel hinzufügen
      </Button>
    </div>
  );
}

export function WebauthnFactorItem(props: {
  spec: WebauthnAuthenticator;
}): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveWebauthn({
      id: props.spec.id,
    });
  }, [props]);
  return (
    <DeviceItem icon={mdiKey} name={props.spec.name} onDelete={onDeleteCb} />
  );
}

export function TOTPAuthenticatorItem(props: {
  spec: TOTPAuthenticator;
  onClick?: () => void;
}): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveTOTPAuthenticator({
      id: props.spec.id,
    });
  }, [props]);
  return (
    <DeviceItem
      icon={mdiCellphoneAndroid}
      name={props.spec.name}
      onDelete={onDeleteCb}
    />
  );
}

const DeviceContainer = styled.div`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
`;

const DeleteButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;

const ItemDeleteIcon = styled(Icon)`
  color: #86cecb;
  :hover {
    background: ${lighten(0.05, "#d6d6d6")};
  }
`;

const ItemText = styled.div`
  flex-grow: 1;
`;

export function DeviceItem(props: {
  icon: string;
  name: string;
  onDelete: () => void;
}): JSX.Element {
  return (
    <DeviceContainer>
      <Icon path={props.icon} size={1} style={{ marginRight: "10px" }} />
      <ItemText>{props.name}</ItemText>
      <DeleteButton onClick={props.onDelete}>
        <ItemDeleteIcon path={mdiDeleteOutline} size={1} />
      </DeleteButton>
    </DeviceContainer>
  );
}
