import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Line = styled.div`
  border-top: 2px solid #aaa;
  flex-grow: 1;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export function OrLine(props: { text: string }) {
  return (
    <Container>
      <Line />
      <Text>{props.text}</Text>
      <Line />
    </Container>
  );
}
