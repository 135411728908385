import { darken, lighten, readableColor } from "polished";
import styled, { useTheme } from "styled-components";

export const Primary = "#9f9f9f";
export const Secondary = "#CCCCCC";
export const Danger = "#B84646";

const ButtonElement = styled.button<{ color: string }>`
  background-color: ${(p) => p.color};
  border-radius: 21.5px;
  border: none;
  box-shadow: 0 0 5px #bbb;
  color: ${(p) => readableColor(p.color)};
  margin-top: 15px;
  min-width: 136px;
  min-height: 43px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: ${(p) => lighten(0.1, p.color)};
  }
  :active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
    background-color: ${(p) => darken(0.05, p.color)};
  }
`;

export function Button(props: {
  children?: React.ReactNode;
  loading?: boolean;
  color?: string;
  onClick?: () => void;
  type?: "button" | "submit";
}) {
  const theme = useTheme();
  const brandingColor =
    (theme.branding?.buttonColor ?? "").length > 0 ? theme.branding!.buttonColor : Primary;
  return (
    <ButtonElement color={props.color ?? brandingColor} onClick={props.onClick} type={props.type}>
      {props.children}
    </ButtonElement>
  );
}
