import { grpc } from "@improbable-eng/grpc-web";
import { mdiKey } from "@mdi/js";
import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { hasLocalStorage, idpc } from "../api";
import { Button } from "../Button";
import {
  AuthContext,
  AuthContinue,
  UserProfile,
  WebauthnAuthenticator,
} from "../generated/idp/api/idp";
import { Input } from "../Input";
import { DeviceItem } from "./DeviceItem";

const Container = styled.div``;

const Prompt = styled.div`
  color: #505050;
`;

const StatusContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const hasWebauthn = window.PublicKeyCredential ? true : false;

export function hasWebauthnDiscoverableCreds(): boolean {
  if (!hasWebauthn) return false;
  // Android currently does not support discoverable credentials at all
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1274263#c3
  if (window.navigator.userAgentData?.platform === "Android") return false;
  // Assume true if no storage is available
  if (!hasLocalStorage) return true;
  try {
    const dcLastErrRaw = window.localStorage.getItem("discoverableCredentialsLastError");
    if (dcLastErrRaw === null) return true;
    if (parseInt(dcLastErrRaw) + 6 * 30 * 24 * 60 * 60 * 1000 > new Date().getTime()) return false;
    return true;
  } catch (err) {
    return true;
  }
}

export function WebauthnDirectButton(props: {
  nonce: Uint8Array;
  authCtx?: AuthContext;
  spec: WebauthnAuthenticator[];
  authContinue: (c: AuthContinue) => void;
  authRecover: () => void;
}): JSX.Element {
  const { authContinue, authCtx, authRecover, nonce, spec } = props;

  const onAuth = useCallback(async () => {
    try {
      const cred = await navigator.credentials.get({
        publicKey: {
          challenge: nonce,
          userVerification: "required",
          rpId: window.location.hostname,
          allowCredentials:
            spec.length > 0 ? spec.map((x) => ({ type: "public-key", id: x.id })) : undefined,
        },
      });
      if (cred instanceof PublicKeyCredential) {
        const assertion = cred.response;
        if (assertion instanceof AuthenticatorAssertionResponse) {
          try {
            const res = await idpc.AuthWebauthn({
              id: new Uint8Array(cred.rawId),
              authenticatorData: new Uint8Array(assertion.authenticatorData),
              clientDataJson: new Uint8Array(assertion.clientDataJSON),
              signature: new Uint8Array(assertion.signature),
              userHandle: new Uint8Array(assertion.userHandle ?? []),
              authCtx,
            });
            authContinue(res.authContinue!);
          } catch (err) {
            if (
              err.code === grpc.Code.Unauthenticated ||
              err.code === grpc.Code.FailedPrecondition
            ) {
              authRecover();
            }
            console.error(err);
          }
        }
      } else {
        alert("Your browser issued a broken WebauthnResponse. Please use a different method.");
      }
    } catch (err) {
      if (err instanceof DOMException) {
        if (err.code === DOMException.NOT_SUPPORTED_ERR) {
          if (hasLocalStorage) {
            // Mostly use in Chrome-based browsers
            window.localStorage.setItem(
              "discoverableCredentialsLastError",
              new Date().getTime().toString(10)
            );
          }
          fetch("/api/collecterror", {
            method: "POST",
            body: new URLSearchParams({
              message: `Informational: WebauthN direct failed and was disabled: ${err.toString()}`,
            }),
          }).then(
            () => {},
            () => {}
          );
          alert(
            "Ihr Gerät benötigt einen Nutzernamen oder E-Mail bevor Sicherheitschlüssel verwendet werden können."
          );
        }
      }
      console.error(err);
    }
  }, [nonce, authCtx, authContinue, authRecover, spec]);

  return <Button onClick={onAuth}>Mit Sicherheitsschlüssel anmelden</Button>;
}

export function WebauthnFactorItem(props: {
  spec: WebauthnAuthenticator;
  onClick?: () => void;
}): JSX.Element {
  return (
    <DeviceItem
      icon={mdiKey}
      name={props.spec.name}
      onClick={hasWebauthn ? props.onClick : undefined}
    />
  );
}

export function WebauthnEnrollItem(props: { onClick?: () => void }): JSX.Element {
  return (
    <DeviceItem
      icon={mdiKey}
      name="Neuer Sicherheitsschlüssel registrieren"
      onClick={props.onClick}
    />
  );
}

// RFC8152 COSE
const ES256 = -7;
const RS256 = -257;
const EdDSA = -8;
const Curve25519 = 6;

export function AddWebauthn(props: {
  nonce: Uint8Array;
  userProfile: UserProfile;
  userId: string;
  otherCreds: Uint8Array[];
  authRecover: () => void;
}): JSX.Element {
  const { branding } = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState("");

  const registerCb = useCallback(async () => {
    try {
      const cred = await navigator.credentials.create({
        publicKey: {
          challenge: props.nonce,
          rp: {
            id: window.location.hostname,
            name: branding?.name ?? "",
          },
          user: {
            id: new TextEncoder().encode(props.userId),
            displayName: props.userProfile.name,
            name: props.userProfile.name,
          },
          attestation: "direct",
          authenticatorSelection: {
            residentKey: "preferred",
            userVerification: "preferred",
          },
          pubKeyCredParams: [
            {
              type: "public-key",
              alg: EdDSA,
              //@ts-ignore TS doesn't know about crv yet.
              crv: Curve25519,
            },
            {
              type: "public-key",
              alg: ES256,
            },
            {
              type: "public-key",
              alg: RS256,
            },
          ],
          excludeCredentials: props.otherCreds.map((x) => ({
            id: x,
            type: "public-key",
          })),
        },
      });
      if (cred instanceof PublicKeyCredential) {
        const attestation = cred.response;
        if (attestation instanceof AuthenticatorAttestationResponse) {
          await idpc.AddWebauthn({
            name,
            id: new Uint8Array(cred.rawId),
            clientDataJson: new Uint8Array(attestation.clientDataJSON),
            attestationObject: new Uint8Array(attestation.attestationObject),
          });
          props.authRecover();
        } else {
          throw new Error("Invalid Webauthn response: Not an AuthenticatorAttestationResponse");
        }
      } else {
        throw new Error("Invalid Webauthn response: Not a PublicKeyCredential");
      }
    } catch (err) {
      setError(err.toString());
    }
  }, [props, name, branding]);

  return (
    <Container>
      <WebauthnEnrollItem />
      <Prompt>
        Stecken Sie ihr Sicherheitsschlüssel ein und geben Sie dem einen Namen. Dann drücken Sie auf
        Registrieren.
      </Prompt>
      <Input name="Namen des Geräts" autoFocus value={name} onChange={setName} />
      <ButtonBox>
        <Button onClick={registerCb}>Registrieren</Button>
      </ButtonBox>
      <StatusContainer>
        {error === null ? "Warte auf Sicherheitsschlüssel..." : error}
      </StatusContainer>
    </Container>
  );
}

interface WebauthnBase {
  nonce: Uint8Array;
  authCtx?: AuthContext;
  authRecover: () => void;
  authContinue: (c: AuthContinue) => void;
}

interface WebauthnFactor extends WebauthnBase {
  type: "factor";
  spec: WebauthnAuthenticator;
}

interface WebauthnDirect extends WebauthnBase {
  type: "direct";
  spec: WebauthnAuthenticator[];
}

export function Webauthn(props: WebauthnFactor | WebauthnDirect): JSX.Element {
  const { nonce, authCtx, authRecover, authContinue } = props;
  const [error, setError] = useState<Error | null>(null);
  const [prompted, setPrompted] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;
    const doWebauthn = async () => {
      setError(null);
      try {
        const cred = await navigator.credentials.get({
          publicKey: {
            challenge: nonce,
            allowCredentials:
              props.type === "factor"
                ? [
                    {
                      type: "public-key",
                      id: props.spec.id,
                    },
                  ]
                : props.spec.map((x) => ({ type: "public-key", id: x.id })),
            userVerification: props.type === "factor" ? "discouraged" : "required",
            rpId: window.location.hostname,
          },
        });
        if (cred instanceof PublicKeyCredential) {
          const assertion = cred.response;
          if (assertion instanceof AuthenticatorAssertionResponse) {
            try {
              const res = await idpc.AuthWebauthn({
                id: new Uint8Array(cred.rawId),
                authenticatorData: new Uint8Array(assertion.authenticatorData),
                clientDataJson: new Uint8Array(assertion.clientDataJSON),
                signature: new Uint8Array(assertion.signature),
                userHandle: new Uint8Array(assertion.userHandle ?? []),
                authCtx,
              });
              authContinue(res.authContinue!);
            } catch (err) {
              if (
                err.code === grpc.Code.Unauthenticated ||
                err.code === grpc.Code.FailedPrecondition
              ) {
                authRecover();
              }
              setError(err);
            }
          }
        } else {
          alert("Your browser issued a broken WebauthnResponse. Please use a different method.");
        }
      } catch (err) {
        setError(err);
      }
    };
    if (hasWebauthn && !prompted) {
      setPrompted(true);
      doWebauthn();
    }
    return () => {
      ignore = true;
    };
  }, [authContinue, authCtx, nonce, props, prompted, authRecover]);

  let prompt =
    props.type === "factor"
      ? `Bitte stecken Sie "${props.spec.name}" ein und drücken auf den Knopf`
      : `Bitte folgen Sie den Anweisungen im obigen Dialog`;
  if (error instanceof DOMException) {
    if (error.name === "NotAllowedError") {
      prompt = "Authentifizierungsversuch abgebrochen";
    } else {
      prompt = "Fehler: " + error.message;
    }
  } else if (error !== null) {
    prompt = "Fehler: " + error.toString();
  }

  return (
    <Container>
      {props.type === "factor" ? (
        <WebauthnFactorItem spec={props.spec} />
      ) : (
        <DeviceItem icon={mdiKey} name="Mit Sicherheitsschlüssel anmelden" />
      )}
      <StatusContainer>
        {prompt}
        {error === null ? "" : <Button onClick={() => setPrompted(false)}>Erneut versuchen</Button>}
      </StatusContainer>
    </Container>
  );
}
