import { transparentize } from "polished";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinContainer = styled.div<{color: string}>`
  display: inline-block;
  box-sizing: border-box;
  border-top: 5px solid ${(p) => transparentize(0.8, p.color)};
  border-right: 5px solid ${(p) => transparentize(0.8, p.color)};
  border-bottom: 5px solid ${(p) => transparentize(0.8, p.color)};
  border-left: 5px solid ${(p) => p.color};

  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;
  &,
  :after {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
`;

export function Spinner(props: {color?: string}) {
  return <SpinContainer color={props.color ?? "white"} />;
}
