import { grpc } from "@improbable-eng/grpc-web";
import { mdiAlert } from "@mdi/js";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { getErrorDetail, saml2c, useInjectedData } from "./api";
import { Brand } from "./Brand";
import { Button, Danger, Primary } from "./Button";
import { AuthContext, ClientProfile, UserProfile } from "./generated/idp/api/idp";
import { Message, POSTMessage } from "./generated/idp/api/saml2/api";
import { ErrorLayout } from "./login/ErrorLayout";
import { UserProfilePill } from "./login/UserProfilePill";
import { useLocation } from "./router";
import { Spinner } from "./Spinner";
import { Background, Card } from "./UI";

const ConsentContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  width: 400px;
`;

const ConsentButtonLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface AuthorizeState {
  loading: boolean;
  needsConsent: boolean;
  extraScopes: string[];
  error?: string;
  profile?: ClientProfile;
}

const rawQueryParamsRe = /(^\?|&)([^=]+)=([^&]*)/g;

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  for (let i = 0; i < arr.byteLength; ++i) {
    bin.push(String.fromCharCode(arr[i]));
  }
  return btoa(bin.join(""));
}

function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function useSAMLMessage(): Message | null {
  const postData = useInjectedData(POSTMessage);
  return useMemo(() => {
    if (postData) return Message.fromPartial({ binding: { $case: "post", post: postData } });
    // Custom weird decoding because redirect binding signature encoding is not canonical.
    const rawQueryParamsIter = window.location.search.matchAll(rawQueryParamsRe);
    let relayState = "";
    let request = "";
    let signatureAlgorithm = "";
    let signature = new Uint8Array();
    try {
      for (let [, , rawKey, rawValue] of rawQueryParamsIter) {
        const key = decodeURIComponent(rawKey);
        switch (key) {
          case "SAMLRequest":
            request = rawValue;
            break;
          case "RelayState":
            relayState = rawValue;
            break;
          case "SigAlg":
            signatureAlgorithm = rawValue;
            break;
          case "Signature":
            signature = bytesFromBase64(decodeURI(rawValue));
            break;
        }
      }
    } catch (err) {
      console.error("SAML2 decoding issue, ignoring", err);
    }
    if (request === "") {
      return null;
    }
    return Message.fromPartial({
      binding: {
        $case: "redirect",
        redirect: { relayState, request, signature, signatureAlgorithm },
      },
    });
  }, [postData]);
}

function respondSAML(url: string, msg: Message) {
  switch (msg.binding?.$case) {
    case "post":
      const form = document.createElement("form");
      form.method = "POST";
      form.action = url;
      const params = new Map<string, string>();
      params.set("SAMLResponse", base64FromBytes(msg.binding.post.request));
      if (msg.binding.post.relayState) params.set("RelayState", msg.binding.post.relayState);
      for (let [key, val] of params.entries()) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = val;
        form.appendChild(hiddenField);
      }
      // This is safe as it's outside the React root.
      document.body.appendChild(form);
      form.submit();
      return;
    case "redirect":
      const redirect = msg.binding.redirect;
      // Note: These are pre-encoded. No further URL encoding is necessary. This is because of dumb
      // signing requirements on SAML's part.
      const rawOrderedParams: [string, string][] = [];
      rawOrderedParams.push(["SAMLResponse", redirect.request]);
      if (redirect.relayState !== "") rawOrderedParams.push(["RelayState", redirect.relayState]);
      if (redirect.signatureAlgorithm !== "")
        rawOrderedParams.push(["SigAlg", redirect.signatureAlgorithm]);
      if (redirect.signature.length > 0)
        rawOrderedParams.push([
          "Signature",
          encodeURIComponent(base64FromBytes(redirect.signature)),
        ]);
      const rawQueryParts: string[] = [];
      for (let [key, val] of rawOrderedParams) {
        rawQueryParts.push(`${key}=${val}`);
      }
      window.location.replace(url + "?" + rawQueryParts.join("&"));
      return;
    default:
      console.error("invalid binding: " + msg.binding?.$case);
      return;
  }
}

export function SAML2(props: {
  profile: UserProfile | null;
  authenticate: (a: AuthContext | null, c?: ClientProfile) => void;
}): JSX.Element {
  const [authorizeState, setAuthorizeState] = useState<AuthorizeState>({
    loading: true,
    extraScopes: [],
    needsConsent: false,
  });

  const [givenConsent, giveConsent] = useState<string[]>([]);
  const { authenticate } = props;

  const location = useLocation();
  const samlMessage = useSAMLMessage();
  const isLogout = location === "/saml2/logout";

  useEffect(() => {
    if (samlMessage === null) {
      setAuthorizeState({
        // TODO: Hacky, should be derived further down without useEffect
        loading: false,
        error: "No SAML2 request",
        extraScopes: [],
        needsConsent: false,
      });
      return;
    }

    let ignore = false;
    const fetchData = async () => {
      try {
        if (!isLogout) {
          const res = await saml2c.SSO({ request: samlMessage });
          if (!ignore && res.response) {
            respondSAML(res.url, res.response);
          }
        } else {
          const res = await saml2c.Logout({ request: samlMessage });
          if (!ignore && res.response) {
            respondSAML(res.url, res.response);
          }
        }
      } catch (err) {
        const profile = getErrorDetail(err, ClientProfile) ?? undefined;
        switch (err.code) {
          case grpc.Code.Unauthenticated:
            const authCtx = getErrorDetail(err, AuthContext);
            authenticate(authCtx, profile);
            break;
          case grpc.Code.InvalidArgument:
            setAuthorizeState({
              loading: false,
              error: "Bad SAML2 configuration: " + err.message,
              extraScopes: [],
              needsConsent: false,
            });
            break;
          case grpc.Code.NotFound:
            setAuthorizeState({
              loading: false,
              error: "SAML2 SP not registered",
              extraScopes: [],
              needsConsent: false,
            });
            break;
          case grpc.Code.OutOfRange:
            setAuthorizeState({
              loading: false,
              error: "Login-Anfrage ist abgelaufen",
              extraScopes: [],
              needsConsent: false,
            });
            break;
          case grpc.Code.DeadlineExceeded:
          case grpc.Code.Canceled:
          case grpc.Code.Unavailable:
            setTimeout(fetchData, 10000);
            break;
          default:
            let errorMsg = "Faild to print error";
            try {
              errorMsg = "" + err;
            } catch (err) {}
            setAuthorizeState({
              loading: false,
              error: "Internal Error. Details: " + errorMsg,
              extraScopes: [],
              needsConsent: false,
            });
            break;
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [givenConsent, authenticate, samlMessage, isLogout]);

  return (
    <Background>
      <Card width={500}>
        <Brand />
        {props.profile ? <UserProfilePill profile={props.profile} /> : null}
        {authorizeState.error !== undefined ? (
          <ErrorLayout
            icon={mdiAlert}
            primaryMessage="Interner Fehler"
            secondaryMessage={authorizeState.error}
          />
        ) : null}
        {authorizeState.loading ? <Spinner color="black" /> : null}
        {authorizeState.needsConsent ? (
          <ConsentContainer>
            <h3>Zustimmung erforderlich</h3>
            Möchten Sie sich bei {authorizeState.profile?.displayName} anmelden?
            <ConsentButtonLayout>
              <Button color={Danger}>Nein</Button>
              <Button color={Primary} onClick={() => giveConsent(authorizeState.extraScopes)}>
                Ja
              </Button>
            </ConsentButtonLayout>
          </ConsentContainer>
        ) : null}
      </Card>
    </Background>
  );
}
