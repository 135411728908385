import styled from "styled-components";

const Meter = styled.div`
  margin-top: 10px;
  width: 100%;
  background-color: #afafaf;
  height: 5px;
`;

const Bar = styled.div<{ color: string; strength: number }>`
  width: ${(p) => p.strength * 100.0}%;
  height: 100%;
  background-color: ${(p) => p.color};
  overflow: hidden;
`;
const TextDescription = styled.div<{ color: string }>`
  margin-top: 5px;
  text-align: right;
  color: ${(p) => p.color};
`;

const Container = styled.div`
  margin-bottom: 5px;
`;

export interface StrengthBarData {
  strength: number;
  color: string;
  text: string;
}

export function StrengthBar(props: StrengthBarData) {
  return (
    <Container>
      <Meter>
        <Bar color={props.color} strength={props.strength} />
      </Meter>
      <TextDescription color={props.color}>{props.text}</TextDescription>
    </Container>
  );
}
