import { grpc } from "@improbable-eng/grpc-web";
import { mdiCellphoneAndroid, mdiDeleteOutline, mdiKey, mdiWindowClose } from "@mdi/js";
import Icon from "@mdi/react";
import Avatar from "boring-avatars";
import { lighten } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { getErrorDetail, idpc } from "../api";
import { Button, Danger } from "../Button";
import {
  AuthContext,
  TOTPAuthenticator,
  User,
  WebauthnAuthenticator,
} from "../generated/idp/api/idp";
import { Spinner } from "../Spinner";
import { Nav } from "./Nav";
import { ReactComponent as MoodleBase } from "./moodle.svg";
import { ReactComponent as MailBase } from "./mail.svg";
import { ReactComponent as CloudBase } from "./cloud.svg";
import { ReactComponent as OfficeBase } from "./office-1.svg";
import { Authenticator } from "./Authenticator";
import Modal from "react-modal";
import { AuthPasswordExtension, ChangePassword } from "../login/Password";

const ProfileIcon = styled.img`
  border-radius: 50%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
`;

const FaList = styled.div`
  max-width: 300px;
`;

const MoodleIcon = styled(MoodleBase)`
  height: 22px;
  width: 33px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const MailIcon = styled(MailBase)`
  height: 21px;
  width: 28px;

  @media (max-width: 360px) {
    height: 19px;
  }
`;

const CloudIcon = styled(CloudBase)`
  height: 22px;
  width: 31px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const OfficeIcon = styled(OfficeBase)`
  height: 22px;
  width: 31px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

export function Account(props: {
  onLogout: () => void;
  authenticate: (a: AuthContext) => void;
}): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const authenticate = props.authenticate;

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await idpc.GetSelf({});
        if (!ignore) {
          setUser(res.user ?? null);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          authenticate({
            $type: "idp.AuthContext",
            authenticationContextClass: [],
            maxAgeSeconds: 99999999,
          });
        }
        let ia = getErrorDetail(err, AuthContext);
        if (ia !== null) {
          authenticate(ia);
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [authenticate]);

  const onPasswordChange = () => {
    setPasswordModalOpen(true);
  };

  if (user)
    return (
      <Nav
        name={user.profile?.name ?? ""}
        onLogout={props.onLogout}
        onPasswordChange={onPasswordChange}
      >
        <PasswordChangeModal
          open={passwordModalOpen}
          onClose={() => {
            setPasswordModalOpen(false);
          }}
        />
        <FlexRow>
          {/* {user.profile?.picture ? (
            <ProfileIcon src={user.profile.picture}></ProfileIcon>
          ) : (
            <Avatar
              name={user?.profile?.name ?? ""}
              size={32}
              variant="marble"
            />
          )} */}
          <div style={{ alignSelf: "center", marginLeft: 0, fontSize: "1.4rem" }}>
            <div style={{ fontSize: "36px", fontWeight: 600 }}>Hallo,</div>
            {user.profile?.name}
          </div>
        </FlexRow>
        {/* <div>{user.profile?.givenName}</div>
        <div>{user.profile?.familyName}</div>
        <div>{user.profile?.gender}</div>
        <div>{user.profile?.picture}</div>
        <div>{user.profile?.locale}</div>
        <div>{user.profile?.zoneinfo}</div> */}

        <p style={{ marginTop: 40 }}>
          Du hast dich erfolgreich angemeldet.
          Auf dieser Seite kann aktuell nur das Passwort geändert
          und 2FA konfiguriert werden
        </p>
        <hr style={{ marginTop: 20 }}></hr>
        <Authenticator authenticate={props.authenticate} />
      </Nav>
    );
  else
    return (
      <Nav>
        <Spinner />
      </Nav>
    );
}

export function WebauthnFactorItem(props: { spec: WebauthnAuthenticator }): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveWebauthn({
      id: props.spec.id,
    });
  }, [props]);
  return <DeviceItem icon={mdiKey} name={props.spec.name} onDelete={onDeleteCb} />;
}

export function TOTPAuthenticatorItem(props: {
  spec: TOTPAuthenticator;
  onClick?: () => void;
}): JSX.Element {
  const onDeleteCb = useCallback(async () => {
    await idpc.RemoveTOTPAuthenticator({
      id: props.spec.id,
    });
  }, [props]);
  return <DeviceItem icon={mdiCellphoneAndroid} name={props.spec.name} onDelete={onDeleteCb} />;
}

const DeviceContainer = styled.div`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
`;

const ContainerButton = styled.button`
  background: #d6d6d6;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  :hover {
    background: ${lighten(0.05, "#d6d6d6")};
  }
`;

const DeleteButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;

const ItemDeleteIcon = styled(Icon)`
  color: #86cecb;
  :hover {
    background: ${lighten(0.05, "#d6d6d6")};
  }
`;

const ItemText = styled.div`
  flex-grow: 1;
`;

export function DeviceItem(props: {
  icon: string;
  name: string;
  onDelete: () => void;
}): JSX.Element {
  return (
    <DeviceContainer>
      <Icon path={props.icon} size={1} style={{ marginRight: "10px" }} />
      <ItemText>{props.name}</ItemText>
      <DeleteButton onClick={props.onDelete}>
        <ItemDeleteIcon path={mdiDeleteOutline} size={1} />
      </DeleteButton>
    </DeviceContainer>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "sans-serif",
  },
};

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  padding-top: 5px;
`;

function PasswordChangeModal(props: { open: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={props.open} onRequestClose={props.onClose} style={customStyles}>
      <ModalTitle>
        <h1>Passwort</h1>
        <CloseButton onClick={props.onClose}>
          <Icon path={mdiWindowClose} size={1} />
        </CloseButton>
      </ModalTitle>

      <ChangePassword
        authRecover={() => {
          props.onClose();
        }}
        reason={AuthPasswordExtension.UserRequested}
      ></ChangePassword>
    </Modal>
  );
}
