import { useCallback, useState } from "react";
import styled from "styled-components";

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const InputElement = styled.input<{ active: boolean; focused: boolean }>`
  line-height: 1.5;
  padding: 1rem;
  padding-top: ${(p) => (p.active ? "1.5rem" : "1rem")};
  padding-bottom: ${(p) => (p.active ? "0.5rem" : "1rem")};
  display: block;
  border: 2px solid;
  border-color: ${(p) => (p.focused ? "#009ee0" : "#949494")};
  border-radius: 2px;
  order: 1;
  outline: 0;
  :placeholder {
    color: #666;
    opacity: 1;
  }
`;

const InputLabel = styled.label<{ active: boolean }>`
  position: absolute;
  top: ${(p) => (p.active ? "0.8rem" : "1.2rem")};
  left: 1.125rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 1.5rem;
  background: #fff;
  transition: top 0.1s ease;
  color: ${(p) => (p.active ? "#000" : "#666")};
  outline: none;
  font-weight: ${(p) => (p.active ? "bold" : "normal")};
  font-size: ${(p) => (p.active ? "12px" : "inherit")};
`;

export type InputMode =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";

export function Input(props: {
  name: string;
  label?: string;
  type?: string;
  inputMode?: InputMode;
  value: string;
  autoFocus?: boolean;
  autoComplete?: string;
  onChange?: (newValue: string) => void;
  onEnter?: () => void;
}) {
  const [focused, setFocused] = useState(false);

  const active = focused || props.value !== "";
  const { onChange, onEnter } = props;
  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.currentTarget.value),
    [onChange]
  );
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => (e.key === "Enter" ? onEnter?.() : undefined),
    [onEnter]
  );

  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  return (
    <InputBox>
      <InputElement
        name={props.name}
        type={props.type}
        onFocus={onFocus}
        onBlur={onBlur}
        active={active}
        inputMode={props.inputMode}
        focused={focused}
        autoComplete={props.autoComplete}
        value={props.value}
        autoFocus={props.autoFocus}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
      />
      <InputLabel active={active} htmlFor={props.name}>
        {props.label ? props.label : props.name}
      </InputLabel>
    </InputBox>
  );
}
