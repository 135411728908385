import Icon from "@mdi/react";
import styled from "styled-components";
import { Button, Secondary } from "../Button";

const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 400px;
`;

const OrangeText = styled.div`
  color: #f16625;
  margin-bottom: 5px;
`;

const GrayText = styled.div`
  color: #505050;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export function ErrorLayout(props: {
  icon: string;
  primaryMessage: string;
  secondaryMessage?: string;
  backButtonText?: string;
  onBack?: () => void;
  children?: React.ReactNode;
}) {
  return (
    <Container>
      <OrangeText>
        <div>
          <Icon path={props.icon} size={3} />
        </div>
        {props.primaryMessage}
      </OrangeText>
      {props.secondaryMessage ? (
        <GrayText>{props.secondaryMessage}</GrayText>
      ) : null}
      {props.children}
      {props.backButtonText ? (
        <Button color={Secondary} onClick={props.onBack}>
          {props.backButtonText}
        </Button>
      ) : null}
    </Container>
  );
}
