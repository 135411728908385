import { mdiAccountOutline, mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import Avatar from "boring-avatars";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "../router";
import { ReactComponent as LogoBase } from "./Logo_small_white.svg";

const Container = styled.div`
  font-family: sans-serif;
  display: flex;
  height: 100vh;
`;

// TODO: Mobile menu
const NavLeftContainer = styled.div`
  min-width: 180px;
  background: #000000;

  @media (max-width: 472px) {
    display: none;
  }
`;

const NavTopContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
`;

const NavTop = styled.div`
  height: 72px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const NavTopText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  /* identical to box height, or 112% */
  letter-spacing: -0.015em;

  margin-left: 40px;
  margin-top: 18px;
`;

const NavTopRight = styled.div`
  margin: 10px 35px 10px 15px;
  padding: 5px;
  display: block;
  cursor: pointer;
  height: 36px;
`;

const AvatarName = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 112% */
  letter-spacing: -0.015em;
  margin-left: 10px;
`;

const LogoutIcon = styled(Icon)`
  margin-left: 5px;
`;

const Content = styled.div`
  padding: 30px 40px 30px 40px;
  overflow-y: auto;
  height: calc(100vh - 72px);
`;


const Logo = styled(LogoBase)`
  display: block;
  width: 50px;
  height: 50px;
  align-self: center;

  @media (max-width: 360px) {
    height: 14px;
    width: 14px;
  }
`;

const LogoBox = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const MenuBox = styled.div`
  height: 44px;
  //background: #9f9f9f;
  display: flex;
  flex-flow: row;
  align-content: center;
  align-items: center;
  color: white;
`;

const MenuText = styled.div`
  font-size: 24px;
`;

const MenuIcon = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const AlignUser = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const DropDownContent = styled.div`
  display: block;
  // position: absolute;
  position: relative;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  right: 0;
  left: auto;
  z-index: 10;
}
`;

const SubA = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export function Nav(props: {
  name?: string;
  onLogout?: () => void;
  onPasswordChange?: () => void;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<Boolean>(false);

  return (
    <Container>
      <NavLeftContainer>
        <LogoBox>
          <Logo />
        </LogoBox>
        <Link activeColor={"#9f9f9f"} path="/">
          <MenuBox>
            <MenuIcon>
              <Icon path={mdiAccountOutline} size={1} />
            </MenuIcon>
            <MenuText>Profil</MenuText>
          </MenuBox>
        </Link>
        {/* <Link activeColor={"#57b846"} path="/authenticator">
          <MenuBox>
            <MenuIcon>
              <Icon path={mdiShieldKeyOutline} size={1} />
            </MenuIcon>
            <MenuText>Geräte</MenuText>
          </MenuBox>
        </Link> */}
        {/* <Link activeColor={"#57b846"} path="/sessions">
          <MenuBox>
            <MenuIcon>
              <Icon path={mdiDevices} size={1} />
            </MenuIcon>
            <MenuText>Sitzungen</MenuText>
          </MenuBox>
        </Link>
        <Link activeColor={"#57b846"} path="/activities">
          <MenuBox>
            <MenuIcon>
              <Icon path={mdiFormatListBulletedType} size={1} />
            </MenuIcon>
            <MenuText>Aktivitäten</MenuText>
          </MenuBox>
        </Link> */}
      </NavLeftContainer>
      <NavTopContainer>
        <NavTop>
          <NavTopText>{theme.branding?.name}</NavTopText>
          <NavTopRight
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <div>
              <AlignUser>
                <Avatar name={props.name ?? ""} size={32} variant="marble" />
                <AvatarName>{props.name}</AvatarName>
                <LogoutIcon path={mdiMenuDown} size={1} />
              </AlignUser>
              {isOpen && (
                <DropDownContent>
                  <SubA onClick={props.onPasswordChange}>Passwort ändern</SubA>
                  <SubA onClick={props.onLogout}>Abmelden</SubA>
                </DropDownContent>
              )}
            </div>
          </NavTopRight>
        </NavTop>
        <Content>{props.children}</Content>
      </NavTopContainer>
    </Container>
  );
}
