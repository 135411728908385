/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { SignatureAlgorithm, PublicKey } from "../../idp/api/cryptopb/cryptopb";
import { BrowserHeaders } from "browser-headers";
import { Timestamp } from "../../google/protobuf/timestamp";
import { Value } from "../../google/protobuf/struct";

export const protobufPackage = "idp";

export enum Mechanism {
  UNKNOWN = 0,
  USERNAME = 10,
  PASSWORD = 1,
  EMAIL = 2,
  KERBEROS = 3,
  TOTP = 4,
  WEBAUTHN_FACTOR = 5,
  WEBAUTHN = 7,
  ATTESTATION = 6,
  CONFIRM_TRUSTED_DEVICE = 8,
  PROOF_OF_SHARK = 9,
  OIDC = 11,
  PHONE = 12,
  IMPERSONATION = 13,
  UNRECOGNIZED = -1,
}

export enum Trust {
  /** NONE - We have no information if this factor belongs to the user */
  NONE = 0,
  /** VERIFIED - The user has proven to be able to use this factor */
  VERIFIED = 1,
  /** VETTED - This factor has been manually vetted to belong to the user */
  VETTED = 3,
  UNRECOGNIZED = -1,
}

export enum GeneratedPasswordFamily {
  SHORT = 0,
  PASSPHRASE = 1,
  MACHINE = 3,
  UNRECOGNIZED = -1,
}

export interface ClientProfile {
  $type: "idp.ClientProfile";
  displayName: string;
  logoUrl: string;
}

export interface UserProfile {
  $type: "idp.UserProfile";
  name: string;
  givenName: string;
  familyName: string;
  middleName: string;
  nickname: string;
  preferredUsername: string;
  profile: string;
  picture: string;
  website: string;
  gender: string;
  birthdate: string;
  zoneinfo: string;
  locale: string;
  updatedAt: number;
}

/** Stored */
export interface User {
  $type: "idp.User";
  /**
   * id is an unchanging unique global identifier for a user. It is recommended
   * that this is a random value with an entropy of at least 128 bits. It should
   * not contain PII.
   */
  id: string;
  /**
   * If set, indicates that this user is managed by an external system. Blocks
   * accesses from the administrative interface and API to modify and/or delete
   * the user.
   */
  external: boolean;
  status: User_Status;
  totpAuthenticator: TOTPAuthenticator[];
  webauthnAuthenticator: WebauthnAuthenticator[];
  emailAddress: EmailAddress[];
  phoneNumber: PhoneNumber[];
  password: Password | undefined;
  profile: UserProfile | undefined;
  memberOf: string[];
  claim: { [key: string]: Value };
  /**
   * If this user has state GHOST, this is set to the timestamp when it
   * transitioned to that state. Cannot be set externally or through API.
   */
  ghostSince: Date | undefined;
}

export enum User_Status {
  /** OK - OK means the user is active and all features are enabled. */
  OK = 0,
  /**
   * DISABLED - DISABLED means the user is prevented from logging in and will get an
   * error indicating that the account has been disabled.
   */
  DISABLED = 1,
  /**
   * GHOST - GHOST means to pretend the user account does not exist. This is used for
   * keeping data about users which are no longer part of an external sync
   * system for a limited period of time.
   */
  GHOST = 2,
  UNRECOGNIZED = -1,
}

export interface User_ClaimEntry {
  $type: "idp.User.ClaimEntry";
  key: string;
  value: Value | undefined;
}

export interface AuthContinue {
  $type: "idp.AuthContinue";
  prompt: string;
  nextMechanism: Mechanism[];
  done: boolean;
  /**
   * Hint containing the mechanism the user most likely wants to use. Can be
   * used to preselect the mechanism on the frontend. Other mechanisms still
   * need to be accessible by the user, they must not be hidden.
   */
  mechanismHint: Mechanism;
  hasUsernames: boolean;
  enrollPassword: boolean;
  enrollTotp: boolean;
  totpAuthenticator: TOTPAuthenticator[];
  enrollWebauthn: boolean;
  webauthnAuthenticator: WebauthnAuthenticator[];
  enrollEmail: boolean;
  emailAddress: EmailAddress[];
  emailEnrollment: EmailEnrollment | undefined;
  /** deprecated */
  enrollBsrEmail: boolean;
  nonce: Uint8Array;
  newSessionToken: string;
  userProfile: UserProfile | undefined;
  userId: string;
}

export interface EmailEnrollment {
  $type: "idp.EmailEnrollment";
  required: boolean;
  domains: string[];
  notDomains: string[];
}

export interface Branding {
  $type: "idp.Branding";
  /** Brand of the organization. Should generally be its name. */
  brand: string;
  /** Name of the identity provider. */
  name: string;
  /**
   * Name of the logo file in the branding directory. Should be displayable by
   * normal browsers, i.e. be a JPEG, PNG or SVG.
   */
  logoFilename: string;
  /** Controls how the brand identity is displayed */
  logoStyle: Branding_LogoStyle;
  /** CSS background property. */
  background: string;
  /** Color of the buttons */
  buttonColor: string;
  /**
   * Text the user is shown when the IDP detects an error only fixable by an
   * administrator. Should generally contain a way to contact the support of
   * your organization.
   */
  supportContactText: string;
  /**
   * Text the user is shown when the IDP denies access to a resource. Should
   * generally contain a way to contact the support of your organization.
   */
  accessDeniedText: string;
  /** URL to a privacy policy */
  privacyPolicy: string;
}

export enum Branding_LogoStyle {
  /** BRAND_NAME_ONLY - Only shows textual brand name */
  BRAND_NAME_ONLY = 0,
  /** BRAND_NAME_WITH_LOGO - Shows logo to the left of textual brand name */
  BRAND_NAME_WITH_LOGO = 1,
  /** WORDMARK - Shows only wordmark logo */
  WORDMARK = 2,
  UNRECOGNIZED = -1,
}

/** Password-related */
export interface AuthPasswordRequest {
  $type: "idp.AuthPasswordRequest";
  authCtx: AuthContext | undefined;
  password: string;
}

export interface AuthPasswordResponse {
  $type: "idp.AuthPasswordResponse";
  result: AuthPasswordResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthPasswordResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_PASSWORD = 2,
  /** MUST_CHANGE_POLICY - IDP policy requires password change */
  MUST_CHANGE_POLICY = 3,
  /** MUST_CHANGE_EXPOSED - Password has been exposed and must be changed */
  MUST_CHANGE_EXPOSED = 4,
  RATE_LIMITED = 5,
  LOCKED_OUT = 6,
  /** UNAVAILABLE - Mechanism is unavailable for this SID (for example */
  UNAVAILABLE = 7,
  UNRECOGNIZED = -1,
}

export interface ChangePasswordRequest {
  $type: "idp.ChangePasswordRequest";
  /**
   * ID of the user whose password is being changed. If left empty the
   * authenticated user is used. If the calling entity doesn't have the
   * idp.internal/pwinterop or idp.internal/admin roles calls will be rejected
   * with PermissionDenied if the user_id is not either empty or set to the
   * authenticated user.
   */
  userId: string;
  /**
   * Set to true if the request comes from a pwinterop service. This is used to
   * break the pwinterop loop (pwinterop->IDP->pwinterop ...) by not forwarding
   * any change with this set back to the pwinterop plugin. Setting this to true
   * without the idp.internal/pwinterop scope will be denied.
   */
  fromPwinterop: boolean;
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponse {
  $type: "idp.ChangePasswordResponse";
}

/**
 * Password represents a password a user can use to identify itself.
 *
 * For security reasons a key is derived from the user's password and a per-user
 * `salt` using the key derivation function (KDF) in `algorithm` and only the
 * derived key is stored. Since KDFs are not reversible it is impossible to find
 * the user's password faster than a brute-force search against the KDF. Thus
 * the ideal KDF is slow and memory-hard to make such brute-forcing as hard as
 * possible.
 *
 * Because the original password is not stored it is important that the password
 * passed into the KDF is bit-for-bit equal as otherwise the derived key won't
 * match and the user can't authenticate. To ensure this, the password is
 * processed into PRECIS class OpaqueString before hashing. This takes care of
 * various forms of normalization including converting to Unicode Normal Form C.
 */
export interface Password {
  $type: "idp.Password";
  algorithm: Password_Algorithm;
  derivedKey: Uint8Array;
  salt: Uint8Array;
  /**
   * Time complexity tunable of the algorithm used. Meaning of the value depends
   * on the algorithm used. If unused on a given algorithm it must be zero.
   */
  timeComplexity: number;
  /**
   * Memory complexity tunable of the algorithm used. Meaning of the value
   * depends on the algorithm used. If unused on a given algorithm it must be
   * zero.
   */
  memoryComplexity: number;
  /**
   * Estimated entropy of the password in bits. Must be set to zero (+0.0) if
   * unknown.
   */
  estimatedEntropyBits: number;
  breached: boolean;
  /**
   * Time at which the password was last changed. This value only changes when
   * the user changes the password to a different one, not when another
   * algorithm is used or the estimated entropy changes.
   */
  lastChanged: Date | undefined;
  /** If set, forces the user to change the password on next authentication. */
  forceChange: boolean;
}

export enum Password_Algorithm {
  /**
   * NONE - The user does not have a password set. The password auth mechanism will
   * fail unless enrolling passwords is allowed.
   */
  NONE = 0,
  /**
   * ARGON2ID - Password is stored as an Argon2id hash. This is the default algorithm,
   * everything will be migrated to it. Argon2id is memory-hard and free from
   * timing attacks.
   */
  ARGON2ID = 1,
  /**
   * KRB5_AES128_SHA - Kerberos 5 AES128_CTS_HMAC_SHA1_96 hash, iteration count is stored
   * in time_complexity. memory_complexity is unused and must be zero.
   */
  KRB5_AES128_SHA = 3,
  /**
   * KRB5_AES256_SHA - Kerberos 5 AES256_CTS_HMAC_SHA1_96 hash, iteration count is stored
   * in time_complexity. memory_complexity is unused and must be zero.
   */
  KRB5_AES256_SHA = 4,
  /**
   * SHA2_CRYPT - SHA-crypt hash based on the SHA2 hash family as specified in
   * https://akkadia.org/drepper/SHA-crypt.txt. rounds are stored in
   * time_complexity and the zero value is equal to 5000. memory_complexity is
   * unused and must be zero. Note that the derived key does not contain the
   * final permutation and encoding.
   * https://pthree.org/2018/05/23/do-not-use-sha256crypt-sha512crypt-theyre-dangerous/
   */
  SHA2_CRYPT = 5,
  /**
   * DES_CRYPT - DES crypt hash based on the repeated application of the DES key schedule.
   * All complexity parameters must be zero.
   */
  DES_CRYPT = 6,
  /**
   * OPAQUE - OPAQUE is an asymmetric password-authenticated key exchange protocol.
   * This means it does not run the KDF on the server, but on the client. The
   * server never sees the password.
   * This is currently incompatible with server-side strength checking.
   */
  OPAQUE = 7,
  /**
   * ORACLE - An external service acts as an oracle. Service configuration happens
   * out-of-band. Should only be used for migrations as it is generally
   * vulnerable to timing attacks.
   */
  ORACLE = 2,
  UNRECOGNIZED = -1,
}

/**
 * SecretRisk represents the risk that a secret is guessed by an attacker in a
 * given timeframe.
 */
export interface SecretRisk {
  $type: "idp.SecretRisk";
  lastUpdated: Date | undefined;
  /** Available "failed attempt" tokens until risk reaches threshold */
  tokenBucket: number;
  /** Set if this secret is already locked out */
  lockedOut: boolean;
}

/** TOTP-related */
export interface TOTPAuthenticator {
  $type: "idp.TOTPAuthenticator";
  id: Uint8Array;
  name: string;
  /** Defaults to 6 */
  digits: number;
  /** Default 30s */
  stepDuration: number;
  hash: TOTPAuthenticator_Hash;
  /** Never exposed to the outside after registration */
  secret: Uint8Array;
}

export enum TOTPAuthenticator_Hash {
  SHA1 = 0,
  SHA256 = 1,
  SHA512 = 2,
  UNRECOGNIZED = -1,
}

export interface AuthTOTPRequest {
  $type: "idp.AuthTOTPRequest";
  authCtx: AuthContext | undefined;
  authenticatorId: Uint8Array;
  code: number;
}

export interface AuthTOTPResponse {
  $type: "idp.AuthTOTPResponse";
  result: AuthTOTPResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthTOTPResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_CODE = 2,
  ALREADY_USED = 3,
  RATE_LIMITED = 4,
  LOCKED_OUT = 5,
  UNRECOGNIZED = -1,
}

export interface AddTOTPAuthenticatorRequest {
  $type: "idp.AddTOTPAuthenticatorRequest";
  authenticator: TOTPAuthenticator | undefined;
  code: number;
}

export interface AddTOTPAuthenticatorResponse {
  $type: "idp.AddTOTPAuthenticatorResponse";
}

/** WebAuthn related */
export interface WebauthnAuthenticator {
  $type: "idp.WebauthnAuthenticator";
  /** WebauthN credential ID */
  id: Uint8Array;
  /** Human-readable name of authenticator assigned by user */
  name: string;
  /** Private fields */
  algorithm: SignatureAlgorithm;
  publicKey: PublicKey | undefined;
  attestationData: Uint8Array;
  /** Dynamic fields */
  counter: number;
}

export interface AddWebauthnRequest {
  $type: "idp.AddWebauthnRequest";
  name: string;
  id: Uint8Array;
  clientDataJson: Uint8Array;
  attestationObject: Uint8Array;
}

export interface AddWebauthnResponse {
  $type: "idp.AddWebauthnResponse";
}

export interface AuthWebauthnRequest {
  $type: "idp.AuthWebauthnRequest";
  authCtx: AuthContext | undefined;
  id: Uint8Array;
  clientDataJson: Uint8Array;
  authenticatorData: Uint8Array;
  signature: Uint8Array;
  userHandle: Uint8Array;
}

export interface AuthWebauthnResponse {
  $type: "idp.AuthWebauthnResponse";
  result: AuthWebauthnResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthWebauthnResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  ERROR = 2,
  UNRECOGNIZED = -1,
}

/** Mail related */
export interface SendAuthMailRequest {
  $type: "idp.SendAuthMailRequest";
  authCtx: AuthContext | undefined;
  type: SendAuthMailRequest_Type;
  /** Only set if type = RESET */
  mechanismToBeReset: Mechanism;
  id: Uint8Array;
}

export enum SendAuthMailRequest_Type {
  AUTHENTICATION = 0,
  VERIFICATION = 1,
  RESET = 2,
  UNRECOGNIZED = -1,
}

export interface SendAuthMailResponse {
  $type: "idp.SendAuthMailResponse";
}

export interface EmailAddress {
  $type: "idp.EmailAddress";
  id: Uint8Array;
  trust: Trust;
  /**
   * If set, this adress is considered externally-managed. It can only be
   * changed through the synchronization mechanism.
   */
  external: boolean;
  localPart: string;
  domain: string;
}

export interface PhoneNumber {
  $type: "idp.PhoneNumber";
  id: Uint8Array;
  trust: Trust;
  /**
   * If set, this adress is considered externally-managed. It can only be
   * changed through the synchronization mechanism.
   */
  external: boolean;
}

export interface AuthBackchannelRequest {
  $type: "idp.AuthBackchannelRequest";
  code: number;
}

export interface AuthBackchannelResponse {
  $type: "idp.AuthBackchannelResponse";
  result: AuthBackchannelResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthBackchannelResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_CODE = 2,
  ALREADY_USED = 3,
  RATE_LIMITED = 4,
  UNRECOGNIZED = -1,
}

/** Username-related */
export interface AuthUsernameRequest {
  $type: "idp.AuthUsernameRequest";
  username: string;
}

export interface AuthUsernameResponse {
  $type: "idp.AuthUsernameResponse";
  result: AuthUsernameResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthUsernameResponse_Result {
  /** INVALID - Should not normally show up. */
  INVALID = 0,
  /** SUCCESS - An identity has been found and is avilable. */
  SUCCESS = 1,
  /** NOT_FOUND - The user could not be associated with an identity. */
  NOT_FOUND = 2,
  /** DISABLED - The user is disabled and cannot be used to authenticate. */
  DISABLED = 3,
  /** POLICY_VIOLATION - Authentication cannot continue. */
  POLICY_VIOLATION = 4,
  UNRECOGNIZED = -1,
}

/** Kerberos-related */
export interface AuthKerberosRequest {
  $type: "idp.AuthKerberosRequest";
  token: string;
}

export interface AuthKerberosResponse {
  $type: "idp.AuthKerberosResponse";
  result: AuthKerberosResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthKerberosResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  POLICY_VIOLATION = 2,
  UNRECOGNIZED = -1,
}

export interface AuthContext {
  $type: "idp.AuthContext";
  /** List of ACRs that are acceptable for this operation, in order of preference */
  authenticationContextClass: string[];
  /** Maximum authentication age in seconds */
  maxAgeSeconds: number;
}

/** Start-related */
export interface AuthStartRequest {
  $type: "idp.AuthStartRequest";
  authCtx: AuthContext | undefined;
}

export interface AuthStartResponse {
  $type: "idp.AuthStartResponse";
  result: AuthStartResponse_Result;
  authContinue: AuthContinue | undefined;
  clientProfile: ClientProfile | undefined;
}

export enum AuthStartResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  POLICY_VIOLATION = 2,
  UNRECOGNIZED = -1,
}

/** Logout */
export interface LogoutRequest {
  $type: "idp.LogoutRequest";
}

export interface LogoutResponse {
  $type: "idp.LogoutResponse";
}

/** Audit */
export interface AuthenticationEvent {
  $type: "idp.AuthenticationEvent";
  mechanism: Mechanism;
  result: number;
  authenticatorId: Uint8Array;
}

export interface OAuthAuthorizeEvent {
  $type: "idp.OAuthAuthorizeEvent";
  clientId: string;
  scopes: string[];
  result: number;
}

export interface SAMLEvent {
  $type: "idp.SAMLEvent";
  clientId: string;
}

export interface AuditEvent {
  $type: "idp.AuditEvent";
  timestamp: Date | undefined;
  sessionHash: Uint8Array;
  sub: string;
  event?: { $case: "authentication"; authentication: AuthenticationEvent };
}

export interface RemoveTOTPAuthenticatorRequest {
  $type: "idp.RemoveTOTPAuthenticatorRequest";
  id: Uint8Array;
}

export interface RemoveTOTPAuthenticatorResponse {
  $type: "idp.RemoveTOTPAuthenticatorResponse";
}

export interface RemoveWebauthnRequest {
  $type: "idp.RemoveWebauthnRequest";
  id: Uint8Array;
}

export interface RemoveWebauthnResponse {
  $type: "idp.RemoveWebauthnResponse";
  id: Uint8Array;
}

export interface GetSelfRequest {
  $type: "idp.GetSelfRequest";
}

export interface GetSelfResponse {
  $type: "idp.GetSelfResponse";
  user: User | undefined;
}

export interface AuthImpersonateRequest {
  $type: "idp.AuthImpersonateRequest";
}

export interface AuthImpersonateResponse {
  $type: "idp.AuthImpersonateResponse";
}

export interface GetSessionsRequest {
  $type: "idp.GetSessionsRequest";
}

export interface GetSessionsResponse {
  $type: "idp.GetSessionsResponse";
  session: UserProfile[];
  selectedUserSession: number;
}

export interface GetBrandingRequest {
  $type: "idp.GetBrandingRequest";
}

export interface VerifyMailRequest {
  $type: "idp.VerifyMailRequest";
  token: string;
}

export interface VerifyMailResponse {
  $type: "idp.VerifyMailResponse";
}

export interface SendVerifyMailRequest {
  $type: "idp.SendVerifyMailRequest";
  email: string;
}

export interface SendVerifyMailResponse {
  $type: "idp.SendVerifyMailResponse";
}

export interface RemoveMailRequest {
  $type: "idp.RemoveMailRequest";
  id: Uint8Array;
}

export interface RemoveMailResponse {
  $type: "idp.RemoveMailResponse";
}

export interface GetUserRequest {
  $type: "idp.GetUserRequest";
  id: string;
  email: string;
  legacyUsername: string;
}

export interface GetUserResponse {
  $type: "idp.GetUserResponse";
  user: User | undefined;
}

export interface GetSessionsForUserRequest {
  $type: "idp.GetSessionsForUserRequest";
  /**
   * ID of the user whose sessions should be returned. If left empty the
   * authenticated user is used. If the calling entity doesn't have the
   * idp.internal/admin role calls will be rejected with PermissionDenied if
   * the user_id is not either empty or set to the authenticated user.
   */
  userId: string;
}

export interface UserAgent {
  $type: "idp.UserAgent";
  brand: string;
  platformBrand: string;
  deviceModel: string;
  mobile: boolean;
}

export interface GetSessionsForUserResponse {
  $type: "idp.GetSessionsForUserResponse";
}

export interface ResetRiskRequest {
  $type: "idp.ResetRiskRequest";
}

export interface ResetRiskResponse {
  $type: "idp.ResetRiskResponse";
}

export interface DeleteUserRequest {
  $type: "idp.DeleteUserRequest";
  userId: string;
}

export interface DeleteUserResponse {
  $type: "idp.DeleteUserResponse";
}

export interface CreateUserRequest {
  $type: "idp.CreateUserRequest";
  user: User | undefined;
  /** set init password */
  newPassword: string;
}

export interface CreateUserResponse {
  $type: "idp.CreateUserResponse";
}

export interface NewUserPasswordRequest {
  $type: "idp.NewUserPasswordRequest";
  userId: string;
  generatedPasswordFamily: GeneratedPasswordFamily;
  forceChange: boolean;
}

export interface NewUserPasswordResponse {
  $type: "idp.NewUserPasswordResponse";
  newPassword: string;
}

export interface ListUsersRequest {
  $type: "idp.ListUsersRequest";
  /** The maximum number of items to return. Capped server-side at 1000. */
  pageSize: number;
  /** The next_page_token returned from a previous List request, if any. */
  pageToken: string;
}

export interface ListUsersResponse {
  $type: "idp.ListUsersResponse";
  /** Users in the list. */
  user: User[];
  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  nextPageToken: string;
}

export interface AddUserClaimRequest {
  $type: "idp.AddUserClaimRequest";
  /** The ID of the user the claim should be added to. */
  userId: string;
  /** The name of the new claim. */
  claimName: string;
  /** The value of the new claim. */
  claimValue: Value | undefined;
}

export interface AddUserClaimResponse {
  $type: "idp.AddUserClaimResponse";
}

export interface DeleteUserClaimRequest {
  $type: "idp.DeleteUserClaimRequest";
  /** The ID of the user the claim should be deleted from. */
  userId: string;
  /** The name of the claim to be deleted. */
  claimName: string;
}

export interface DeleteUserClaimResponse {
  $type: "idp.DeleteUserClaimResponse";
}

export interface AddUserToGroupRequest {
  $type: "idp.AddUserToGroupRequest";
  /** The ID of the user the group should be added to. */
  userId: string;
  /** The name of the group to be added. */
  groupName: string;
}

export interface AddUserToGroupResponse {
  $type: "idp.AddUserToGroupResponse";
}

export interface SyncFullRequest {
  $type: "idp.SyncFullRequest";
  user: User[];
}

export interface SyncFullResponse {
  $type: "idp.SyncFullResponse";
}

/**
 * PasswordStrengthPolicy contains policies regarding the strength of users'
 * passwords. Each class has an upper bound, i.e. all scores from preceding
 * upper bound until the one of the given class are considered part of it.
 * For example if too_simple_upper_bound contains 24 and acceptable_upper_bound
 * 32 all scores from 24 (inclusive) up to 32 (exclusive) are considered
 * "acceptable".
 * Everything above good_upper_bound is considered "strong".
 */
export interface PasswordStrengthPolicy {
  $type: "idp.PasswordStrengthPolicy";
  tooSimpleUpperBound: number;
  acceptableUpperBound: number;
  goodUpperBound: number;
}

export interface PasswordStrengthRequest {
  $type: "idp.PasswordStrengthRequest";
  /**
   * ID of the user the password is for. If left empty the authenticated user is
   * used. If the calling entity doesn't have the idp.internal/pwinterop or
   * idp.internal/admin roles calls will be rejected with PermissionDenied if
   * the user_id is not either empty or set to the authenticated user.
   */
  userId: string;
  /** password is the password to analyze in plaintext */
  password: string;
}

export interface PasswordStrengthResponse {
  $type: "idp.PasswordStrengthResponse";
  /**
   * Set to true if the password is included in a database of breached database
   * hooked up to the IDP.
   */
  breached: boolean;
  /**
   * Estimated entropy of the given password in bits (i.e. log2). This does not
   * include entropy loss from breached passwords.
   */
  strengthBits: number;
  /**
   * Set to true if the password meets all password policies for the given user.
   * If this is not true subsequent calls to ChangePassword with the same
   * password will fail.
   */
  acceptable: boolean;
  /**
   * The password strength policy applied to the user. Can be used for more
   * accurate user feedback.
   */
  policy: PasswordStrengthPolicy | undefined;
}

export interface GeneratePasswordRequest {
  $type: "idp.GeneratePasswordRequest";
  count: number;
  family: GeneratedPasswordFamily;
}

export interface GeneratePasswordResponse {
  $type: "idp.GeneratePasswordResponse";
  password: string[];
}

export interface FrontendConfig {
  $type: "idp.FrontendConfig";
  identifier: string;
}

const baseClientProfile: object = { $type: "idp.ClientProfile", displayName: "", logoUrl: "" };

export const ClientProfile = {
  $type: "idp.ClientProfile" as const,

  encode(message: ClientProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displayName !== "") {
      writer.uint32(42).string(message.displayName);
    }
    if (message.logoUrl !== "") {
      writer.uint32(50).string(message.logoUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientProfile {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClientProfile } as ClientProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          message.displayName = reader.string();
          break;
        case 6:
          message.logoUrl = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ClientProfile>): ClientProfile {
    const message = { ...baseClientProfile } as ClientProfile;
    if (object.displayName !== undefined && object.displayName !== null) {
      message.displayName = object.displayName;
    }
    if (object.logoUrl !== undefined && object.logoUrl !== null) {
      message.logoUrl = object.logoUrl;
    }
    return message;
  },
};

messageTypeRegistry.set(ClientProfile.$type, ClientProfile);

const baseUserProfile: object = {
  $type: "idp.UserProfile",
  name: "",
  givenName: "",
  familyName: "",
  middleName: "",
  nickname: "",
  preferredUsername: "",
  profile: "",
  picture: "",
  website: "",
  gender: "",
  birthdate: "",
  zoneinfo: "",
  locale: "",
  updatedAt: 0,
};

export const UserProfile = {
  $type: "idp.UserProfile" as const,

  encode(message: UserProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.givenName !== "") {
      writer.uint32(18).string(message.givenName);
    }
    if (message.familyName !== "") {
      writer.uint32(26).string(message.familyName);
    }
    if (message.middleName !== "") {
      writer.uint32(34).string(message.middleName);
    }
    if (message.nickname !== "") {
      writer.uint32(42).string(message.nickname);
    }
    if (message.preferredUsername !== "") {
      writer.uint32(50).string(message.preferredUsername);
    }
    if (message.profile !== "") {
      writer.uint32(58).string(message.profile);
    }
    if (message.picture !== "") {
      writer.uint32(66).string(message.picture);
    }
    if (message.website !== "") {
      writer.uint32(74).string(message.website);
    }
    if (message.gender !== "") {
      writer.uint32(82).string(message.gender);
    }
    if (message.birthdate !== "") {
      writer.uint32(90).string(message.birthdate);
    }
    if (message.zoneinfo !== "") {
      writer.uint32(98).string(message.zoneinfo);
    }
    if (message.locale !== "") {
      writer.uint32(106).string(message.locale);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(112).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfile {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserProfile } as UserProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.givenName = reader.string();
          break;
        case 3:
          message.familyName = reader.string();
          break;
        case 4:
          message.middleName = reader.string();
          break;
        case 5:
          message.nickname = reader.string();
          break;
        case 6:
          message.preferredUsername = reader.string();
          break;
        case 7:
          message.profile = reader.string();
          break;
        case 8:
          message.picture = reader.string();
          break;
        case 9:
          message.website = reader.string();
          break;
        case 10:
          message.gender = reader.string();
          break;
        case 11:
          message.birthdate = reader.string();
          break;
        case 12:
          message.zoneinfo = reader.string();
          break;
        case 13:
          message.locale = reader.string();
          break;
        case 14:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<UserProfile>): UserProfile {
    const message = { ...baseUserProfile } as UserProfile;
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.givenName !== undefined && object.givenName !== null) {
      message.givenName = object.givenName;
    }
    if (object.familyName !== undefined && object.familyName !== null) {
      message.familyName = object.familyName;
    }
    if (object.middleName !== undefined && object.middleName !== null) {
      message.middleName = object.middleName;
    }
    if (object.nickname !== undefined && object.nickname !== null) {
      message.nickname = object.nickname;
    }
    if (object.preferredUsername !== undefined && object.preferredUsername !== null) {
      message.preferredUsername = object.preferredUsername;
    }
    if (object.profile !== undefined && object.profile !== null) {
      message.profile = object.profile;
    }
    if (object.picture !== undefined && object.picture !== null) {
      message.picture = object.picture;
    }
    if (object.website !== undefined && object.website !== null) {
      message.website = object.website;
    }
    if (object.gender !== undefined && object.gender !== null) {
      message.gender = object.gender;
    }
    if (object.birthdate !== undefined && object.birthdate !== null) {
      message.birthdate = object.birthdate;
    }
    if (object.zoneinfo !== undefined && object.zoneinfo !== null) {
      message.zoneinfo = object.zoneinfo;
    }
    if (object.locale !== undefined && object.locale !== null) {
      message.locale = object.locale;
    }
    if (object.updatedAt !== undefined && object.updatedAt !== null) {
      message.updatedAt = object.updatedAt;
    }
    return message;
  },
};

messageTypeRegistry.set(UserProfile.$type, UserProfile);

const baseUser: object = { $type: "idp.User", id: "", external: false, status: 0, memberOf: "" };

export const User = {
  $type: "idp.User" as const,

  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(90).string(message.id);
    }
    if (message.external === true) {
      writer.uint32(96).bool(message.external);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    for (const v of message.totpAuthenticator) {
      TOTPAuthenticator.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.webauthnAuthenticator) {
      WebauthnAuthenticator.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.emailAddress) {
      EmailAddress.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.phoneNumber) {
      PhoneNumber.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.password !== undefined) {
      Password.encode(message.password, writer.uint32(34).fork()).ldelim();
    }
    if (message.profile !== undefined) {
      UserProfile.encode(message.profile, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.memberOf) {
      writer.uint32(82).string(v!);
    }
    Object.entries(message.claim).forEach(([key, value]) => {
      User_ClaimEntry.encode(
        { $type: "idp.User.ClaimEntry", key: key as any, value },
        writer.uint32(58).fork()
      ).ldelim();
    });
    if (message.ghostSince !== undefined) {
      Timestamp.encode(toTimestamp(message.ghostSince), writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUser } as User;
    message.totpAuthenticator = [];
    message.webauthnAuthenticator = [];
    message.emailAddress = [];
    message.phoneNumber = [];
    message.memberOf = [];
    message.claim = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 11:
          message.id = reader.string();
          break;
        case 12:
          message.external = reader.bool();
          break;
        case 6:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.totpAuthenticator.push(TOTPAuthenticator.decode(reader, reader.uint32()));
          break;
        case 3:
          message.webauthnAuthenticator.push(WebauthnAuthenticator.decode(reader, reader.uint32()));
          break;
        case 5:
          message.emailAddress.push(EmailAddress.decode(reader, reader.uint32()));
          break;
        case 8:
          message.phoneNumber.push(PhoneNumber.decode(reader, reader.uint32()));
          break;
        case 4:
          message.password = Password.decode(reader, reader.uint32());
          break;
        case 9:
          message.profile = UserProfile.decode(reader, reader.uint32());
          break;
        case 10:
          message.memberOf.push(reader.string());
          break;
        case 7:
          const entry7 = User_ClaimEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.claim[entry7.key] = entry7.value;
          }
          break;
        case 13:
          message.ghostSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = { ...baseUser } as User;
    message.totpAuthenticator = [];
    message.webauthnAuthenticator = [];
    message.emailAddress = [];
    message.phoneNumber = [];
    message.memberOf = [];
    message.claim = {};
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.external !== undefined && object.external !== null) {
      message.external = object.external;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    }
    if (object.totpAuthenticator !== undefined && object.totpAuthenticator !== null) {
      for (const e of object.totpAuthenticator) {
        message.totpAuthenticator.push(TOTPAuthenticator.fromPartial(e));
      }
    }
    if (object.webauthnAuthenticator !== undefined && object.webauthnAuthenticator !== null) {
      for (const e of object.webauthnAuthenticator) {
        message.webauthnAuthenticator.push(WebauthnAuthenticator.fromPartial(e));
      }
    }
    if (object.emailAddress !== undefined && object.emailAddress !== null) {
      for (const e of object.emailAddress) {
        message.emailAddress.push(EmailAddress.fromPartial(e));
      }
    }
    if (object.phoneNumber !== undefined && object.phoneNumber !== null) {
      for (const e of object.phoneNumber) {
        message.phoneNumber.push(PhoneNumber.fromPartial(e));
      }
    }
    if (object.password !== undefined && object.password !== null) {
      message.password = Password.fromPartial(object.password);
    }
    if (object.profile !== undefined && object.profile !== null) {
      message.profile = UserProfile.fromPartial(object.profile);
    }
    if (object.memberOf !== undefined && object.memberOf !== null) {
      for (const e of object.memberOf) {
        message.memberOf.push(e);
      }
    }
    if (object.claim !== undefined && object.claim !== null) {
      Object.entries(object.claim).forEach(([key, value]) => {
        if (value !== undefined) {
          message.claim[key] = Value.fromPartial(value);
        }
      });
    }
    if (object.ghostSince !== undefined && object.ghostSince !== null) {
      message.ghostSince = object.ghostSince;
    }
    return message;
  },
};

messageTypeRegistry.set(User.$type, User);

const baseUser_ClaimEntry: object = { $type: "idp.User.ClaimEntry", key: "" };

export const User_ClaimEntry = {
  $type: "idp.User.ClaimEntry" as const,

  encode(message: User_ClaimEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_ClaimEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUser_ClaimEntry } as User_ClaimEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Value.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<User_ClaimEntry>): User_ClaimEntry {
    const message = { ...baseUser_ClaimEntry } as User_ClaimEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = Value.fromPartial(object.value);
    }
    return message;
  },
};

messageTypeRegistry.set(User_ClaimEntry.$type, User_ClaimEntry);

const baseAuthContinue: object = {
  $type: "idp.AuthContinue",
  prompt: "",
  nextMechanism: 0,
  done: false,
  mechanismHint: 0,
  hasUsernames: false,
  enrollPassword: false,
  enrollTotp: false,
  enrollWebauthn: false,
  enrollEmail: false,
  enrollBsrEmail: false,
  newSessionToken: "",
  userId: "",
};

export const AuthContinue = {
  $type: "idp.AuthContinue" as const,

  encode(message: AuthContinue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prompt !== "") {
      writer.uint32(42).string(message.prompt);
    }
    writer.uint32(10).fork();
    for (const v of message.nextMechanism) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.done === true) {
      writer.uint32(48).bool(message.done);
    }
    if (message.mechanismHint !== 0) {
      writer.uint32(144).int32(message.mechanismHint);
    }
    if (message.hasUsernames === true) {
      writer.uint32(128).bool(message.hasUsernames);
    }
    if (message.enrollPassword === true) {
      writer.uint32(96).bool(message.enrollPassword);
    }
    if (message.enrollTotp === true) {
      writer.uint32(80).bool(message.enrollTotp);
    }
    for (const v of message.totpAuthenticator) {
      TOTPAuthenticator.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.enrollWebauthn === true) {
      writer.uint32(88).bool(message.enrollWebauthn);
    }
    for (const v of message.webauthnAuthenticator) {
      WebauthnAuthenticator.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.enrollEmail === true) {
      writer.uint32(112).bool(message.enrollEmail);
    }
    for (const v of message.emailAddress) {
      EmailAddress.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.emailEnrollment !== undefined) {
      EmailEnrollment.encode(message.emailEnrollment, writer.uint32(154).fork()).ldelim();
    }
    if (message.enrollBsrEmail === true) {
      writer.uint32(136).bool(message.enrollBsrEmail);
    }
    if (message.nonce.length !== 0) {
      writer.uint32(58).bytes(message.nonce);
    }
    if (message.newSessionToken !== "") {
      writer.uint32(18).string(message.newSessionToken);
    }
    if (message.userProfile !== undefined) {
      UserProfile.encode(message.userProfile, writer.uint32(26).fork()).ldelim();
    }
    if (message.userId !== "") {
      writer.uint32(34).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthContinue {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthContinue } as AuthContinue;
    message.nextMechanism = [];
    message.totpAuthenticator = [];
    message.webauthnAuthenticator = [];
    message.emailAddress = [];
    message.nonce = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          message.prompt = reader.string();
          break;
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.nextMechanism.push(reader.int32() as any);
            }
          } else {
            message.nextMechanism.push(reader.int32() as any);
          }
          break;
        case 6:
          message.done = reader.bool();
          break;
        case 18:
          message.mechanismHint = reader.int32() as any;
          break;
        case 16:
          message.hasUsernames = reader.bool();
          break;
        case 12:
          message.enrollPassword = reader.bool();
          break;
        case 10:
          message.enrollTotp = reader.bool();
          break;
        case 8:
          message.totpAuthenticator.push(TOTPAuthenticator.decode(reader, reader.uint32()));
          break;
        case 11:
          message.enrollWebauthn = reader.bool();
          break;
        case 9:
          message.webauthnAuthenticator.push(WebauthnAuthenticator.decode(reader, reader.uint32()));
          break;
        case 14:
          message.enrollEmail = reader.bool();
          break;
        case 15:
          message.emailAddress.push(EmailAddress.decode(reader, reader.uint32()));
          break;
        case 19:
          message.emailEnrollment = EmailEnrollment.decode(reader, reader.uint32());
          break;
        case 17:
          message.enrollBsrEmail = reader.bool();
          break;
        case 7:
          message.nonce = reader.bytes();
          break;
        case 2:
          message.newSessionToken = reader.string();
          break;
        case 3:
          message.userProfile = UserProfile.decode(reader, reader.uint32());
          break;
        case 4:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthContinue>): AuthContinue {
    const message = { ...baseAuthContinue } as AuthContinue;
    message.nextMechanism = [];
    message.totpAuthenticator = [];
    message.webauthnAuthenticator = [];
    message.emailAddress = [];
    if (object.prompt !== undefined && object.prompt !== null) {
      message.prompt = object.prompt;
    }
    if (object.nextMechanism !== undefined && object.nextMechanism !== null) {
      for (const e of object.nextMechanism) {
        message.nextMechanism.push(e);
      }
    }
    if (object.done !== undefined && object.done !== null) {
      message.done = object.done;
    }
    if (object.mechanismHint !== undefined && object.mechanismHint !== null) {
      message.mechanismHint = object.mechanismHint;
    }
    if (object.hasUsernames !== undefined && object.hasUsernames !== null) {
      message.hasUsernames = object.hasUsernames;
    }
    if (object.enrollPassword !== undefined && object.enrollPassword !== null) {
      message.enrollPassword = object.enrollPassword;
    }
    if (object.enrollTotp !== undefined && object.enrollTotp !== null) {
      message.enrollTotp = object.enrollTotp;
    }
    if (object.totpAuthenticator !== undefined && object.totpAuthenticator !== null) {
      for (const e of object.totpAuthenticator) {
        message.totpAuthenticator.push(TOTPAuthenticator.fromPartial(e));
      }
    }
    if (object.enrollWebauthn !== undefined && object.enrollWebauthn !== null) {
      message.enrollWebauthn = object.enrollWebauthn;
    }
    if (object.webauthnAuthenticator !== undefined && object.webauthnAuthenticator !== null) {
      for (const e of object.webauthnAuthenticator) {
        message.webauthnAuthenticator.push(WebauthnAuthenticator.fromPartial(e));
      }
    }
    if (object.enrollEmail !== undefined && object.enrollEmail !== null) {
      message.enrollEmail = object.enrollEmail;
    }
    if (object.emailAddress !== undefined && object.emailAddress !== null) {
      for (const e of object.emailAddress) {
        message.emailAddress.push(EmailAddress.fromPartial(e));
      }
    }
    if (object.emailEnrollment !== undefined && object.emailEnrollment !== null) {
      message.emailEnrollment = EmailEnrollment.fromPartial(object.emailEnrollment);
    }
    if (object.enrollBsrEmail !== undefined && object.enrollBsrEmail !== null) {
      message.enrollBsrEmail = object.enrollBsrEmail;
    }
    if (object.nonce !== undefined && object.nonce !== null) {
      message.nonce = object.nonce;
    }
    if (object.newSessionToken !== undefined && object.newSessionToken !== null) {
      message.newSessionToken = object.newSessionToken;
    }
    if (object.userProfile !== undefined && object.userProfile !== null) {
      message.userProfile = UserProfile.fromPartial(object.userProfile);
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthContinue.$type, AuthContinue);

const baseEmailEnrollment: object = {
  $type: "idp.EmailEnrollment",
  required: false,
  domains: "",
  notDomains: "",
};

export const EmailEnrollment = {
  $type: "idp.EmailEnrollment" as const,

  encode(message: EmailEnrollment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.required === true) {
      writer.uint32(8).bool(message.required);
    }
    for (const v of message.domains) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.notDomains) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailEnrollment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEmailEnrollment } as EmailEnrollment;
    message.domains = [];
    message.notDomains = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.required = reader.bool();
          break;
        case 2:
          message.domains.push(reader.string());
          break;
        case 3:
          message.notDomains.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<EmailEnrollment>): EmailEnrollment {
    const message = { ...baseEmailEnrollment } as EmailEnrollment;
    message.domains = [];
    message.notDomains = [];
    if (object.required !== undefined && object.required !== null) {
      message.required = object.required;
    }
    if (object.domains !== undefined && object.domains !== null) {
      for (const e of object.domains) {
        message.domains.push(e);
      }
    }
    if (object.notDomains !== undefined && object.notDomains !== null) {
      for (const e of object.notDomains) {
        message.notDomains.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(EmailEnrollment.$type, EmailEnrollment);

const baseBranding: object = {
  $type: "idp.Branding",
  brand: "",
  name: "",
  logoFilename: "",
  logoStyle: 0,
  background: "",
  buttonColor: "",
  supportContactText: "",
  accessDeniedText: "",
  privacyPolicy: "",
};

export const Branding = {
  $type: "idp.Branding" as const,

  encode(message: Branding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== "") {
      writer.uint32(10).string(message.brand);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.logoFilename !== "") {
      writer.uint32(26).string(message.logoFilename);
    }
    if (message.logoStyle !== 0) {
      writer.uint32(64).int32(message.logoStyle);
    }
    if (message.background !== "") {
      writer.uint32(50).string(message.background);
    }
    if (message.buttonColor !== "") {
      writer.uint32(58).string(message.buttonColor);
    }
    if (message.supportContactText !== "") {
      writer.uint32(34).string(message.supportContactText);
    }
    if (message.accessDeniedText !== "") {
      writer.uint32(42).string(message.accessDeniedText);
    }
    if (message.privacyPolicy !== "") {
      writer.uint32(74).string(message.privacyPolicy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Branding {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBranding } as Branding;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brand = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.logoFilename = reader.string();
          break;
        case 8:
          message.logoStyle = reader.int32() as any;
          break;
        case 6:
          message.background = reader.string();
          break;
        case 7:
          message.buttonColor = reader.string();
          break;
        case 4:
          message.supportContactText = reader.string();
          break;
        case 5:
          message.accessDeniedText = reader.string();
          break;
        case 9:
          message.privacyPolicy = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Branding>): Branding {
    const message = { ...baseBranding } as Branding;
    if (object.brand !== undefined && object.brand !== null) {
      message.brand = object.brand;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.logoFilename !== undefined && object.logoFilename !== null) {
      message.logoFilename = object.logoFilename;
    }
    if (object.logoStyle !== undefined && object.logoStyle !== null) {
      message.logoStyle = object.logoStyle;
    }
    if (object.background !== undefined && object.background !== null) {
      message.background = object.background;
    }
    if (object.buttonColor !== undefined && object.buttonColor !== null) {
      message.buttonColor = object.buttonColor;
    }
    if (object.supportContactText !== undefined && object.supportContactText !== null) {
      message.supportContactText = object.supportContactText;
    }
    if (object.accessDeniedText !== undefined && object.accessDeniedText !== null) {
      message.accessDeniedText = object.accessDeniedText;
    }
    if (object.privacyPolicy !== undefined && object.privacyPolicy !== null) {
      message.privacyPolicy = object.privacyPolicy;
    }
    return message;
  },
};

messageTypeRegistry.set(Branding.$type, Branding);

const baseAuthPasswordRequest: object = { $type: "idp.AuthPasswordRequest", password: "" };

export const AuthPasswordRequest = {
  $type: "idp.AuthPasswordRequest" as const,

  encode(message: AuthPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(18).fork()).ldelim();
    }
    if (message.password !== "") {
      writer.uint32(10).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthPasswordRequest } as AuthPasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.authCtx = AuthContext.decode(reader, reader.uint32());
          break;
        case 1:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthPasswordRequest>): AuthPasswordRequest {
    const message = { ...baseAuthPasswordRequest } as AuthPasswordRequest;
    if (object.authCtx !== undefined && object.authCtx !== null) {
      message.authCtx = AuthContext.fromPartial(object.authCtx);
    }
    if (object.password !== undefined && object.password !== null) {
      message.password = object.password;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthPasswordRequest.$type, AuthPasswordRequest);

const baseAuthPasswordResponse: object = { $type: "idp.AuthPasswordResponse", result: 0 };

export const AuthPasswordResponse = {
  $type: "idp.AuthPasswordResponse" as const,

  encode(message: AuthPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthPasswordResponse } as AuthPasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthPasswordResponse>): AuthPasswordResponse {
    const message = { ...baseAuthPasswordResponse } as AuthPasswordResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthPasswordResponse.$type, AuthPasswordResponse);

const baseChangePasswordRequest: object = {
  $type: "idp.ChangePasswordRequest",
  userId: "",
  fromPwinterop: false,
  oldPassword: "",
  newPassword: "",
};

export const ChangePasswordRequest = {
  $type: "idp.ChangePasswordRequest" as const,

  encode(message: ChangePasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.fromPwinterop === true) {
      writer.uint32(40).bool(message.fromPwinterop);
    }
    if (message.oldPassword !== "") {
      writer.uint32(10).string(message.oldPassword);
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangePasswordRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseChangePasswordRequest } as ChangePasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.userId = reader.string();
          break;
        case 5:
          message.fromPwinterop = reader.bool();
          break;
        case 1:
          message.oldPassword = reader.string();
          break;
        case 2:
          message.newPassword = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ChangePasswordRequest>): ChangePasswordRequest {
    const message = { ...baseChangePasswordRequest } as ChangePasswordRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.fromPwinterop !== undefined && object.fromPwinterop !== null) {
      message.fromPwinterop = object.fromPwinterop;
    }
    if (object.oldPassword !== undefined && object.oldPassword !== null) {
      message.oldPassword = object.oldPassword;
    }
    if (object.newPassword !== undefined && object.newPassword !== null) {
      message.newPassword = object.newPassword;
    }
    return message;
  },
};

messageTypeRegistry.set(ChangePasswordRequest.$type, ChangePasswordRequest);

const baseChangePasswordResponse: object = { $type: "idp.ChangePasswordResponse" };

export const ChangePasswordResponse = {
  $type: "idp.ChangePasswordResponse" as const,

  encode(_: ChangePasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangePasswordResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseChangePasswordResponse } as ChangePasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ChangePasswordResponse>): ChangePasswordResponse {
    const message = { ...baseChangePasswordResponse } as ChangePasswordResponse;
    return message;
  },
};

messageTypeRegistry.set(ChangePasswordResponse.$type, ChangePasswordResponse);

const basePassword: object = {
  $type: "idp.Password",
  algorithm: 0,
  timeComplexity: 0,
  memoryComplexity: 0,
  estimatedEntropyBits: 0,
  breached: false,
  forceChange: false,
};

export const Password = {
  $type: "idp.Password" as const,

  encode(message: Password, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.algorithm !== 0) {
      writer.uint32(8).int32(message.algorithm);
    }
    if (message.derivedKey.length !== 0) {
      writer.uint32(18).bytes(message.derivedKey);
    }
    if (message.salt.length !== 0) {
      writer.uint32(26).bytes(message.salt);
    }
    if (message.timeComplexity !== 0) {
      writer.uint32(32).uint32(message.timeComplexity);
    }
    if (message.memoryComplexity !== 0) {
      writer.uint32(40).uint32(message.memoryComplexity);
    }
    if (message.estimatedEntropyBits !== 0) {
      writer.uint32(49).double(message.estimatedEntropyBits);
    }
    if (message.breached === true) {
      writer.uint32(72).bool(message.breached);
    }
    if (message.lastChanged !== undefined) {
      Timestamp.encode(toTimestamp(message.lastChanged), writer.uint32(58).fork()).ldelim();
    }
    if (message.forceChange === true) {
      writer.uint32(64).bool(message.forceChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Password {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePassword } as Password;
    message.derivedKey = new Uint8Array();
    message.salt = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.algorithm = reader.int32() as any;
          break;
        case 2:
          message.derivedKey = reader.bytes();
          break;
        case 3:
          message.salt = reader.bytes();
          break;
        case 4:
          message.timeComplexity = reader.uint32();
          break;
        case 5:
          message.memoryComplexity = reader.uint32();
          break;
        case 6:
          message.estimatedEntropyBits = reader.double();
          break;
        case 9:
          message.breached = reader.bool();
          break;
        case 7:
          message.lastChanged = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.forceChange = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Password>): Password {
    const message = { ...basePassword } as Password;
    if (object.algorithm !== undefined && object.algorithm !== null) {
      message.algorithm = object.algorithm;
    }
    if (object.derivedKey !== undefined && object.derivedKey !== null) {
      message.derivedKey = object.derivedKey;
    }
    if (object.salt !== undefined && object.salt !== null) {
      message.salt = object.salt;
    }
    if (object.timeComplexity !== undefined && object.timeComplexity !== null) {
      message.timeComplexity = object.timeComplexity;
    }
    if (object.memoryComplexity !== undefined && object.memoryComplexity !== null) {
      message.memoryComplexity = object.memoryComplexity;
    }
    if (object.estimatedEntropyBits !== undefined && object.estimatedEntropyBits !== null) {
      message.estimatedEntropyBits = object.estimatedEntropyBits;
    }
    if (object.breached !== undefined && object.breached !== null) {
      message.breached = object.breached;
    }
    if (object.lastChanged !== undefined && object.lastChanged !== null) {
      message.lastChanged = object.lastChanged;
    }
    if (object.forceChange !== undefined && object.forceChange !== null) {
      message.forceChange = object.forceChange;
    }
    return message;
  },
};

messageTypeRegistry.set(Password.$type, Password);

const baseSecretRisk: object = { $type: "idp.SecretRisk", tokenBucket: 0, lockedOut: false };

export const SecretRisk = {
  $type: "idp.SecretRisk" as const,

  encode(message: SecretRisk, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastUpdated !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdated), writer.uint32(18).fork()).ldelim();
    }
    if (message.tokenBucket !== 0) {
      writer.uint32(25).double(message.tokenBucket);
    }
    if (message.lockedOut === true) {
      writer.uint32(32).bool(message.lockedOut);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretRisk {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSecretRisk } as SecretRisk;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.lastUpdated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.tokenBucket = reader.double();
          break;
        case 4:
          message.lockedOut = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SecretRisk>): SecretRisk {
    const message = { ...baseSecretRisk } as SecretRisk;
    if (object.lastUpdated !== undefined && object.lastUpdated !== null) {
      message.lastUpdated = object.lastUpdated;
    }
    if (object.tokenBucket !== undefined && object.tokenBucket !== null) {
      message.tokenBucket = object.tokenBucket;
    }
    if (object.lockedOut !== undefined && object.lockedOut !== null) {
      message.lockedOut = object.lockedOut;
    }
    return message;
  },
};

messageTypeRegistry.set(SecretRisk.$type, SecretRisk);

const baseTOTPAuthenticator: object = {
  $type: "idp.TOTPAuthenticator",
  name: "",
  digits: 0,
  stepDuration: 0,
  hash: 0,
};

export const TOTPAuthenticator = {
  $type: "idp.TOTPAuthenticator" as const,

  encode(message: TOTPAuthenticator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.digits !== 0) {
      writer.uint32(32).uint32(message.digits);
    }
    if (message.stepDuration !== 0) {
      writer.uint32(40).uint32(message.stepDuration);
    }
    if (message.hash !== 0) {
      writer.uint32(48).int32(message.hash);
    }
    if (message.secret.length !== 0) {
      writer.uint32(26).bytes(message.secret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TOTPAuthenticator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTOTPAuthenticator } as TOTPAuthenticator;
    message.id = new Uint8Array();
    message.secret = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 4:
          message.digits = reader.uint32();
          break;
        case 5:
          message.stepDuration = reader.uint32();
          break;
        case 6:
          message.hash = reader.int32() as any;
          break;
        case 3:
          message.secret = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<TOTPAuthenticator>): TOTPAuthenticator {
    const message = { ...baseTOTPAuthenticator } as TOTPAuthenticator;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.digits !== undefined && object.digits !== null) {
      message.digits = object.digits;
    }
    if (object.stepDuration !== undefined && object.stepDuration !== null) {
      message.stepDuration = object.stepDuration;
    }
    if (object.hash !== undefined && object.hash !== null) {
      message.hash = object.hash;
    }
    if (object.secret !== undefined && object.secret !== null) {
      message.secret = object.secret;
    }
    return message;
  },
};

messageTypeRegistry.set(TOTPAuthenticator.$type, TOTPAuthenticator);

const baseAuthTOTPRequest: object = { $type: "idp.AuthTOTPRequest", code: 0 };

export const AuthTOTPRequest = {
  $type: "idp.AuthTOTPRequest" as const,

  encode(message: AuthTOTPRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(26).fork()).ldelim();
    }
    if (message.authenticatorId.length !== 0) {
      writer.uint32(10).bytes(message.authenticatorId);
    }
    if (message.code !== 0) {
      writer.uint32(16).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthTOTPRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthTOTPRequest } as AuthTOTPRequest;
    message.authenticatorId = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.authCtx = AuthContext.decode(reader, reader.uint32());
          break;
        case 1:
          message.authenticatorId = reader.bytes();
          break;
        case 2:
          message.code = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthTOTPRequest>): AuthTOTPRequest {
    const message = { ...baseAuthTOTPRequest } as AuthTOTPRequest;
    if (object.authCtx !== undefined && object.authCtx !== null) {
      message.authCtx = AuthContext.fromPartial(object.authCtx);
    }
    if (object.authenticatorId !== undefined && object.authenticatorId !== null) {
      message.authenticatorId = object.authenticatorId;
    }
    if (object.code !== undefined && object.code !== null) {
      message.code = object.code;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthTOTPRequest.$type, AuthTOTPRequest);

const baseAuthTOTPResponse: object = { $type: "idp.AuthTOTPResponse", result: 0 };

export const AuthTOTPResponse = {
  $type: "idp.AuthTOTPResponse" as const,

  encode(message: AuthTOTPResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthTOTPResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthTOTPResponse } as AuthTOTPResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthTOTPResponse>): AuthTOTPResponse {
    const message = { ...baseAuthTOTPResponse } as AuthTOTPResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthTOTPResponse.$type, AuthTOTPResponse);

const baseAddTOTPAuthenticatorRequest: object = {
  $type: "idp.AddTOTPAuthenticatorRequest",
  code: 0,
};

export const AddTOTPAuthenticatorRequest = {
  $type: "idp.AddTOTPAuthenticatorRequest" as const,

  encode(
    message: AddTOTPAuthenticatorRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.authenticator !== undefined) {
      TOTPAuthenticator.encode(message.authenticator, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== 0) {
      writer.uint32(16).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTOTPAuthenticatorRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddTOTPAuthenticatorRequest } as AddTOTPAuthenticatorRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.authenticator = TOTPAuthenticator.decode(reader, reader.uint32());
          break;
        case 2:
          message.code = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AddTOTPAuthenticatorRequest>): AddTOTPAuthenticatorRequest {
    const message = { ...baseAddTOTPAuthenticatorRequest } as AddTOTPAuthenticatorRequest;
    if (object.authenticator !== undefined && object.authenticator !== null) {
      message.authenticator = TOTPAuthenticator.fromPartial(object.authenticator);
    }
    if (object.code !== undefined && object.code !== null) {
      message.code = object.code;
    }
    return message;
  },
};

messageTypeRegistry.set(AddTOTPAuthenticatorRequest.$type, AddTOTPAuthenticatorRequest);

const baseAddTOTPAuthenticatorResponse: object = { $type: "idp.AddTOTPAuthenticatorResponse" };

export const AddTOTPAuthenticatorResponse = {
  $type: "idp.AddTOTPAuthenticatorResponse" as const,

  encode(_: AddTOTPAuthenticatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTOTPAuthenticatorResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddTOTPAuthenticatorResponse } as AddTOTPAuthenticatorResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AddTOTPAuthenticatorResponse>): AddTOTPAuthenticatorResponse {
    const message = { ...baseAddTOTPAuthenticatorResponse } as AddTOTPAuthenticatorResponse;
    return message;
  },
};

messageTypeRegistry.set(AddTOTPAuthenticatorResponse.$type, AddTOTPAuthenticatorResponse);

const baseWebauthnAuthenticator: object = {
  $type: "idp.WebauthnAuthenticator",
  name: "",
  algorithm: 0,
  counter: 0,
};

export const WebauthnAuthenticator = {
  $type: "idp.WebauthnAuthenticator" as const,

  encode(message: WebauthnAuthenticator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.algorithm !== 0) {
      writer.uint32(24).int32(message.algorithm);
    }
    if (message.publicKey !== undefined) {
      PublicKey.encode(message.publicKey, writer.uint32(34).fork()).ldelim();
    }
    if (message.attestationData.length !== 0) {
      writer.uint32(50).bytes(message.attestationData);
    }
    if (message.counter !== 0) {
      writer.uint32(40).int64(message.counter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebauthnAuthenticator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWebauthnAuthenticator } as WebauthnAuthenticator;
    message.id = new Uint8Array();
    message.attestationData = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.algorithm = reader.int32() as any;
          break;
        case 4:
          message.publicKey = PublicKey.decode(reader, reader.uint32());
          break;
        case 6:
          message.attestationData = reader.bytes();
          break;
        case 5:
          message.counter = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<WebauthnAuthenticator>): WebauthnAuthenticator {
    const message = { ...baseWebauthnAuthenticator } as WebauthnAuthenticator;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.algorithm !== undefined && object.algorithm !== null) {
      message.algorithm = object.algorithm;
    }
    if (object.publicKey !== undefined && object.publicKey !== null) {
      message.publicKey = PublicKey.fromPartial(object.publicKey);
    }
    if (object.attestationData !== undefined && object.attestationData !== null) {
      message.attestationData = object.attestationData;
    }
    if (object.counter !== undefined && object.counter !== null) {
      message.counter = object.counter;
    }
    return message;
  },
};

messageTypeRegistry.set(WebauthnAuthenticator.$type, WebauthnAuthenticator);

const baseAddWebauthnRequest: object = { $type: "idp.AddWebauthnRequest", name: "" };

export const AddWebauthnRequest = {
  $type: "idp.AddWebauthnRequest" as const,

  encode(message: AddWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.id.length !== 0) {
      writer.uint32(18).bytes(message.id);
    }
    if (message.clientDataJson.length !== 0) {
      writer.uint32(26).bytes(message.clientDataJson);
    }
    if (message.attestationObject.length !== 0) {
      writer.uint32(34).bytes(message.attestationObject);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddWebauthnRequest } as AddWebauthnRequest;
    message.id = new Uint8Array();
    message.clientDataJson = new Uint8Array();
    message.attestationObject = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.id = reader.bytes();
          break;
        case 3:
          message.clientDataJson = reader.bytes();
          break;
        case 4:
          message.attestationObject = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AddWebauthnRequest>): AddWebauthnRequest {
    const message = { ...baseAddWebauthnRequest } as AddWebauthnRequest;
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.clientDataJson !== undefined && object.clientDataJson !== null) {
      message.clientDataJson = object.clientDataJson;
    }
    if (object.attestationObject !== undefined && object.attestationObject !== null) {
      message.attestationObject = object.attestationObject;
    }
    return message;
  },
};

messageTypeRegistry.set(AddWebauthnRequest.$type, AddWebauthnRequest);

const baseAddWebauthnResponse: object = { $type: "idp.AddWebauthnResponse" };

export const AddWebauthnResponse = {
  $type: "idp.AddWebauthnResponse" as const,

  encode(_: AddWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddWebauthnResponse } as AddWebauthnResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AddWebauthnResponse>): AddWebauthnResponse {
    const message = { ...baseAddWebauthnResponse } as AddWebauthnResponse;
    return message;
  },
};

messageTypeRegistry.set(AddWebauthnResponse.$type, AddWebauthnResponse);

const baseAuthWebauthnRequest: object = { $type: "idp.AuthWebauthnRequest" };

export const AuthWebauthnRequest = {
  $type: "idp.AuthWebauthnRequest" as const,

  encode(message: AuthWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(50).fork()).ldelim();
    }
    if (message.id.length !== 0) {
      writer.uint32(42).bytes(message.id);
    }
    if (message.clientDataJson.length !== 0) {
      writer.uint32(10).bytes(message.clientDataJson);
    }
    if (message.authenticatorData.length !== 0) {
      writer.uint32(18).bytes(message.authenticatorData);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    if (message.userHandle.length !== 0) {
      writer.uint32(34).bytes(message.userHandle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthWebauthnRequest } as AuthWebauthnRequest;
    message.id = new Uint8Array();
    message.clientDataJson = new Uint8Array();
    message.authenticatorData = new Uint8Array();
    message.signature = new Uint8Array();
    message.userHandle = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          message.authCtx = AuthContext.decode(reader, reader.uint32());
          break;
        case 5:
          message.id = reader.bytes();
          break;
        case 1:
          message.clientDataJson = reader.bytes();
          break;
        case 2:
          message.authenticatorData = reader.bytes();
          break;
        case 3:
          message.signature = reader.bytes();
          break;
        case 4:
          message.userHandle = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthWebauthnRequest>): AuthWebauthnRequest {
    const message = { ...baseAuthWebauthnRequest } as AuthWebauthnRequest;
    if (object.authCtx !== undefined && object.authCtx !== null) {
      message.authCtx = AuthContext.fromPartial(object.authCtx);
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.clientDataJson !== undefined && object.clientDataJson !== null) {
      message.clientDataJson = object.clientDataJson;
    }
    if (object.authenticatorData !== undefined && object.authenticatorData !== null) {
      message.authenticatorData = object.authenticatorData;
    }
    if (object.signature !== undefined && object.signature !== null) {
      message.signature = object.signature;
    }
    if (object.userHandle !== undefined && object.userHandle !== null) {
      message.userHandle = object.userHandle;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthWebauthnRequest.$type, AuthWebauthnRequest);

const baseAuthWebauthnResponse: object = { $type: "idp.AuthWebauthnResponse", result: 0 };

export const AuthWebauthnResponse = {
  $type: "idp.AuthWebauthnResponse" as const,

  encode(message: AuthWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthWebauthnResponse } as AuthWebauthnResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthWebauthnResponse>): AuthWebauthnResponse {
    const message = { ...baseAuthWebauthnResponse } as AuthWebauthnResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthWebauthnResponse.$type, AuthWebauthnResponse);

const baseSendAuthMailRequest: object = {
  $type: "idp.SendAuthMailRequest",
  type: 0,
  mechanismToBeReset: 0,
};

export const SendAuthMailRequest = {
  $type: "idp.SendAuthMailRequest" as const,

  encode(message: SendAuthMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.mechanismToBeReset !== 0) {
      writer.uint32(32).int32(message.mechanismToBeReset);
    }
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendAuthMailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSendAuthMailRequest } as SendAuthMailRequest;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.authCtx = AuthContext.decode(reader, reader.uint32());
          break;
        case 3:
          message.type = reader.int32() as any;
          break;
        case 4:
          message.mechanismToBeReset = reader.int32() as any;
          break;
        case 1:
          message.id = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SendAuthMailRequest>): SendAuthMailRequest {
    const message = { ...baseSendAuthMailRequest } as SendAuthMailRequest;
    if (object.authCtx !== undefined && object.authCtx !== null) {
      message.authCtx = AuthContext.fromPartial(object.authCtx);
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    }
    if (object.mechanismToBeReset !== undefined && object.mechanismToBeReset !== null) {
      message.mechanismToBeReset = object.mechanismToBeReset;
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(SendAuthMailRequest.$type, SendAuthMailRequest);

const baseSendAuthMailResponse: object = { $type: "idp.SendAuthMailResponse" };

export const SendAuthMailResponse = {
  $type: "idp.SendAuthMailResponse" as const,

  encode(_: SendAuthMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendAuthMailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSendAuthMailResponse } as SendAuthMailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SendAuthMailResponse>): SendAuthMailResponse {
    const message = { ...baseSendAuthMailResponse } as SendAuthMailResponse;
    return message;
  },
};

messageTypeRegistry.set(SendAuthMailResponse.$type, SendAuthMailResponse);

const baseEmailAddress: object = {
  $type: "idp.EmailAddress",
  trust: 0,
  external: false,
  localPart: "",
  domain: "",
};

export const EmailAddress = {
  $type: "idp.EmailAddress" as const,

  encode(message: EmailAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.trust !== 0) {
      writer.uint32(32).int32(message.trust);
    }
    if (message.external === true) {
      writer.uint32(40).bool(message.external);
    }
    if (message.localPart !== "") {
      writer.uint32(18).string(message.localPart);
    }
    if (message.domain !== "") {
      writer.uint32(26).string(message.domain);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailAddress {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEmailAddress } as EmailAddress;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        case 4:
          message.trust = reader.int32() as any;
          break;
        case 5:
          message.external = reader.bool();
          break;
        case 2:
          message.localPart = reader.string();
          break;
        case 3:
          message.domain = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<EmailAddress>): EmailAddress {
    const message = { ...baseEmailAddress } as EmailAddress;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.trust !== undefined && object.trust !== null) {
      message.trust = object.trust;
    }
    if (object.external !== undefined && object.external !== null) {
      message.external = object.external;
    }
    if (object.localPart !== undefined && object.localPart !== null) {
      message.localPart = object.localPart;
    }
    if (object.domain !== undefined && object.domain !== null) {
      message.domain = object.domain;
    }
    return message;
  },
};

messageTypeRegistry.set(EmailAddress.$type, EmailAddress);

const basePhoneNumber: object = { $type: "idp.PhoneNumber", trust: 0, external: false };

export const PhoneNumber = {
  $type: "idp.PhoneNumber" as const,

  encode(message: PhoneNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.trust !== 0) {
      writer.uint32(16).int32(message.trust);
    }
    if (message.external === true) {
      writer.uint32(32).bool(message.external);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhoneNumber {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePhoneNumber } as PhoneNumber;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        case 2:
          message.trust = reader.int32() as any;
          break;
        case 4:
          message.external = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<PhoneNumber>): PhoneNumber {
    const message = { ...basePhoneNumber } as PhoneNumber;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.trust !== undefined && object.trust !== null) {
      message.trust = object.trust;
    }
    if (object.external !== undefined && object.external !== null) {
      message.external = object.external;
    }
    return message;
  },
};

messageTypeRegistry.set(PhoneNumber.$type, PhoneNumber);

const baseAuthBackchannelRequest: object = { $type: "idp.AuthBackchannelRequest", code: 0 };

export const AuthBackchannelRequest = {
  $type: "idp.AuthBackchannelRequest" as const,

  encode(message: AuthBackchannelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthBackchannelRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthBackchannelRequest } as AuthBackchannelRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.code = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthBackchannelRequest>): AuthBackchannelRequest {
    const message = { ...baseAuthBackchannelRequest } as AuthBackchannelRequest;
    if (object.code !== undefined && object.code !== null) {
      message.code = object.code;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthBackchannelRequest.$type, AuthBackchannelRequest);

const baseAuthBackchannelResponse: object = { $type: "idp.AuthBackchannelResponse", result: 0 };

export const AuthBackchannelResponse = {
  $type: "idp.AuthBackchannelResponse" as const,

  encode(message: AuthBackchannelResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthBackchannelResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthBackchannelResponse } as AuthBackchannelResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthBackchannelResponse>): AuthBackchannelResponse {
    const message = { ...baseAuthBackchannelResponse } as AuthBackchannelResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthBackchannelResponse.$type, AuthBackchannelResponse);

const baseAuthUsernameRequest: object = { $type: "idp.AuthUsernameRequest", username: "" };

export const AuthUsernameRequest = {
  $type: "idp.AuthUsernameRequest" as const,

  encode(message: AuthUsernameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthUsernameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthUsernameRequest } as AuthUsernameRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.username = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthUsernameRequest>): AuthUsernameRequest {
    const message = { ...baseAuthUsernameRequest } as AuthUsernameRequest;
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthUsernameRequest.$type, AuthUsernameRequest);

const baseAuthUsernameResponse: object = { $type: "idp.AuthUsernameResponse", result: 0 };

export const AuthUsernameResponse = {
  $type: "idp.AuthUsernameResponse" as const,

  encode(message: AuthUsernameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthUsernameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthUsernameResponse } as AuthUsernameResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthUsernameResponse>): AuthUsernameResponse {
    const message = { ...baseAuthUsernameResponse } as AuthUsernameResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthUsernameResponse.$type, AuthUsernameResponse);

const baseAuthKerberosRequest: object = { $type: "idp.AuthKerberosRequest", token: "" };

export const AuthKerberosRequest = {
  $type: "idp.AuthKerberosRequest" as const,

  encode(message: AuthKerberosRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthKerberosRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthKerberosRequest } as AuthKerberosRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthKerberosRequest>): AuthKerberosRequest {
    const message = { ...baseAuthKerberosRequest } as AuthKerberosRequest;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthKerberosRequest.$type, AuthKerberosRequest);

const baseAuthKerberosResponse: object = { $type: "idp.AuthKerberosResponse", result: 0 };

export const AuthKerberosResponse = {
  $type: "idp.AuthKerberosResponse" as const,

  encode(message: AuthKerberosResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthKerberosResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthKerberosResponse } as AuthKerberosResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthKerberosResponse>): AuthKerberosResponse {
    const message = { ...baseAuthKerberosResponse } as AuthKerberosResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthKerberosResponse.$type, AuthKerberosResponse);

const baseAuthContext: object = {
  $type: "idp.AuthContext",
  authenticationContextClass: "",
  maxAgeSeconds: 0,
};

export const AuthContext = {
  $type: "idp.AuthContext" as const,

  encode(message: AuthContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.authenticationContextClass) {
      writer.uint32(10).string(v!);
    }
    if (message.maxAgeSeconds !== 0) {
      writer.uint32(24).uint32(message.maxAgeSeconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthContext {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthContext } as AuthContext;
    message.authenticationContextClass = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.authenticationContextClass.push(reader.string());
          break;
        case 3:
          message.maxAgeSeconds = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthContext>): AuthContext {
    const message = { ...baseAuthContext } as AuthContext;
    message.authenticationContextClass = [];
    if (
      object.authenticationContextClass !== undefined &&
      object.authenticationContextClass !== null
    ) {
      for (const e of object.authenticationContextClass) {
        message.authenticationContextClass.push(e);
      }
    }
    if (object.maxAgeSeconds !== undefined && object.maxAgeSeconds !== null) {
      message.maxAgeSeconds = object.maxAgeSeconds;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthContext.$type, AuthContext);

const baseAuthStartRequest: object = { $type: "idp.AuthStartRequest" };

export const AuthStartRequest = {
  $type: "idp.AuthStartRequest" as const,

  encode(message: AuthStartRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthStartRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthStartRequest } as AuthStartRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.authCtx = AuthContext.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthStartRequest>): AuthStartRequest {
    const message = { ...baseAuthStartRequest } as AuthStartRequest;
    if (object.authCtx !== undefined && object.authCtx !== null) {
      message.authCtx = AuthContext.fromPartial(object.authCtx);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthStartRequest.$type, AuthStartRequest);

const baseAuthStartResponse: object = { $type: "idp.AuthStartResponse", result: 0 };

export const AuthStartResponse = {
  $type: "idp.AuthStartResponse" as const,

  encode(message: AuthStartResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    if (message.clientProfile !== undefined) {
      ClientProfile.encode(message.clientProfile, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthStartResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthStartResponse } as AuthStartResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = reader.int32() as any;
          break;
        case 2:
          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          break;
        case 3:
          message.clientProfile = ClientProfile.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthStartResponse>): AuthStartResponse {
    const message = { ...baseAuthStartResponse } as AuthStartResponse;
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authContinue !== undefined && object.authContinue !== null) {
      message.authContinue = AuthContinue.fromPartial(object.authContinue);
    }
    if (object.clientProfile !== undefined && object.clientProfile !== null) {
      message.clientProfile = ClientProfile.fromPartial(object.clientProfile);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthStartResponse.$type, AuthStartResponse);

const baseLogoutRequest: object = { $type: "idp.LogoutRequest" };

export const LogoutRequest = {
  $type: "idp.LogoutRequest" as const,

  encode(_: LogoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLogoutRequest } as LogoutRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<LogoutRequest>): LogoutRequest {
    const message = { ...baseLogoutRequest } as LogoutRequest;
    return message;
  },
};

messageTypeRegistry.set(LogoutRequest.$type, LogoutRequest);

const baseLogoutResponse: object = { $type: "idp.LogoutResponse" };

export const LogoutResponse = {
  $type: "idp.LogoutResponse" as const,

  encode(_: LogoutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLogoutResponse } as LogoutResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<LogoutResponse>): LogoutResponse {
    const message = { ...baseLogoutResponse } as LogoutResponse;
    return message;
  },
};

messageTypeRegistry.set(LogoutResponse.$type, LogoutResponse);

const baseAuthenticationEvent: object = {
  $type: "idp.AuthenticationEvent",
  mechanism: 0,
  result: 0,
};

export const AuthenticationEvent = {
  $type: "idp.AuthenticationEvent" as const,

  encode(message: AuthenticationEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mechanism !== 0) {
      writer.uint32(8).int32(message.mechanism);
    }
    if (message.result !== 0) {
      writer.uint32(16).uint32(message.result);
    }
    if (message.authenticatorId.length !== 0) {
      writer.uint32(26).bytes(message.authenticatorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthenticationEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthenticationEvent } as AuthenticationEvent;
    message.authenticatorId = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mechanism = reader.int32() as any;
          break;
        case 2:
          message.result = reader.uint32();
          break;
        case 3:
          message.authenticatorId = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthenticationEvent>): AuthenticationEvent {
    const message = { ...baseAuthenticationEvent } as AuthenticationEvent;
    if (object.mechanism !== undefined && object.mechanism !== null) {
      message.mechanism = object.mechanism;
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    if (object.authenticatorId !== undefined && object.authenticatorId !== null) {
      message.authenticatorId = object.authenticatorId;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthenticationEvent.$type, AuthenticationEvent);

const baseOAuthAuthorizeEvent: object = {
  $type: "idp.OAuthAuthorizeEvent",
  clientId: "",
  scopes: "",
  result: 0,
};

export const OAuthAuthorizeEvent = {
  $type: "idp.OAuthAuthorizeEvent" as const,

  encode(message: OAuthAuthorizeEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    for (const v of message.scopes) {
      writer.uint32(18).string(v!);
    }
    if (message.result !== 0) {
      writer.uint32(24).uint32(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OAuthAuthorizeEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOAuthAuthorizeEvent } as OAuthAuthorizeEvent;
    message.scopes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        case 2:
          message.scopes.push(reader.string());
          break;
        case 3:
          message.result = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<OAuthAuthorizeEvent>): OAuthAuthorizeEvent {
    const message = { ...baseOAuthAuthorizeEvent } as OAuthAuthorizeEvent;
    message.scopes = [];
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    if (object.scopes !== undefined && object.scopes !== null) {
      for (const e of object.scopes) {
        message.scopes.push(e);
      }
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    return message;
  },
};

messageTypeRegistry.set(OAuthAuthorizeEvent.$type, OAuthAuthorizeEvent);

const baseSAMLEvent: object = { $type: "idp.SAMLEvent", clientId: "" };

export const SAMLEvent = {
  $type: "idp.SAMLEvent" as const,

  encode(message: SAMLEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SAMLEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSAMLEvent } as SAMLEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SAMLEvent>): SAMLEvent {
    const message = { ...baseSAMLEvent } as SAMLEvent;
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    return message;
  },
};

messageTypeRegistry.set(SAMLEvent.$type, SAMLEvent);

const baseAuditEvent: object = { $type: "idp.AuditEvent", sub: "" };

export const AuditEvent = {
  $type: "idp.AuditEvent" as const,

  encode(message: AuditEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.sessionHash.length !== 0) {
      writer.uint32(18).bytes(message.sessionHash);
    }
    if (message.sub !== "") {
      writer.uint32(26).string(message.sub);
    }
    if (message.event?.$case === "authentication") {
      AuthenticationEvent.encode(message.event.authentication, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuditEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuditEvent } as AuditEvent;
    message.sessionHash = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.sessionHash = reader.bytes();
          break;
        case 3:
          message.sub = reader.string();
          break;
        case 4:
          message.event = {
            $case: "authentication",
            authentication: AuthenticationEvent.decode(reader, reader.uint32()),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuditEvent>): AuditEvent {
    const message = { ...baseAuditEvent } as AuditEvent;
    if (object.timestamp !== undefined && object.timestamp !== null) {
      message.timestamp = object.timestamp;
    }
    if (object.sessionHash !== undefined && object.sessionHash !== null) {
      message.sessionHash = object.sessionHash;
    }
    if (object.sub !== undefined && object.sub !== null) {
      message.sub = object.sub;
    }
    if (
      object.event?.$case === "authentication" &&
      object.event?.authentication !== undefined &&
      object.event?.authentication !== null
    ) {
      message.event = {
        $case: "authentication",
        authentication: AuthenticationEvent.fromPartial(object.event.authentication),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(AuditEvent.$type, AuditEvent);

const baseRemoveTOTPAuthenticatorRequest: object = { $type: "idp.RemoveTOTPAuthenticatorRequest" };

export const RemoveTOTPAuthenticatorRequest = {
  $type: "idp.RemoveTOTPAuthenticatorRequest" as const,

  encode(
    message: RemoveTOTPAuthenticatorRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveTOTPAuthenticatorRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveTOTPAuthenticatorRequest } as RemoveTOTPAuthenticatorRequest;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RemoveTOTPAuthenticatorRequest>): RemoveTOTPAuthenticatorRequest {
    const message = { ...baseRemoveTOTPAuthenticatorRequest } as RemoveTOTPAuthenticatorRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(RemoveTOTPAuthenticatorRequest.$type, RemoveTOTPAuthenticatorRequest);

const baseRemoveTOTPAuthenticatorResponse: object = {
  $type: "idp.RemoveTOTPAuthenticatorResponse",
};

export const RemoveTOTPAuthenticatorResponse = {
  $type: "idp.RemoveTOTPAuthenticatorResponse" as const,

  encode(_: RemoveTOTPAuthenticatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveTOTPAuthenticatorResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveTOTPAuthenticatorResponse } as RemoveTOTPAuthenticatorResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<RemoveTOTPAuthenticatorResponse>): RemoveTOTPAuthenticatorResponse {
    const message = { ...baseRemoveTOTPAuthenticatorResponse } as RemoveTOTPAuthenticatorResponse;
    return message;
  },
};

messageTypeRegistry.set(RemoveTOTPAuthenticatorResponse.$type, RemoveTOTPAuthenticatorResponse);

const baseRemoveWebauthnRequest: object = { $type: "idp.RemoveWebauthnRequest" };

export const RemoveWebauthnRequest = {
  $type: "idp.RemoveWebauthnRequest" as const,

  encode(message: RemoveWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveWebauthnRequest } as RemoveWebauthnRequest;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RemoveWebauthnRequest>): RemoveWebauthnRequest {
    const message = { ...baseRemoveWebauthnRequest } as RemoveWebauthnRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(RemoveWebauthnRequest.$type, RemoveWebauthnRequest);

const baseRemoveWebauthnResponse: object = { $type: "idp.RemoveWebauthnResponse" };

export const RemoveWebauthnResponse = {
  $type: "idp.RemoveWebauthnResponse" as const,

  encode(message: RemoveWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveWebauthnResponse } as RemoveWebauthnResponse;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RemoveWebauthnResponse>): RemoveWebauthnResponse {
    const message = { ...baseRemoveWebauthnResponse } as RemoveWebauthnResponse;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(RemoveWebauthnResponse.$type, RemoveWebauthnResponse);

const baseGetSelfRequest: object = { $type: "idp.GetSelfRequest" };

export const GetSelfRequest = {
  $type: "idp.GetSelfRequest" as const,

  encode(_: GetSelfRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelfRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSelfRequest } as GetSelfRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetSelfRequest>): GetSelfRequest {
    const message = { ...baseGetSelfRequest } as GetSelfRequest;
    return message;
  },
};

messageTypeRegistry.set(GetSelfRequest.$type, GetSelfRequest);

const baseGetSelfResponse: object = { $type: "idp.GetSelfResponse" };

export const GetSelfResponse = {
  $type: "idp.GetSelfResponse" as const,

  encode(message: GetSelfResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelfResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSelfResponse } as GetSelfResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetSelfResponse>): GetSelfResponse {
    const message = { ...baseGetSelfResponse } as GetSelfResponse;
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    }
    return message;
  },
};

messageTypeRegistry.set(GetSelfResponse.$type, GetSelfResponse);

const baseAuthImpersonateRequest: object = { $type: "idp.AuthImpersonateRequest" };

export const AuthImpersonateRequest = {
  $type: "idp.AuthImpersonateRequest" as const,

  encode(_: AuthImpersonateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthImpersonateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthImpersonateRequest } as AuthImpersonateRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AuthImpersonateRequest>): AuthImpersonateRequest {
    const message = { ...baseAuthImpersonateRequest } as AuthImpersonateRequest;
    return message;
  },
};

messageTypeRegistry.set(AuthImpersonateRequest.$type, AuthImpersonateRequest);

const baseAuthImpersonateResponse: object = { $type: "idp.AuthImpersonateResponse" };

export const AuthImpersonateResponse = {
  $type: "idp.AuthImpersonateResponse" as const,

  encode(_: AuthImpersonateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthImpersonateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthImpersonateResponse } as AuthImpersonateResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AuthImpersonateResponse>): AuthImpersonateResponse {
    const message = { ...baseAuthImpersonateResponse } as AuthImpersonateResponse;
    return message;
  },
};

messageTypeRegistry.set(AuthImpersonateResponse.$type, AuthImpersonateResponse);

const baseGetSessionsRequest: object = { $type: "idp.GetSessionsRequest" };

export const GetSessionsRequest = {
  $type: "idp.GetSessionsRequest" as const,

  encode(_: GetSessionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSessionsRequest } as GetSessionsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetSessionsRequest>): GetSessionsRequest {
    const message = { ...baseGetSessionsRequest } as GetSessionsRequest;
    return message;
  },
};

messageTypeRegistry.set(GetSessionsRequest.$type, GetSessionsRequest);

const baseGetSessionsResponse: object = {
  $type: "idp.GetSessionsResponse",
  selectedUserSession: 0,
};

export const GetSessionsResponse = {
  $type: "idp.GetSessionsResponse" as const,

  encode(message: GetSessionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.session) {
      UserProfile.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.selectedUserSession !== 0) {
      writer.uint32(16).int32(message.selectedUserSession);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSessionsResponse } as GetSessionsResponse;
    message.session = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.session.push(UserProfile.decode(reader, reader.uint32()));
          break;
        case 2:
          message.selectedUserSession = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetSessionsResponse>): GetSessionsResponse {
    const message = { ...baseGetSessionsResponse } as GetSessionsResponse;
    message.session = [];
    if (object.session !== undefined && object.session !== null) {
      for (const e of object.session) {
        message.session.push(UserProfile.fromPartial(e));
      }
    }
    if (object.selectedUserSession !== undefined && object.selectedUserSession !== null) {
      message.selectedUserSession = object.selectedUserSession;
    }
    return message;
  },
};

messageTypeRegistry.set(GetSessionsResponse.$type, GetSessionsResponse);

const baseGetBrandingRequest: object = { $type: "idp.GetBrandingRequest" };

export const GetBrandingRequest = {
  $type: "idp.GetBrandingRequest" as const,

  encode(_: GetBrandingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBrandingRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetBrandingRequest } as GetBrandingRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetBrandingRequest>): GetBrandingRequest {
    const message = { ...baseGetBrandingRequest } as GetBrandingRequest;
    return message;
  },
};

messageTypeRegistry.set(GetBrandingRequest.$type, GetBrandingRequest);

const baseVerifyMailRequest: object = { $type: "idp.VerifyMailRequest", token: "" };

export const VerifyMailRequest = {
  $type: "idp.VerifyMailRequest" as const,

  encode(message: VerifyMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyMailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseVerifyMailRequest } as VerifyMailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<VerifyMailRequest>): VerifyMailRequest {
    const message = { ...baseVerifyMailRequest } as VerifyMailRequest;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    }
    return message;
  },
};

messageTypeRegistry.set(VerifyMailRequest.$type, VerifyMailRequest);

const baseVerifyMailResponse: object = { $type: "idp.VerifyMailResponse" };

export const VerifyMailResponse = {
  $type: "idp.VerifyMailResponse" as const,

  encode(_: VerifyMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyMailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseVerifyMailResponse } as VerifyMailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<VerifyMailResponse>): VerifyMailResponse {
    const message = { ...baseVerifyMailResponse } as VerifyMailResponse;
    return message;
  },
};

messageTypeRegistry.set(VerifyMailResponse.$type, VerifyMailResponse);

const baseSendVerifyMailRequest: object = { $type: "idp.SendVerifyMailRequest", email: "" };

export const SendVerifyMailRequest = {
  $type: "idp.SendVerifyMailRequest" as const,

  encode(message: SendVerifyMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendVerifyMailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSendVerifyMailRequest } as SendVerifyMailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SendVerifyMailRequest>): SendVerifyMailRequest {
    const message = { ...baseSendVerifyMailRequest } as SendVerifyMailRequest;
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    }
    return message;
  },
};

messageTypeRegistry.set(SendVerifyMailRequest.$type, SendVerifyMailRequest);

const baseSendVerifyMailResponse: object = { $type: "idp.SendVerifyMailResponse" };

export const SendVerifyMailResponse = {
  $type: "idp.SendVerifyMailResponse" as const,

  encode(_: SendVerifyMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendVerifyMailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSendVerifyMailResponse } as SendVerifyMailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SendVerifyMailResponse>): SendVerifyMailResponse {
    const message = { ...baseSendVerifyMailResponse } as SendVerifyMailResponse;
    return message;
  },
};

messageTypeRegistry.set(SendVerifyMailResponse.$type, SendVerifyMailResponse);

const baseRemoveMailRequest: object = { $type: "idp.RemoveMailRequest" };

export const RemoveMailRequest = {
  $type: "idp.RemoveMailRequest" as const,

  encode(message: RemoveMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveMailRequest } as RemoveMailRequest;
    message.id = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RemoveMailRequest>): RemoveMailRequest {
    const message = { ...baseRemoveMailRequest } as RemoveMailRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    return message;
  },
};

messageTypeRegistry.set(RemoveMailRequest.$type, RemoveMailRequest);

const baseRemoveMailResponse: object = { $type: "idp.RemoveMailResponse" };

export const RemoveMailResponse = {
  $type: "idp.RemoveMailResponse" as const,

  encode(_: RemoveMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveMailResponse } as RemoveMailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<RemoveMailResponse>): RemoveMailResponse {
    const message = { ...baseRemoveMailResponse } as RemoveMailResponse;
    return message;
  },
};

messageTypeRegistry.set(RemoveMailResponse.$type, RemoveMailResponse);

const baseGetUserRequest: object = {
  $type: "idp.GetUserRequest",
  id: "",
  email: "",
  legacyUsername: "",
};

export const GetUserRequest = {
  $type: "idp.GetUserRequest" as const,

  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.legacyUsername !== "") {
      writer.uint32(26).string(message.legacyUsername);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserRequest } as GetUserRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.email = reader.string();
          break;
        case 3:
          message.legacyUsername = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetUserRequest>): GetUserRequest {
    const message = { ...baseGetUserRequest } as GetUserRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    }
    if (object.legacyUsername !== undefined && object.legacyUsername !== null) {
      message.legacyUsername = object.legacyUsername;
    }
    return message;
  },
};

messageTypeRegistry.set(GetUserRequest.$type, GetUserRequest);

const baseGetUserResponse: object = { $type: "idp.GetUserResponse" };

export const GetUserResponse = {
  $type: "idp.GetUserResponse" as const,

  encode(message: GetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserResponse } as GetUserResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetUserResponse>): GetUserResponse {
    const message = { ...baseGetUserResponse } as GetUserResponse;
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    }
    return message;
  },
};

messageTypeRegistry.set(GetUserResponse.$type, GetUserResponse);

const baseGetSessionsForUserRequest: object = {
  $type: "idp.GetSessionsForUserRequest",
  userId: "",
};

export const GetSessionsForUserRequest = {
  $type: "idp.GetSessionsForUserRequest" as const,

  encode(message: GetSessionsForUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsForUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSessionsForUserRequest } as GetSessionsForUserRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetSessionsForUserRequest>): GetSessionsForUserRequest {
    const message = { ...baseGetSessionsForUserRequest } as GetSessionsForUserRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    return message;
  },
};

messageTypeRegistry.set(GetSessionsForUserRequest.$type, GetSessionsForUserRequest);

const baseUserAgent: object = {
  $type: "idp.UserAgent",
  brand: "",
  platformBrand: "",
  deviceModel: "",
  mobile: false,
};

export const UserAgent = {
  $type: "idp.UserAgent" as const,

  encode(message: UserAgent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== "") {
      writer.uint32(10).string(message.brand);
    }
    if (message.platformBrand !== "") {
      writer.uint32(18).string(message.platformBrand);
    }
    if (message.deviceModel !== "") {
      writer.uint32(26).string(message.deviceModel);
    }
    if (message.mobile === true) {
      writer.uint32(32).bool(message.mobile);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAgent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserAgent } as UserAgent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brand = reader.string();
          break;
        case 2:
          message.platformBrand = reader.string();
          break;
        case 3:
          message.deviceModel = reader.string();
          break;
        case 4:
          message.mobile = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<UserAgent>): UserAgent {
    const message = { ...baseUserAgent } as UserAgent;
    if (object.brand !== undefined && object.brand !== null) {
      message.brand = object.brand;
    }
    if (object.platformBrand !== undefined && object.platformBrand !== null) {
      message.platformBrand = object.platformBrand;
    }
    if (object.deviceModel !== undefined && object.deviceModel !== null) {
      message.deviceModel = object.deviceModel;
    }
    if (object.mobile !== undefined && object.mobile !== null) {
      message.mobile = object.mobile;
    }
    return message;
  },
};

messageTypeRegistry.set(UserAgent.$type, UserAgent);

const baseGetSessionsForUserResponse: object = { $type: "idp.GetSessionsForUserResponse" };

export const GetSessionsForUserResponse = {
  $type: "idp.GetSessionsForUserResponse" as const,

  encode(_: GetSessionsForUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsForUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSessionsForUserResponse } as GetSessionsForUserResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetSessionsForUserResponse>): GetSessionsForUserResponse {
    const message = { ...baseGetSessionsForUserResponse } as GetSessionsForUserResponse;
    return message;
  },
};

messageTypeRegistry.set(GetSessionsForUserResponse.$type, GetSessionsForUserResponse);

const baseResetRiskRequest: object = { $type: "idp.ResetRiskRequest" };

export const ResetRiskRequest = {
  $type: "idp.ResetRiskRequest" as const,

  encode(_: ResetRiskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetRiskRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseResetRiskRequest } as ResetRiskRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ResetRiskRequest>): ResetRiskRequest {
    const message = { ...baseResetRiskRequest } as ResetRiskRequest;
    return message;
  },
};

messageTypeRegistry.set(ResetRiskRequest.$type, ResetRiskRequest);

const baseResetRiskResponse: object = { $type: "idp.ResetRiskResponse" };

export const ResetRiskResponse = {
  $type: "idp.ResetRiskResponse" as const,

  encode(_: ResetRiskResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetRiskResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseResetRiskResponse } as ResetRiskResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ResetRiskResponse>): ResetRiskResponse {
    const message = { ...baseResetRiskResponse } as ResetRiskResponse;
    return message;
  },
};

messageTypeRegistry.set(ResetRiskResponse.$type, ResetRiskResponse);

const baseDeleteUserRequest: object = { $type: "idp.DeleteUserRequest", userId: "" };

export const DeleteUserRequest = {
  $type: "idp.DeleteUserRequest" as const,

  encode(message: DeleteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteUserRequest } as DeleteUserRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<DeleteUserRequest>): DeleteUserRequest {
    const message = { ...baseDeleteUserRequest } as DeleteUserRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    return message;
  },
};

messageTypeRegistry.set(DeleteUserRequest.$type, DeleteUserRequest);

const baseDeleteUserResponse: object = { $type: "idp.DeleteUserResponse" };

export const DeleteUserResponse = {
  $type: "idp.DeleteUserResponse" as const,

  encode(_: DeleteUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteUserResponse } as DeleteUserResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<DeleteUserResponse>): DeleteUserResponse {
    const message = { ...baseDeleteUserResponse } as DeleteUserResponse;
    return message;
  },
};

messageTypeRegistry.set(DeleteUserResponse.$type, DeleteUserResponse);

const baseCreateUserRequest: object = { $type: "idp.CreateUserRequest", newPassword: "" };

export const CreateUserRequest = {
  $type: "idp.CreateUserRequest" as const,

  encode(message: CreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateUserRequest } as CreateUserRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        case 2:
          message.newPassword = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<CreateUserRequest>): CreateUserRequest {
    const message = { ...baseCreateUserRequest } as CreateUserRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    }
    if (object.newPassword !== undefined && object.newPassword !== null) {
      message.newPassword = object.newPassword;
    }
    return message;
  },
};

messageTypeRegistry.set(CreateUserRequest.$type, CreateUserRequest);

const baseCreateUserResponse: object = { $type: "idp.CreateUserResponse" };

export const CreateUserResponse = {
  $type: "idp.CreateUserResponse" as const,

  encode(_: CreateUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateUserResponse } as CreateUserResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<CreateUserResponse>): CreateUserResponse {
    const message = { ...baseCreateUserResponse } as CreateUserResponse;
    return message;
  },
};

messageTypeRegistry.set(CreateUserResponse.$type, CreateUserResponse);

const baseNewUserPasswordRequest: object = {
  $type: "idp.NewUserPasswordRequest",
  userId: "",
  generatedPasswordFamily: 0,
  forceChange: false,
};

export const NewUserPasswordRequest = {
  $type: "idp.NewUserPasswordRequest" as const,

  encode(message: NewUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.generatedPasswordFamily !== 0) {
      writer.uint32(16).int32(message.generatedPasswordFamily);
    }
    if (message.forceChange === true) {
      writer.uint32(24).bool(message.forceChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewUserPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNewUserPasswordRequest } as NewUserPasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.generatedPasswordFamily = reader.int32() as any;
          break;
        case 3:
          message.forceChange = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<NewUserPasswordRequest>): NewUserPasswordRequest {
    const message = { ...baseNewUserPasswordRequest } as NewUserPasswordRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.generatedPasswordFamily !== undefined && object.generatedPasswordFamily !== null) {
      message.generatedPasswordFamily = object.generatedPasswordFamily;
    }
    if (object.forceChange !== undefined && object.forceChange !== null) {
      message.forceChange = object.forceChange;
    }
    return message;
  },
};

messageTypeRegistry.set(NewUserPasswordRequest.$type, NewUserPasswordRequest);

const baseNewUserPasswordResponse: object = {
  $type: "idp.NewUserPasswordResponse",
  newPassword: "",
};

export const NewUserPasswordResponse = {
  $type: "idp.NewUserPasswordResponse" as const,

  encode(message: NewUserPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newPassword !== "") {
      writer.uint32(10).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewUserPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNewUserPasswordResponse } as NewUserPasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.newPassword = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<NewUserPasswordResponse>): NewUserPasswordResponse {
    const message = { ...baseNewUserPasswordResponse } as NewUserPasswordResponse;
    if (object.newPassword !== undefined && object.newPassword !== null) {
      message.newPassword = object.newPassword;
    }
    return message;
  },
};

messageTypeRegistry.set(NewUserPasswordResponse.$type, NewUserPasswordResponse);

const baseListUsersRequest: object = { $type: "idp.ListUsersRequest", pageSize: 0, pageToken: "" };

export const ListUsersRequest = {
  $type: "idp.ListUsersRequest" as const,

  encode(message: ListUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListUsersRequest } as ListUsersRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.pageSize = reader.int32();
          break;
        case 2:
          message.pageToken = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListUsersRequest>): ListUsersRequest {
    const message = { ...baseListUsersRequest } as ListUsersRequest;
    if (object.pageSize !== undefined && object.pageSize !== null) {
      message.pageSize = object.pageSize;
    }
    if (object.pageToken !== undefined && object.pageToken !== null) {
      message.pageToken = object.pageToken;
    }
    return message;
  },
};

messageTypeRegistry.set(ListUsersRequest.$type, ListUsersRequest);

const baseListUsersResponse: object = { $type: "idp.ListUsersResponse", nextPageToken: "" };

export const ListUsersResponse = {
  $type: "idp.ListUsersResponse" as const,

  encode(message: ListUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.user) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListUsersResponse } as ListUsersResponse;
    message.user = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user.push(User.decode(reader, reader.uint32()));
          break;
        case 2:
          message.nextPageToken = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListUsersResponse>): ListUsersResponse {
    const message = { ...baseListUsersResponse } as ListUsersResponse;
    message.user = [];
    if (object.user !== undefined && object.user !== null) {
      for (const e of object.user) {
        message.user.push(User.fromPartial(e));
      }
    }
    if (object.nextPageToken !== undefined && object.nextPageToken !== null) {
      message.nextPageToken = object.nextPageToken;
    }
    return message;
  },
};

messageTypeRegistry.set(ListUsersResponse.$type, ListUsersResponse);

const baseAddUserClaimRequest: object = {
  $type: "idp.AddUserClaimRequest",
  userId: "",
  claimName: "",
};

export const AddUserClaimRequest = {
  $type: "idp.AddUserClaimRequest" as const,

  encode(message: AddUserClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.claimName !== "") {
      writer.uint32(18).string(message.claimName);
    }
    if (message.claimValue !== undefined) {
      Value.encode(message.claimValue, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserClaimRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddUserClaimRequest } as AddUserClaimRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.claimName = reader.string();
          break;
        case 3:
          message.claimValue = Value.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AddUserClaimRequest>): AddUserClaimRequest {
    const message = { ...baseAddUserClaimRequest } as AddUserClaimRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.claimName !== undefined && object.claimName !== null) {
      message.claimName = object.claimName;
    }
    if (object.claimValue !== undefined && object.claimValue !== null) {
      message.claimValue = Value.fromPartial(object.claimValue);
    }
    return message;
  },
};

messageTypeRegistry.set(AddUserClaimRequest.$type, AddUserClaimRequest);

const baseAddUserClaimResponse: object = { $type: "idp.AddUserClaimResponse" };

export const AddUserClaimResponse = {
  $type: "idp.AddUserClaimResponse" as const,

  encode(_: AddUserClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserClaimResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddUserClaimResponse } as AddUserClaimResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AddUserClaimResponse>): AddUserClaimResponse {
    const message = { ...baseAddUserClaimResponse } as AddUserClaimResponse;
    return message;
  },
};

messageTypeRegistry.set(AddUserClaimResponse.$type, AddUserClaimResponse);

const baseDeleteUserClaimRequest: object = {
  $type: "idp.DeleteUserClaimRequest",
  userId: "",
  claimName: "",
};

export const DeleteUserClaimRequest = {
  $type: "idp.DeleteUserClaimRequest" as const,

  encode(message: DeleteUserClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.claimName !== "") {
      writer.uint32(18).string(message.claimName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserClaimRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteUserClaimRequest } as DeleteUserClaimRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.claimName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<DeleteUserClaimRequest>): DeleteUserClaimRequest {
    const message = { ...baseDeleteUserClaimRequest } as DeleteUserClaimRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.claimName !== undefined && object.claimName !== null) {
      message.claimName = object.claimName;
    }
    return message;
  },
};

messageTypeRegistry.set(DeleteUserClaimRequest.$type, DeleteUserClaimRequest);

const baseDeleteUserClaimResponse: object = { $type: "idp.DeleteUserClaimResponse" };

export const DeleteUserClaimResponse = {
  $type: "idp.DeleteUserClaimResponse" as const,

  encode(_: DeleteUserClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserClaimResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteUserClaimResponse } as DeleteUserClaimResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<DeleteUserClaimResponse>): DeleteUserClaimResponse {
    const message = { ...baseDeleteUserClaimResponse } as DeleteUserClaimResponse;
    return message;
  },
};

messageTypeRegistry.set(DeleteUserClaimResponse.$type, DeleteUserClaimResponse);

const baseAddUserToGroupRequest: object = {
  $type: "idp.AddUserToGroupRequest",
  userId: "",
  groupName: "",
};

export const AddUserToGroupRequest = {
  $type: "idp.AddUserToGroupRequest" as const,

  encode(message: AddUserToGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.groupName !== "") {
      writer.uint32(18).string(message.groupName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserToGroupRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddUserToGroupRequest } as AddUserToGroupRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.groupName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AddUserToGroupRequest>): AddUserToGroupRequest {
    const message = { ...baseAddUserToGroupRequest } as AddUserToGroupRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.groupName !== undefined && object.groupName !== null) {
      message.groupName = object.groupName;
    }
    return message;
  },
};

messageTypeRegistry.set(AddUserToGroupRequest.$type, AddUserToGroupRequest);

const baseAddUserToGroupResponse: object = { $type: "idp.AddUserToGroupResponse" };

export const AddUserToGroupResponse = {
  $type: "idp.AddUserToGroupResponse" as const,

  encode(_: AddUserToGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserToGroupResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddUserToGroupResponse } as AddUserToGroupResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<AddUserToGroupResponse>): AddUserToGroupResponse {
    const message = { ...baseAddUserToGroupResponse } as AddUserToGroupResponse;
    return message;
  },
};

messageTypeRegistry.set(AddUserToGroupResponse.$type, AddUserToGroupResponse);

const baseSyncFullRequest: object = { $type: "idp.SyncFullRequest" };

export const SyncFullRequest = {
  $type: "idp.SyncFullRequest" as const,

  encode(message: SyncFullRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.user) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncFullRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSyncFullRequest } as SyncFullRequest;
    message.user = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user.push(User.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SyncFullRequest>): SyncFullRequest {
    const message = { ...baseSyncFullRequest } as SyncFullRequest;
    message.user = [];
    if (object.user !== undefined && object.user !== null) {
      for (const e of object.user) {
        message.user.push(User.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(SyncFullRequest.$type, SyncFullRequest);

const baseSyncFullResponse: object = { $type: "idp.SyncFullResponse" };

export const SyncFullResponse = {
  $type: "idp.SyncFullResponse" as const,

  encode(_: SyncFullResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncFullResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSyncFullResponse } as SyncFullResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SyncFullResponse>): SyncFullResponse {
    const message = { ...baseSyncFullResponse } as SyncFullResponse;
    return message;
  },
};

messageTypeRegistry.set(SyncFullResponse.$type, SyncFullResponse);

const basePasswordStrengthPolicy: object = {
  $type: "idp.PasswordStrengthPolicy",
  tooSimpleUpperBound: 0,
  acceptableUpperBound: 0,
  goodUpperBound: 0,
};

export const PasswordStrengthPolicy = {
  $type: "idp.PasswordStrengthPolicy" as const,

  encode(message: PasswordStrengthPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tooSimpleUpperBound !== 0) {
      writer.uint32(24).uint32(message.tooSimpleUpperBound);
    }
    if (message.acceptableUpperBound !== 0) {
      writer.uint32(32).uint32(message.acceptableUpperBound);
    }
    if (message.goodUpperBound !== 0) {
      writer.uint32(40).uint32(message.goodUpperBound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthPolicy {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePasswordStrengthPolicy } as PasswordStrengthPolicy;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.tooSimpleUpperBound = reader.uint32();
          break;
        case 4:
          message.acceptableUpperBound = reader.uint32();
          break;
        case 5:
          message.goodUpperBound = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<PasswordStrengthPolicy>): PasswordStrengthPolicy {
    const message = { ...basePasswordStrengthPolicy } as PasswordStrengthPolicy;
    if (object.tooSimpleUpperBound !== undefined && object.tooSimpleUpperBound !== null) {
      message.tooSimpleUpperBound = object.tooSimpleUpperBound;
    }
    if (object.acceptableUpperBound !== undefined && object.acceptableUpperBound !== null) {
      message.acceptableUpperBound = object.acceptableUpperBound;
    }
    if (object.goodUpperBound !== undefined && object.goodUpperBound !== null) {
      message.goodUpperBound = object.goodUpperBound;
    }
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthPolicy.$type, PasswordStrengthPolicy);

const basePasswordStrengthRequest: object = {
  $type: "idp.PasswordStrengthRequest",
  userId: "",
  password: "",
};

export const PasswordStrengthRequest = {
  $type: "idp.PasswordStrengthRequest" as const,

  encode(message: PasswordStrengthRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.password !== "") {
      writer.uint32(10).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePasswordStrengthRequest } as PasswordStrengthRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.userId = reader.string();
          break;
        case 1:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<PasswordStrengthRequest>): PasswordStrengthRequest {
    const message = { ...basePasswordStrengthRequest } as PasswordStrengthRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    }
    if (object.password !== undefined && object.password !== null) {
      message.password = object.password;
    }
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthRequest.$type, PasswordStrengthRequest);

const basePasswordStrengthResponse: object = {
  $type: "idp.PasswordStrengthResponse",
  breached: false,
  strengthBits: 0,
  acceptable: false,
};

export const PasswordStrengthResponse = {
  $type: "idp.PasswordStrengthResponse" as const,

  encode(message: PasswordStrengthResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.breached === true) {
      writer.uint32(8).bool(message.breached);
    }
    if (message.strengthBits !== 0) {
      writer.uint32(17).double(message.strengthBits);
    }
    if (message.acceptable === true) {
      writer.uint32(24).bool(message.acceptable);
    }
    if (message.policy !== undefined) {
      PasswordStrengthPolicy.encode(message.policy, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePasswordStrengthResponse } as PasswordStrengthResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.breached = reader.bool();
          break;
        case 2:
          message.strengthBits = reader.double();
          break;
        case 3:
          message.acceptable = reader.bool();
          break;
        case 4:
          message.policy = PasswordStrengthPolicy.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<PasswordStrengthResponse>): PasswordStrengthResponse {
    const message = { ...basePasswordStrengthResponse } as PasswordStrengthResponse;
    if (object.breached !== undefined && object.breached !== null) {
      message.breached = object.breached;
    }
    if (object.strengthBits !== undefined && object.strengthBits !== null) {
      message.strengthBits = object.strengthBits;
    }
    if (object.acceptable !== undefined && object.acceptable !== null) {
      message.acceptable = object.acceptable;
    }
    if (object.policy !== undefined && object.policy !== null) {
      message.policy = PasswordStrengthPolicy.fromPartial(object.policy);
    }
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthResponse.$type, PasswordStrengthResponse);

const baseGeneratePasswordRequest: object = {
  $type: "idp.GeneratePasswordRequest",
  count: 0,
  family: 0,
};

export const GeneratePasswordRequest = {
  $type: "idp.GeneratePasswordRequest" as const,

  encode(message: GeneratePasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    if (message.family !== 0) {
      writer.uint32(16).int32(message.family);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePasswordRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGeneratePasswordRequest } as GeneratePasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.count = reader.uint32();
          break;
        case 2:
          message.family = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GeneratePasswordRequest>): GeneratePasswordRequest {
    const message = { ...baseGeneratePasswordRequest } as GeneratePasswordRequest;
    if (object.count !== undefined && object.count !== null) {
      message.count = object.count;
    }
    if (object.family !== undefined && object.family !== null) {
      message.family = object.family;
    }
    return message;
  },
};

messageTypeRegistry.set(GeneratePasswordRequest.$type, GeneratePasswordRequest);

const baseGeneratePasswordResponse: object = {
  $type: "idp.GeneratePasswordResponse",
  password: "",
};

export const GeneratePasswordResponse = {
  $type: "idp.GeneratePasswordResponse" as const,

  encode(message: GeneratePasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.password) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePasswordResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGeneratePasswordResponse } as GeneratePasswordResponse;
    message.password = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.password.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GeneratePasswordResponse>): GeneratePasswordResponse {
    const message = { ...baseGeneratePasswordResponse } as GeneratePasswordResponse;
    message.password = [];
    if (object.password !== undefined && object.password !== null) {
      for (const e of object.password) {
        message.password.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GeneratePasswordResponse.$type, GeneratePasswordResponse);

const baseFrontendConfig: object = { $type: "idp.FrontendConfig", identifier: "" };

export const FrontendConfig = {
  $type: "idp.FrontendConfig" as const,

  encode(message: FrontendConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FrontendConfig {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFrontendConfig } as FrontendConfig;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<FrontendConfig>): FrontendConfig {
    const message = { ...baseFrontendConfig } as FrontendConfig;
    if (object.identifier !== undefined && object.identifier !== null) {
      message.identifier = object.identifier;
    }
    return message;
  },
};

messageTypeRegistry.set(FrontendConfig.$type, FrontendConfig);

/**
 * TOOD: Split the IdentityProvider service into an Authentication (Auth*) and a
 * UserManagement service (Add/Remove/Change)
 */
export interface IdentityProvider {
  AuthStart(
    request: DeepPartial<AuthStartRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthStartResponse>;
  AuthUsername(
    request: DeepPartial<AuthUsernameRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthUsernameResponse>;
  AuthPassword(
    request: DeepPartial<AuthPasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthPasswordResponse>;
  ChangePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<ChangePasswordResponse>;
  PasswordStrength(
    request: DeepPartial<PasswordStrengthRequest>,
    metadata?: grpc.Metadata
  ): Promise<PasswordStrengthResponse>;
  GeneratePassword(
    request: DeepPartial<GeneratePasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<GeneratePasswordResponse>;
  AuthTOTP(
    request: DeepPartial<AuthTOTPRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthTOTPResponse>;
  AddTOTPAuthenticator(
    request: DeepPartial<AddTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddTOTPAuthenticatorResponse>;
  RemoveTOTPAuthenticator(
    request: DeepPartial<RemoveTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveTOTPAuthenticatorResponse>;
  AddWebauthn(
    request: DeepPartial<AddWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddWebauthnResponse>;
  AuthWebauthn(
    request: DeepPartial<AuthWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthWebauthnResponse>;
  RemoveWebauthn(
    request: DeepPartial<RemoveWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveWebauthnResponse>;
  SendAuthMail(
    request: DeepPartial<SendAuthMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<SendAuthMailResponse>;
  AuthBackchannel(
    request: DeepPartial<AuthBackchannelRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthBackchannelResponse>;
  RemoveMail(
    request: DeepPartial<RemoveMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveMailResponse>;
  VerifyMail(
    request: DeepPartial<VerifyMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<VerifyMailResponse>;
  SendVerifyMail(
    request: DeepPartial<SendVerifyMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<SendVerifyMailResponse>;
  AuthImpersonate(
    request: DeepPartial<AuthImpersonateRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthImpersonateResponse>;
  /**
   * GetUser returns the requested user object. Leaving the id field empty
   * returns the currently authenticated user. Getting data for a user other
   * than the currently authenticated one requires the idp.internal/pwinterop or
   * idp.internal/admin scopes.
   */
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse>;
  /** GetSelf is deprecated. Use GetUser with an empty id instead. */
  GetSelf(request: DeepPartial<GetSelfRequest>, metadata?: grpc.Metadata): Promise<GetSelfResponse>;
  GetSessions(
    request: DeepPartial<GetSessionsRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetSessionsResponse>;
  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse>;
  GetBranding(
    request: DeepPartial<GetBrandingRequest>,
    metadata?: grpc.Metadata
  ): Promise<Branding>;
}

export class IdentityProviderClientImpl implements IdentityProvider {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  AuthStart(
    request: DeepPartial<AuthStartRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthStartResponse> {
    return this.rpc.unary(
      IdentityProviderAuthStartDesc,
      AuthStartRequest.fromPartial(request),
      metadata
    );
  }

  AuthUsername(
    request: DeepPartial<AuthUsernameRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthUsernameResponse> {
    return this.rpc.unary(
      IdentityProviderAuthUsernameDesc,
      AuthUsernameRequest.fromPartial(request),
      metadata
    );
  }

  AuthPassword(
    request: DeepPartial<AuthPasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthPasswordResponse> {
    return this.rpc.unary(
      IdentityProviderAuthPasswordDesc,
      AuthPasswordRequest.fromPartial(request),
      metadata
    );
  }

  ChangePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<ChangePasswordResponse> {
    return this.rpc.unary(
      IdentityProviderChangePasswordDesc,
      ChangePasswordRequest.fromPartial(request),
      metadata
    );
  }

  PasswordStrength(
    request: DeepPartial<PasswordStrengthRequest>,
    metadata?: grpc.Metadata
  ): Promise<PasswordStrengthResponse> {
    return this.rpc.unary(
      IdentityProviderPasswordStrengthDesc,
      PasswordStrengthRequest.fromPartial(request),
      metadata
    );
  }

  GeneratePassword(
    request: DeepPartial<GeneratePasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<GeneratePasswordResponse> {
    return this.rpc.unary(
      IdentityProviderGeneratePasswordDesc,
      GeneratePasswordRequest.fromPartial(request),
      metadata
    );
  }

  AuthTOTP(
    request: DeepPartial<AuthTOTPRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthTOTPResponse> {
    return this.rpc.unary(
      IdentityProviderAuthTOTPDesc,
      AuthTOTPRequest.fromPartial(request),
      metadata
    );
  }

  AddTOTPAuthenticator(
    request: DeepPartial<AddTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddTOTPAuthenticatorResponse> {
    return this.rpc.unary(
      IdentityProviderAddTOTPAuthenticatorDesc,
      AddTOTPAuthenticatorRequest.fromPartial(request),
      metadata
    );
  }

  RemoveTOTPAuthenticator(
    request: DeepPartial<RemoveTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveTOTPAuthenticatorResponse> {
    return this.rpc.unary(
      IdentityProviderRemoveTOTPAuthenticatorDesc,
      RemoveTOTPAuthenticatorRequest.fromPartial(request),
      metadata
    );
  }

  AddWebauthn(
    request: DeepPartial<AddWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddWebauthnResponse> {
    return this.rpc.unary(
      IdentityProviderAddWebauthnDesc,
      AddWebauthnRequest.fromPartial(request),
      metadata
    );
  }

  AuthWebauthn(
    request: DeepPartial<AuthWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthWebauthnResponse> {
    return this.rpc.unary(
      IdentityProviderAuthWebauthnDesc,
      AuthWebauthnRequest.fromPartial(request),
      metadata
    );
  }

  RemoveWebauthn(
    request: DeepPartial<RemoveWebauthnRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveWebauthnResponse> {
    return this.rpc.unary(
      IdentityProviderRemoveWebauthnDesc,
      RemoveWebauthnRequest.fromPartial(request),
      metadata
    );
  }

  SendAuthMail(
    request: DeepPartial<SendAuthMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<SendAuthMailResponse> {
    return this.rpc.unary(
      IdentityProviderSendAuthMailDesc,
      SendAuthMailRequest.fromPartial(request),
      metadata
    );
  }

  AuthBackchannel(
    request: DeepPartial<AuthBackchannelRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthBackchannelResponse> {
    return this.rpc.unary(
      IdentityProviderAuthBackchannelDesc,
      AuthBackchannelRequest.fromPartial(request),
      metadata
    );
  }

  RemoveMail(
    request: DeepPartial<RemoveMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<RemoveMailResponse> {
    return this.rpc.unary(
      IdentityProviderRemoveMailDesc,
      RemoveMailRequest.fromPartial(request),
      metadata
    );
  }

  VerifyMail(
    request: DeepPartial<VerifyMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<VerifyMailResponse> {
    return this.rpc.unary(
      IdentityProviderVerifyMailDesc,
      VerifyMailRequest.fromPartial(request),
      metadata
    );
  }

  SendVerifyMail(
    request: DeepPartial<SendVerifyMailRequest>,
    metadata?: grpc.Metadata
  ): Promise<SendVerifyMailResponse> {
    return this.rpc.unary(
      IdentityProviderSendVerifyMailDesc,
      SendVerifyMailRequest.fromPartial(request),
      metadata
    );
  }

  AuthImpersonate(
    request: DeepPartial<AuthImpersonateRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthImpersonateResponse> {
    return this.rpc.unary(
      IdentityProviderAuthImpersonateDesc,
      AuthImpersonateRequest.fromPartial(request),
      metadata
    );
  }

  GetUser(
    request: DeepPartial<GetUserRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetUserResponse> {
    return this.rpc.unary(
      IdentityProviderGetUserDesc,
      GetUserRequest.fromPartial(request),
      metadata
    );
  }

  GetSelf(
    request: DeepPartial<GetSelfRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetSelfResponse> {
    return this.rpc.unary(
      IdentityProviderGetSelfDesc,
      GetSelfRequest.fromPartial(request),
      metadata
    );
  }

  GetSessions(
    request: DeepPartial<GetSessionsRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetSessionsResponse> {
    return this.rpc.unary(
      IdentityProviderGetSessionsDesc,
      GetSessionsRequest.fromPartial(request),
      metadata
    );
  }

  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse> {
    return this.rpc.unary(IdentityProviderLogoutDesc, LogoutRequest.fromPartial(request), metadata);
  }

  GetBranding(
    request: DeepPartial<GetBrandingRequest>,
    metadata?: grpc.Metadata
  ): Promise<Branding> {
    return this.rpc.unary(
      IdentityProviderGetBrandingDesc,
      GetBrandingRequest.fromPartial(request),
      metadata
    );
  }
}

export const IdentityProviderDesc = {
  serviceName: "idp.IdentityProvider",
};

export const IdentityProviderAuthStartDesc: UnaryMethodDefinitionish = {
  methodName: "AuthStart",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthStartRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthStartResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthUsernameDesc: UnaryMethodDefinitionish = {
  methodName: "AuthUsername",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthUsernameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthUsernameResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "AuthPassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthPasswordResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderChangePasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ChangePassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChangePasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ChangePasswordResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderPasswordStrengthDesc: UnaryMethodDefinitionish = {
  methodName: "PasswordStrength",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PasswordStrengthRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...PasswordStrengthResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderGeneratePasswordDesc: UnaryMethodDefinitionish = {
  methodName: "GeneratePassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneratePasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GeneratePasswordResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthTOTPDesc: UnaryMethodDefinitionish = {
  methodName: "AuthTOTP",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthTOTPRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthTOTPResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAddTOTPAuthenticatorDesc: UnaryMethodDefinitionish = {
  methodName: "AddTOTPAuthenticator",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddTOTPAuthenticatorRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddTOTPAuthenticatorResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveTOTPAuthenticatorDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveTOTPAuthenticator",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveTOTPAuthenticatorRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveTOTPAuthenticatorResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAddWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "AddWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddWebauthnResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "AuthWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthWebauthnResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveWebauthnResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderSendAuthMailDesc: UnaryMethodDefinitionish = {
  methodName: "SendAuthMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SendAuthMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SendAuthMailResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthBackchannelDesc: UnaryMethodDefinitionish = {
  methodName: "AuthBackchannel",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthBackchannelRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthBackchannelResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveMailDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveMailResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderVerifyMailDesc: UnaryMethodDefinitionish = {
  methodName: "VerifyMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return VerifyMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...VerifyMailResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderSendVerifyMailDesc: UnaryMethodDefinitionish = {
  methodName: "SendVerifyMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SendVerifyMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SendVerifyMailResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthImpersonateDesc: UnaryMethodDefinitionish = {
  methodName: "AuthImpersonate",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthImpersonateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthImpersonateResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetUserResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetSelfDesc: UnaryMethodDefinitionish = {
  methodName: "GetSelf",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSelfRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetSelfResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetSessionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetSessions",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSessionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetSessionsResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderLogoutDesc: UnaryMethodDefinitionish = {
  methodName: "Logout",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...LogoutResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetBrandingDesc: UnaryMethodDefinitionish = {
  methodName: "GetBranding",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBrandingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Branding.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface IdentityProviderAdmin {
  ResetRisk(
    request: DeepPartial<ResetRiskRequest>,
    metadata?: grpc.Metadata
  ): Promise<ResetRiskResponse>;
  DeleteUser(
    request: DeepPartial<DeleteUserRequest>,
    metadata?: grpc.Metadata
  ): Promise<DeleteUserResponse>;
  CreateUser(
    request: DeepPartial<CreateUserRequest>,
    metadata?: grpc.Metadata
  ): Promise<CreateUserResponse>;
  ListUsers(
    request: DeepPartial<ListUsersRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListUsersResponse>;
  /** AddUserClaim adds a new custom claim into a users "claim" map. */
  AddUserClaim(
    request: DeepPartial<AddUserClaimRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddUserClaimResponse>;
  /** DeleteUserClaim deletes a custom claim from a users "claim" map. */
  DeleteUserClaim(
    request: DeepPartial<DeleteUserClaimRequest>,
    metadata?: grpc.Metadata
  ): Promise<DeleteUserClaimResponse>;
  /** AddUserToGroup adds a group to a user. */
  AddUserToGroup(
    request: DeepPartial<AddUserToGroupRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddUserToGroupResponse>;
  NewUserPassword(
    request: DeepPartial<NewUserPasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<NewUserPasswordResponse>;
}

export class IdentityProviderAdminClientImpl implements IdentityProviderAdmin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  ResetRisk(
    request: DeepPartial<ResetRiskRequest>,
    metadata?: grpc.Metadata
  ): Promise<ResetRiskResponse> {
    return this.rpc.unary(
      IdentityProviderAdminResetRiskDesc,
      ResetRiskRequest.fromPartial(request),
      metadata
    );
  }

  DeleteUser(
    request: DeepPartial<DeleteUserRequest>,
    metadata?: grpc.Metadata
  ): Promise<DeleteUserResponse> {
    return this.rpc.unary(
      IdentityProviderAdminDeleteUserDesc,
      DeleteUserRequest.fromPartial(request),
      metadata
    );
  }

  CreateUser(
    request: DeepPartial<CreateUserRequest>,
    metadata?: grpc.Metadata
  ): Promise<CreateUserResponse> {
    return this.rpc.unary(
      IdentityProviderAdminCreateUserDesc,
      CreateUserRequest.fromPartial(request),
      metadata
    );
  }

  ListUsers(
    request: DeepPartial<ListUsersRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListUsersResponse> {
    return this.rpc.unary(
      IdentityProviderAdminListUsersDesc,
      ListUsersRequest.fromPartial(request),
      metadata
    );
  }

  AddUserClaim(
    request: DeepPartial<AddUserClaimRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddUserClaimResponse> {
    return this.rpc.unary(
      IdentityProviderAdminAddUserClaimDesc,
      AddUserClaimRequest.fromPartial(request),
      metadata
    );
  }

  DeleteUserClaim(
    request: DeepPartial<DeleteUserClaimRequest>,
    metadata?: grpc.Metadata
  ): Promise<DeleteUserClaimResponse> {
    return this.rpc.unary(
      IdentityProviderAdminDeleteUserClaimDesc,
      DeleteUserClaimRequest.fromPartial(request),
      metadata
    );
  }

  AddUserToGroup(
    request: DeepPartial<AddUserToGroupRequest>,
    metadata?: grpc.Metadata
  ): Promise<AddUserToGroupResponse> {
    return this.rpc.unary(
      IdentityProviderAdminAddUserToGroupDesc,
      AddUserToGroupRequest.fromPartial(request),
      metadata
    );
  }

  NewUserPassword(
    request: DeepPartial<NewUserPasswordRequest>,
    metadata?: grpc.Metadata
  ): Promise<NewUserPasswordResponse> {
    return this.rpc.unary(
      IdentityProviderAdminNewUserPasswordDesc,
      NewUserPasswordRequest.fromPartial(request),
      metadata
    );
  }
}

export const IdentityProviderAdminDesc = {
  serviceName: "idp.IdentityProviderAdmin",
};

export const IdentityProviderAdminResetRiskDesc: UnaryMethodDefinitionish = {
  methodName: "ResetRisk",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetRiskRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ResetRiskResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminDeleteUserDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUser",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteUserResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminCreateUserDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUser",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreateUserResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminListUsersDesc: UnaryMethodDefinitionish = {
  methodName: "ListUsers",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ListUsersResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminAddUserClaimDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserClaim",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserClaimRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddUserClaimResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminDeleteUserClaimDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUserClaim",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserClaimRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteUserClaimResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminAddUserToGroupDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserToGroup",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserToGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddUserToGroupResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminNewUserPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "NewUserPassword",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return NewUserPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...NewUserPasswordResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

/**
 * The Sync service exposes RPCs for synchronizing the built-in user and group
 * databases.
 */
export interface Sync {
  /**
   * SyncFull requests a full synchronization of all externally-managed
   * resources. Any resources not part of the request will be deleted.
   * Only fields that are externally-manageable are set, the others are ignored.
   * The following User fields are currently externally manageable:
   * id, status, profile, member_of, claim
   * The password field has a bit more special rules and is set if the algorithm
   * is ORACLE (but not if it is set to anything else). Also if the sync client
   * stops sending a password, the existing one is kept (this is different from
   * all other fields). If you are not using a password oracle, you should not
   * be setting the password field on your synchronized users.
   */
  SyncFull(
    request: DeepPartial<SyncFullRequest>,
    metadata?: grpc.Metadata
  ): Promise<SyncFullResponse>;
}

export class SyncClientImpl implements Sync {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  SyncFull(
    request: DeepPartial<SyncFullRequest>,
    metadata?: grpc.Metadata
  ): Promise<SyncFullResponse> {
    return this.rpc.unary(SyncSyncFullDesc, SyncFullRequest.fromPartial(request), metadata);
  }
}

export const SyncDesc = {
  serviceName: "idp.Sync",
};

export const SyncSyncFullDesc: UnaryMethodDefinitionish = {
  methodName: "SyncFull",
  service: SyncDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SyncFullRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SyncFullResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
