import { mdiAlert } from "@mdi/js";
import Icon from "@mdi/react";
import styled from "styled-components/macro";
import { hasLocalStorage } from "./api";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.branding?.background ?? "white"};

  @media (max-width: 500px) {
    align-items: normal;
    justify-content: normal;
  }
`;

const CardContainer = styled.div`
  width: 500px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: 500px) {
    padding: 0;
    border-radius: 0px;
    border: none;
  }
`;

const CardContent = styled.div<{ hasWarning: boolean }>`
  padding: 50px;
  ${(p) => (p.hasWarning ? "padding-top: 0;" : "")}

  @media (max-width: 500px) {
    padding: 15px;
    border-radius: 0px;
    border: none;
  }
`;

const Warning = styled.div`
  background: orange;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-right: 50px;
`;

const IconContainer = styled.div`
  width: 50px;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

export function Card(props: { children: React.ReactNode }): JSX.Element {
  return (
    <Background>
      <CardContainer>
        {!hasLocalStorage ? (
          <Warning>
            <IconContainer>
              <Icon path={mdiAlert} size={1.5} />
            </IconContainer>
            <p>
              <b>Ihr Browser kann sich die Anmeldung nicht merken.</b>
              <br />
              Verlassen Sie den Inkognito-Modus oder aktivieren Sie Cookies.
            </p>
          </Warning>
        ) : null}

        <CardContent hasWarning={!hasLocalStorage}>{props.children}</CardContent>
      </CardContainer>
    </Background>
  );
}
