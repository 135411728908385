import { darken } from "polished";
import styled from "styled-components/macro";

const FakeUserImageContainer = styled.div<{ color: string; size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background-color: ${(p) => p.color};
  color: ${(p) => darken(0.3, p.color)};
  border-radius: ${(p) => p.size / 2}px;
  font-weight: bold;
  line-height: 1.6;
  margin-right: ${(p) => p.size / 3.2}px;
  font-size: ${(p) => p.size / 1.6}px;
  text-align: center;
`;

export function FakeUserImage(props: { name: string; size: number }): JSX.Element {
  // TODO: Split at grapheme boundary
  return (
    <FakeUserImageContainer size={props.size} color="#63F5AB">
      {props.name[0]}
    </FakeUserImageContainer>
  );
}
