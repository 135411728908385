import React from "react";
import { Nav } from "./Nav";
import construction from "./construction.svg";

export function Sessions() {
  return (
    <Nav>
      <img src={construction} alt="construction" />
    </Nav>
  );
}
