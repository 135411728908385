/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { ClientProfile } from "../../../idp/api/idp";
import { BrowserHeaders } from "browser-headers";

export const protobufPackage = "idp.oauth2";

export enum ClientType {
  UNKNOWN_CLIENT = 0,
  CONFIDENTIAL_CLIENT = 1,
  PUBLIC_CLIENT = 2,
  SERVICE_ACCOUNT = 3,
  UNRECOGNIZED = -1,
}

export enum SubjectType {
  SUBJECT_PUBLIC = 0,
  SUBJECT_PAIRWISE = 1,
  UNRECOGNIZED = -1,
}

export enum ResponseType {
  RESPONSE_TYPE_UNKNOWN = 0,
  RESPONSE_TYPE_CODE = 1,
  RESPONSE_TYPE_ID_TOKEN = 2,
  RESPONSE_TYPE_TOKEN = 3,
  RESPONSE_TYPE_NONE = 4,
  UNRECOGNIZED = -1,
}

export enum PKCEChallengeMethod {
  PKCE_METHOD_NONE = 0,
  PKCE_METHOD_PLAIN = 1,
  PKCE_METHOD_S256 = 2,
  UNRECOGNIZED = -1,
}

/** OAuth2/OIDC Client config */
export interface Client {
  $type: "idp.oauth2.Client";
  id: string;
  redirectUri: string[];
  /** If this is set, implicit and PKCE flows are not allowed */
  secret: string;
  secretHash: Uint8Array;
  /**
   * The client is allowed to request the following scopes without
   * prompting the user for consent.
   */
  autoConsentScope: string[];
  /**
   * The client is allowed to request the following scopes for itself (without
   * being attached to a user). Only OAuth2 scopes are allowed here as there is
   * no identity to fullfill OIDC scopes.
   */
  selfScopes: string[];
  subjectType: SubjectType;
  /**
   * List of claim names which should be included in the ID token. These will be
   * included in addition to any the client might request through the claims
   * parameter. They are however only included if the client has access to them,
   * i.e. if they are available to this client on the userinfo endpoint.
   */
  extraIdTokenClaims: string[];
  /** Only issue when user is a member of any of these groups */
  restrictToGroup: string[];
  /** Only issue when authentication fulfills any of these classes */
  restrictToAcr: string[];
  /** Allow the OAuth 2.0 implicit flow. Not recommended for new clients. */
  allowImplicit: boolean;
  /**
   * Enables or disables App Tokens, an interopatibility feature to work with
   * protocols and systems still dependant on legacy passwords.
   */
  enableApptokens: boolean;
  profile: ClientProfile | undefined;
}

export interface ClaimRequest {
  $type: "idp.oauth2.ClaimRequest";
  name: string;
  essential: boolean;
  inUserinfo: boolean;
  inIdToken: boolean;
  value: string[];
  locale: string;
}

export interface AuthorizeRequest {
  $type: "idp.oauth2.AuthorizeRequest";
  clientId: string;
  scope: string[];
  redirectUri: string;
  nonce: string;
  maxAge: number;
  requestedAcr: string[];
  responseType: ResponseType[];
  claimsLocale: string[];
  codeChallenge: string;
  codeChallengeMethod: PKCEChallengeMethod;
  claimRequest: ClaimRequest[];
  consentScopes: string[];
}

export interface AuthorizeError {
  $type: "idp.oauth2.AuthorizeError";
  error: string;
  errorDescription: string;
  errorUri: string;
}

export interface AuthorizeRequireConsent {
  $type: "idp.oauth2.AuthorizeRequireConsent";
  scopes: string[];
}

export interface AuthorizeData {
  $type: "idp.oauth2.AuthorizeData";
  code: string;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  idToken: string;
  sessionState: string;
  scope: string[];
}

export interface AuthorizeResponse {
  $type: "idp.oauth2.AuthorizeResponse";
  type?:
    | { $case: "data"; data: AuthorizeData }
    | { $case: "requireConsent"; requireConsent: AuthorizeRequireConsent }
    | { $case: "error"; error: AuthorizeError };
  clientProfile: ClientProfile | undefined;
}

export interface RevokeRequest {
  $type: "idp.oauth2.RevokeRequest";
  clientId: string;
}

export interface RevokeResponse {
  $type: "idp.oauth2.RevokeResponse";
}

export interface Consent {
  $type: "idp.oauth2.Consent";
  clientId: string;
  subject: string;
  scope: string[];
}

export interface GetConsentRequest {
  $type: "idp.oauth2.GetConsentRequest";
}

export interface GetConsentResponse {
  $type: "idp.oauth2.GetConsentResponse";
  consent: Consent[];
}

export interface GetClientProfileRequest {
  $type: "idp.oauth2.GetClientProfileRequest";
  clientId: string;
}

export interface ReplaceClientRequest {
  $type: "idp.oauth2.ReplaceClientRequest";
  newClient: Client | undefined;
  oldClientId: string;
}

export interface ReplaceClientResponse {
  $type: "idp.oauth2.ReplaceClientResponse";
  clientId: string;
}

export interface ListClientsRequest {
  $type: "idp.oauth2.ListClientsRequest";
}

export interface ListClientsResponse {
  $type: "idp.oauth2.ListClientsResponse";
  client: Client[];
}

export interface RevokeClientRequest {
  $type: "idp.oauth2.RevokeClientRequest";
  clientId: string;
}

export interface RevokeClientResponse {
  $type: "idp.oauth2.RevokeClientResponse";
}

const baseClient: object = {
  $type: "idp.oauth2.Client",
  id: "",
  redirectUri: "",
  secret: "",
  autoConsentScope: "",
  selfScopes: "",
  subjectType: 0,
  extraIdTokenClaims: "",
  restrictToGroup: "",
  restrictToAcr: "",
  allowImplicit: false,
  enableApptokens: false,
};

export const Client = {
  $type: "idp.oauth2.Client" as const,

  encode(message: Client, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.redirectUri) {
      writer.uint32(18).string(v!);
    }
    if (message.secret !== "") {
      writer.uint32(26).string(message.secret);
    }
    if (message.secretHash.length !== 0) {
      writer.uint32(42).bytes(message.secretHash);
    }
    for (const v of message.autoConsentScope) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.selfScopes) {
      writer.uint32(82).string(v!);
    }
    if (message.subjectType !== 0) {
      writer.uint32(96).int32(message.subjectType);
    }
    for (const v of message.extraIdTokenClaims) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.restrictToGroup) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.restrictToAcr) {
      writer.uint32(74).string(v!);
    }
    if (message.allowImplicit === true) {
      writer.uint32(88).bool(message.allowImplicit);
    }
    if (message.enableApptokens === true) {
      writer.uint32(112).bool(message.enableApptokens);
    }
    if (message.profile !== undefined) {
      ClientProfile.encode(message.profile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClient } as Client;
    message.redirectUri = [];
    message.autoConsentScope = [];
    message.selfScopes = [];
    message.extraIdTokenClaims = [];
    message.restrictToGroup = [];
    message.restrictToAcr = [];
    message.secretHash = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.redirectUri.push(reader.string());
          break;
        case 3:
          message.secret = reader.string();
          break;
        case 5:
          message.secretHash = reader.bytes();
          break;
        case 8:
          message.autoConsentScope.push(reader.string());
          break;
        case 10:
          message.selfScopes.push(reader.string());
          break;
        case 12:
          message.subjectType = reader.int32() as any;
          break;
        case 13:
          message.extraIdTokenClaims.push(reader.string());
          break;
        case 7:
          message.restrictToGroup.push(reader.string());
          break;
        case 9:
          message.restrictToAcr.push(reader.string());
          break;
        case 11:
          message.allowImplicit = reader.bool();
          break;
        case 14:
          message.enableApptokens = reader.bool();
          break;
        case 4:
          message.profile = ClientProfile.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Client>): Client {
    const message = { ...baseClient } as Client;
    message.redirectUri = [];
    message.autoConsentScope = [];
    message.selfScopes = [];
    message.extraIdTokenClaims = [];
    message.restrictToGroup = [];
    message.restrictToAcr = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    }
    if (object.redirectUri !== undefined && object.redirectUri !== null) {
      for (const e of object.redirectUri) {
        message.redirectUri.push(e);
      }
    }
    if (object.secret !== undefined && object.secret !== null) {
      message.secret = object.secret;
    }
    if (object.secretHash !== undefined && object.secretHash !== null) {
      message.secretHash = object.secretHash;
    }
    if (object.autoConsentScope !== undefined && object.autoConsentScope !== null) {
      for (const e of object.autoConsentScope) {
        message.autoConsentScope.push(e);
      }
    }
    if (object.selfScopes !== undefined && object.selfScopes !== null) {
      for (const e of object.selfScopes) {
        message.selfScopes.push(e);
      }
    }
    if (object.subjectType !== undefined && object.subjectType !== null) {
      message.subjectType = object.subjectType;
    }
    if (object.extraIdTokenClaims !== undefined && object.extraIdTokenClaims !== null) {
      for (const e of object.extraIdTokenClaims) {
        message.extraIdTokenClaims.push(e);
      }
    }
    if (object.restrictToGroup !== undefined && object.restrictToGroup !== null) {
      for (const e of object.restrictToGroup) {
        message.restrictToGroup.push(e);
      }
    }
    if (object.restrictToAcr !== undefined && object.restrictToAcr !== null) {
      for (const e of object.restrictToAcr) {
        message.restrictToAcr.push(e);
      }
    }
    if (object.allowImplicit !== undefined && object.allowImplicit !== null) {
      message.allowImplicit = object.allowImplicit;
    }
    if (object.enableApptokens !== undefined && object.enableApptokens !== null) {
      message.enableApptokens = object.enableApptokens;
    }
    if (object.profile !== undefined && object.profile !== null) {
      message.profile = ClientProfile.fromPartial(object.profile);
    }
    return message;
  },
};

messageTypeRegistry.set(Client.$type, Client);

const baseClaimRequest: object = {
  $type: "idp.oauth2.ClaimRequest",
  name: "",
  essential: false,
  inUserinfo: false,
  inIdToken: false,
  value: "",
  locale: "",
};

export const ClaimRequest = {
  $type: "idp.oauth2.ClaimRequest" as const,

  encode(message: ClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.essential === true) {
      writer.uint32(16).bool(message.essential);
    }
    if (message.inUserinfo === true) {
      writer.uint32(40).bool(message.inUserinfo);
    }
    if (message.inIdToken === true) {
      writer.uint32(48).bool(message.inIdToken);
    }
    for (const v of message.value) {
      writer.uint32(26).string(v!);
    }
    if (message.locale !== "") {
      writer.uint32(34).string(message.locale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClaimRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClaimRequest } as ClaimRequest;
    message.value = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.essential = reader.bool();
          break;
        case 5:
          message.inUserinfo = reader.bool();
          break;
        case 6:
          message.inIdToken = reader.bool();
          break;
        case 3:
          message.value.push(reader.string());
          break;
        case 4:
          message.locale = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ClaimRequest>): ClaimRequest {
    const message = { ...baseClaimRequest } as ClaimRequest;
    message.value = [];
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    }
    if (object.essential !== undefined && object.essential !== null) {
      message.essential = object.essential;
    }
    if (object.inUserinfo !== undefined && object.inUserinfo !== null) {
      message.inUserinfo = object.inUserinfo;
    }
    if (object.inIdToken !== undefined && object.inIdToken !== null) {
      message.inIdToken = object.inIdToken;
    }
    if (object.value !== undefined && object.value !== null) {
      for (const e of object.value) {
        message.value.push(e);
      }
    }
    if (object.locale !== undefined && object.locale !== null) {
      message.locale = object.locale;
    }
    return message;
  },
};

messageTypeRegistry.set(ClaimRequest.$type, ClaimRequest);

const baseAuthorizeRequest: object = {
  $type: "idp.oauth2.AuthorizeRequest",
  clientId: "",
  scope: "",
  redirectUri: "",
  nonce: "",
  maxAge: 0,
  requestedAcr: "",
  responseType: 0,
  claimsLocale: "",
  codeChallenge: "",
  codeChallengeMethod: 0,
  consentScopes: "",
};

export const AuthorizeRequest = {
  $type: "idp.oauth2.AuthorizeRequest" as const,

  encode(message: AuthorizeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    for (const v of message.scope) {
      writer.uint32(90).string(v!);
    }
    if (message.redirectUri !== "") {
      writer.uint32(18).string(message.redirectUri);
    }
    if (message.nonce !== "") {
      writer.uint32(26).string(message.nonce);
    }
    if (message.maxAge !== 0) {
      writer.uint32(32).uint32(message.maxAge);
    }
    for (const v of message.requestedAcr) {
      writer.uint32(42).string(v!);
    }
    writer.uint32(50).fork();
    for (const v of message.responseType) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.claimsLocale) {
      writer.uint32(58).string(v!);
    }
    if (message.codeChallenge !== "") {
      writer.uint32(66).string(message.codeChallenge);
    }
    if (message.codeChallengeMethod !== 0) {
      writer.uint32(72).int32(message.codeChallengeMethod);
    }
    for (const v of message.claimRequest) {
      ClaimRequest.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.consentScopes) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthorizeRequest } as AuthorizeRequest;
    message.scope = [];
    message.requestedAcr = [];
    message.responseType = [];
    message.claimsLocale = [];
    message.claimRequest = [];
    message.consentScopes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        case 11:
          message.scope.push(reader.string());
          break;
        case 2:
          message.redirectUri = reader.string();
          break;
        case 3:
          message.nonce = reader.string();
          break;
        case 4:
          message.maxAge = reader.uint32();
          break;
        case 5:
          message.requestedAcr.push(reader.string());
          break;
        case 6:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.responseType.push(reader.int32() as any);
            }
          } else {
            message.responseType.push(reader.int32() as any);
          }
          break;
        case 7:
          message.claimsLocale.push(reader.string());
          break;
        case 8:
          message.codeChallenge = reader.string();
          break;
        case 9:
          message.codeChallengeMethod = reader.int32() as any;
          break;
        case 12:
          message.claimRequest.push(ClaimRequest.decode(reader, reader.uint32()));
          break;
        case 10:
          message.consentScopes.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthorizeRequest>): AuthorizeRequest {
    const message = { ...baseAuthorizeRequest } as AuthorizeRequest;
    message.scope = [];
    message.requestedAcr = [];
    message.responseType = [];
    message.claimsLocale = [];
    message.claimRequest = [];
    message.consentScopes = [];
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    if (object.scope !== undefined && object.scope !== null) {
      for (const e of object.scope) {
        message.scope.push(e);
      }
    }
    if (object.redirectUri !== undefined && object.redirectUri !== null) {
      message.redirectUri = object.redirectUri;
    }
    if (object.nonce !== undefined && object.nonce !== null) {
      message.nonce = object.nonce;
    }
    if (object.maxAge !== undefined && object.maxAge !== null) {
      message.maxAge = object.maxAge;
    }
    if (object.requestedAcr !== undefined && object.requestedAcr !== null) {
      for (const e of object.requestedAcr) {
        message.requestedAcr.push(e);
      }
    }
    if (object.responseType !== undefined && object.responseType !== null) {
      for (const e of object.responseType) {
        message.responseType.push(e);
      }
    }
    if (object.claimsLocale !== undefined && object.claimsLocale !== null) {
      for (const e of object.claimsLocale) {
        message.claimsLocale.push(e);
      }
    }
    if (object.codeChallenge !== undefined && object.codeChallenge !== null) {
      message.codeChallenge = object.codeChallenge;
    }
    if (object.codeChallengeMethod !== undefined && object.codeChallengeMethod !== null) {
      message.codeChallengeMethod = object.codeChallengeMethod;
    }
    if (object.claimRequest !== undefined && object.claimRequest !== null) {
      for (const e of object.claimRequest) {
        message.claimRequest.push(ClaimRequest.fromPartial(e));
      }
    }
    if (object.consentScopes !== undefined && object.consentScopes !== null) {
      for (const e of object.consentScopes) {
        message.consentScopes.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(AuthorizeRequest.$type, AuthorizeRequest);

const baseAuthorizeError: object = {
  $type: "idp.oauth2.AuthorizeError",
  error: "",
  errorDescription: "",
  errorUri: "",
};

export const AuthorizeError = {
  $type: "idp.oauth2.AuthorizeError" as const,

  encode(message: AuthorizeError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== "") {
      writer.uint32(10).string(message.error);
    }
    if (message.errorDescription !== "") {
      writer.uint32(18).string(message.errorDescription);
    }
    if (message.errorUri !== "") {
      writer.uint32(26).string(message.errorUri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthorizeError } as AuthorizeError;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.error = reader.string();
          break;
        case 2:
          message.errorDescription = reader.string();
          break;
        case 3:
          message.errorUri = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthorizeError>): AuthorizeError {
    const message = { ...baseAuthorizeError } as AuthorizeError;
    if (object.error !== undefined && object.error !== null) {
      message.error = object.error;
    }
    if (object.errorDescription !== undefined && object.errorDescription !== null) {
      message.errorDescription = object.errorDescription;
    }
    if (object.errorUri !== undefined && object.errorUri !== null) {
      message.errorUri = object.errorUri;
    }
    return message;
  },
};

messageTypeRegistry.set(AuthorizeError.$type, AuthorizeError);

const baseAuthorizeRequireConsent: object = {
  $type: "idp.oauth2.AuthorizeRequireConsent",
  scopes: "",
};

export const AuthorizeRequireConsent = {
  $type: "idp.oauth2.AuthorizeRequireConsent" as const,

  encode(message: AuthorizeRequireConsent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scopes) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeRequireConsent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthorizeRequireConsent } as AuthorizeRequireConsent;
    message.scopes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scopes.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthorizeRequireConsent>): AuthorizeRequireConsent {
    const message = { ...baseAuthorizeRequireConsent } as AuthorizeRequireConsent;
    message.scopes = [];
    if (object.scopes !== undefined && object.scopes !== null) {
      for (const e of object.scopes) {
        message.scopes.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(AuthorizeRequireConsent.$type, AuthorizeRequireConsent);

const baseAuthorizeData: object = {
  $type: "idp.oauth2.AuthorizeData",
  code: "",
  accessToken: "",
  tokenType: "",
  expiresIn: 0,
  idToken: "",
  sessionState: "",
  scope: "",
};

export const AuthorizeData = {
  $type: "idp.oauth2.AuthorizeData" as const,

  encode(message: AuthorizeData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    if (message.tokenType !== "") {
      writer.uint32(66).string(message.tokenType);
    }
    if (message.expiresIn !== 0) {
      writer.uint32(32).uint32(message.expiresIn);
    }
    if (message.idToken !== "") {
      writer.uint32(42).string(message.idToken);
    }
    if (message.sessionState !== "") {
      writer.uint32(50).string(message.sessionState);
    }
    for (const v of message.scope) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthorizeData } as AuthorizeData;
    message.scope = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.code = reader.string();
          break;
        case 2:
          message.accessToken = reader.string();
          break;
        case 8:
          message.tokenType = reader.string();
          break;
        case 4:
          message.expiresIn = reader.uint32();
          break;
        case 5:
          message.idToken = reader.string();
          break;
        case 6:
          message.sessionState = reader.string();
          break;
        case 7:
          message.scope.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthorizeData>): AuthorizeData {
    const message = { ...baseAuthorizeData } as AuthorizeData;
    message.scope = [];
    if (object.code !== undefined && object.code !== null) {
      message.code = object.code;
    }
    if (object.accessToken !== undefined && object.accessToken !== null) {
      message.accessToken = object.accessToken;
    }
    if (object.tokenType !== undefined && object.tokenType !== null) {
      message.tokenType = object.tokenType;
    }
    if (object.expiresIn !== undefined && object.expiresIn !== null) {
      message.expiresIn = object.expiresIn;
    }
    if (object.idToken !== undefined && object.idToken !== null) {
      message.idToken = object.idToken;
    }
    if (object.sessionState !== undefined && object.sessionState !== null) {
      message.sessionState = object.sessionState;
    }
    if (object.scope !== undefined && object.scope !== null) {
      for (const e of object.scope) {
        message.scope.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(AuthorizeData.$type, AuthorizeData);

const baseAuthorizeResponse: object = { $type: "idp.oauth2.AuthorizeResponse" };

export const AuthorizeResponse = {
  $type: "idp.oauth2.AuthorizeResponse" as const,

  encode(message: AuthorizeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type?.$case === "data") {
      AuthorizeData.encode(message.type.data, writer.uint32(10).fork()).ldelim();
    }
    if (message.type?.$case === "requireConsent") {
      AuthorizeRequireConsent.encode(
        message.type.requireConsent,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.type?.$case === "error") {
      AuthorizeError.encode(message.type.error, writer.uint32(26).fork()).ldelim();
    }
    if (message.clientProfile !== undefined) {
      ClientProfile.encode(message.clientProfile, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthorizeResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthorizeResponse } as AuthorizeResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = { $case: "data", data: AuthorizeData.decode(reader, reader.uint32()) };
          break;
        case 2:
          message.type = {
            $case: "requireConsent",
            requireConsent: AuthorizeRequireConsent.decode(reader, reader.uint32()),
          };
          break;
        case 3:
          message.type = { $case: "error", error: AuthorizeError.decode(reader, reader.uint32()) };
          break;
        case 4:
          message.clientProfile = ClientProfile.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AuthorizeResponse>): AuthorizeResponse {
    const message = { ...baseAuthorizeResponse } as AuthorizeResponse;
    if (
      object.type?.$case === "data" &&
      object.type?.data !== undefined &&
      object.type?.data !== null
    ) {
      message.type = { $case: "data", data: AuthorizeData.fromPartial(object.type.data) };
    }
    if (
      object.type?.$case === "requireConsent" &&
      object.type?.requireConsent !== undefined &&
      object.type?.requireConsent !== null
    ) {
      message.type = {
        $case: "requireConsent",
        requireConsent: AuthorizeRequireConsent.fromPartial(object.type.requireConsent),
      };
    }
    if (
      object.type?.$case === "error" &&
      object.type?.error !== undefined &&
      object.type?.error !== null
    ) {
      message.type = { $case: "error", error: AuthorizeError.fromPartial(object.type.error) };
    }
    if (object.clientProfile !== undefined && object.clientProfile !== null) {
      message.clientProfile = ClientProfile.fromPartial(object.clientProfile);
    }
    return message;
  },
};

messageTypeRegistry.set(AuthorizeResponse.$type, AuthorizeResponse);

const baseRevokeRequest: object = { $type: "idp.oauth2.RevokeRequest", clientId: "" };

export const RevokeRequest = {
  $type: "idp.oauth2.RevokeRequest" as const,

  encode(message: RevokeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRevokeRequest } as RevokeRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RevokeRequest>): RevokeRequest {
    const message = { ...baseRevokeRequest } as RevokeRequest;
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    return message;
  },
};

messageTypeRegistry.set(RevokeRequest.$type, RevokeRequest);

const baseRevokeResponse: object = { $type: "idp.oauth2.RevokeResponse" };

export const RevokeResponse = {
  $type: "idp.oauth2.RevokeResponse" as const,

  encode(_: RevokeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRevokeResponse } as RevokeResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<RevokeResponse>): RevokeResponse {
    const message = { ...baseRevokeResponse } as RevokeResponse;
    return message;
  },
};

messageTypeRegistry.set(RevokeResponse.$type, RevokeResponse);

const baseConsent: object = { $type: "idp.oauth2.Consent", clientId: "", subject: "", scope: "" };

export const Consent = {
  $type: "idp.oauth2.Consent" as const,

  encode(message: Consent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.subject !== "") {
      writer.uint32(26).string(message.subject);
    }
    for (const v of message.scope) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseConsent } as Consent;
    message.scope = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.clientId = reader.string();
          break;
        case 3:
          message.subject = reader.string();
          break;
        case 1:
          message.scope.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Consent>): Consent {
    const message = { ...baseConsent } as Consent;
    message.scope = [];
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    if (object.subject !== undefined && object.subject !== null) {
      message.subject = object.subject;
    }
    if (object.scope !== undefined && object.scope !== null) {
      for (const e of object.scope) {
        message.scope.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(Consent.$type, Consent);

const baseGetConsentRequest: object = { $type: "idp.oauth2.GetConsentRequest" };

export const GetConsentRequest = {
  $type: "idp.oauth2.GetConsentRequest" as const,

  encode(_: GetConsentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetConsentRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetConsentRequest } as GetConsentRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetConsentRequest>): GetConsentRequest {
    const message = { ...baseGetConsentRequest } as GetConsentRequest;
    return message;
  },
};

messageTypeRegistry.set(GetConsentRequest.$type, GetConsentRequest);

const baseGetConsentResponse: object = { $type: "idp.oauth2.GetConsentResponse" };

export const GetConsentResponse = {
  $type: "idp.oauth2.GetConsentResponse" as const,

  encode(message: GetConsentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.consent) {
      Consent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetConsentResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetConsentResponse } as GetConsentResponse;
    message.consent = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.consent.push(Consent.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetConsentResponse>): GetConsentResponse {
    const message = { ...baseGetConsentResponse } as GetConsentResponse;
    message.consent = [];
    if (object.consent !== undefined && object.consent !== null) {
      for (const e of object.consent) {
        message.consent.push(Consent.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetConsentResponse.$type, GetConsentResponse);

const baseGetClientProfileRequest: object = {
  $type: "idp.oauth2.GetClientProfileRequest",
  clientId: "",
};

export const GetClientProfileRequest = {
  $type: "idp.oauth2.GetClientProfileRequest" as const,

  encode(message: GetClientProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientProfileRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetClientProfileRequest } as GetClientProfileRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetClientProfileRequest>): GetClientProfileRequest {
    const message = { ...baseGetClientProfileRequest } as GetClientProfileRequest;
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    return message;
  },
};

messageTypeRegistry.set(GetClientProfileRequest.$type, GetClientProfileRequest);

const baseReplaceClientRequest: object = {
  $type: "idp.oauth2.ReplaceClientRequest",
  oldClientId: "",
};

export const ReplaceClientRequest = {
  $type: "idp.oauth2.ReplaceClientRequest" as const,

  encode(message: ReplaceClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newClient !== undefined) {
      Client.encode(message.newClient, writer.uint32(10).fork()).ldelim();
    }
    if (message.oldClientId !== "") {
      writer.uint32(18).string(message.oldClientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceClientRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReplaceClientRequest } as ReplaceClientRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.newClient = Client.decode(reader, reader.uint32());
          break;
        case 2:
          message.oldClientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ReplaceClientRequest>): ReplaceClientRequest {
    const message = { ...baseReplaceClientRequest } as ReplaceClientRequest;
    if (object.newClient !== undefined && object.newClient !== null) {
      message.newClient = Client.fromPartial(object.newClient);
    }
    if (object.oldClientId !== undefined && object.oldClientId !== null) {
      message.oldClientId = object.oldClientId;
    }
    return message;
  },
};

messageTypeRegistry.set(ReplaceClientRequest.$type, ReplaceClientRequest);

const baseReplaceClientResponse: object = {
  $type: "idp.oauth2.ReplaceClientResponse",
  clientId: "",
};

export const ReplaceClientResponse = {
  $type: "idp.oauth2.ReplaceClientResponse" as const,

  encode(message: ReplaceClientResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceClientResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReplaceClientResponse } as ReplaceClientResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ReplaceClientResponse>): ReplaceClientResponse {
    const message = { ...baseReplaceClientResponse } as ReplaceClientResponse;
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    return message;
  },
};

messageTypeRegistry.set(ReplaceClientResponse.$type, ReplaceClientResponse);

const baseListClientsRequest: object = { $type: "idp.oauth2.ListClientsRequest" };

export const ListClientsRequest = {
  $type: "idp.oauth2.ListClientsRequest" as const,

  encode(_: ListClientsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListClientsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListClientsRequest } as ListClientsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListClientsRequest>): ListClientsRequest {
    const message = { ...baseListClientsRequest } as ListClientsRequest;
    return message;
  },
};

messageTypeRegistry.set(ListClientsRequest.$type, ListClientsRequest);

const baseListClientsResponse: object = { $type: "idp.oauth2.ListClientsResponse" };

export const ListClientsResponse = {
  $type: "idp.oauth2.ListClientsResponse" as const,

  encode(message: ListClientsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.client) {
      Client.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListClientsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListClientsResponse } as ListClientsResponse;
    message.client = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.client.push(Client.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListClientsResponse>): ListClientsResponse {
    const message = { ...baseListClientsResponse } as ListClientsResponse;
    message.client = [];
    if (object.client !== undefined && object.client !== null) {
      for (const e of object.client) {
        message.client.push(Client.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(ListClientsResponse.$type, ListClientsResponse);

const baseRevokeClientRequest: object = { $type: "idp.oauth2.RevokeClientRequest", clientId: "" };

export const RevokeClientRequest = {
  $type: "idp.oauth2.RevokeClientRequest" as const,

  encode(message: RevokeClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeClientRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRevokeClientRequest } as RevokeClientRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RevokeClientRequest>): RevokeClientRequest {
    const message = { ...baseRevokeClientRequest } as RevokeClientRequest;
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    return message;
  },
};

messageTypeRegistry.set(RevokeClientRequest.$type, RevokeClientRequest);

const baseRevokeClientResponse: object = { $type: "idp.oauth2.RevokeClientResponse" };

export const RevokeClientResponse = {
  $type: "idp.oauth2.RevokeClientResponse" as const,

  encode(_: RevokeClientResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeClientResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRevokeClientResponse } as RevokeClientResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<RevokeClientResponse>): RevokeClientResponse {
    const message = { ...baseRevokeClientResponse } as RevokeClientResponse;
    return message;
  },
};

messageTypeRegistry.set(RevokeClientResponse.$type, RevokeClientResponse);

export interface OAuth2 {
  /** Authorize delegates IDP authentication via OAuth2/OIDC. */
  Authorize(
    request: DeepPartial<AuthorizeRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthorizeResponse>;
  /** Revoke revokes all user-given consent and refresh tokens for a client. */
  Revoke(request: DeepPartial<RevokeRequest>, metadata?: grpc.Metadata): Promise<RevokeResponse>;
  /**
   * GetConsent returns a list of applications to which the user has granted
   * scopes.
   */
  GetConsent(
    request: DeepPartial<GetConsentRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetConsentResponse>;
  /** GetClientProfile gets the client profile of the given application */
  GetClientProfile(
    request: DeepPartial<GetClientProfileRequest>,
    metadata?: grpc.Metadata
  ): Promise<ClientProfile>;
}

export class OAuth2ClientImpl implements OAuth2 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  Authorize(
    request: DeepPartial<AuthorizeRequest>,
    metadata?: grpc.Metadata
  ): Promise<AuthorizeResponse> {
    return this.rpc.unary(OAuth2AuthorizeDesc, AuthorizeRequest.fromPartial(request), metadata);
  }

  Revoke(request: DeepPartial<RevokeRequest>, metadata?: grpc.Metadata): Promise<RevokeResponse> {
    return this.rpc.unary(OAuth2RevokeDesc, RevokeRequest.fromPartial(request), metadata);
  }

  GetConsent(
    request: DeepPartial<GetConsentRequest>,
    metadata?: grpc.Metadata
  ): Promise<GetConsentResponse> {
    return this.rpc.unary(OAuth2GetConsentDesc, GetConsentRequest.fromPartial(request), metadata);
  }

  GetClientProfile(
    request: DeepPartial<GetClientProfileRequest>,
    metadata?: grpc.Metadata
  ): Promise<ClientProfile> {
    return this.rpc.unary(
      OAuth2GetClientProfileDesc,
      GetClientProfileRequest.fromPartial(request),
      metadata
    );
  }
}

export const OAuth2Desc = {
  serviceName: "idp.oauth2.OAuth2",
};

export const OAuth2AuthorizeDesc: UnaryMethodDefinitionish = {
  methodName: "Authorize",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthorizeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthorizeResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const OAuth2RevokeDesc: UnaryMethodDefinitionish = {
  methodName: "Revoke",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RevokeResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const OAuth2GetConsentDesc: UnaryMethodDefinitionish = {
  methodName: "GetConsent",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetConsentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetConsentResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const OAuth2GetClientProfileDesc: UnaryMethodDefinitionish = {
  methodName: "GetClientProfile",
  service: OAuth2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetClientProfileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ClientProfile.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

/**
 * The OAuth2Admin service handles administrative operations for OAuth2
 * delegation. This service requires the id.dolansoft.org/oauth2 scope.
 */
export interface OAuth2Admin {
  /**
   * ReplaceClient creates, replaces or deletes a client. If old_client_id is
   * set to a non-existent client, a NotFound error is returned.
   */
  ReplaceClient(
    request: DeepPartial<ReplaceClientRequest>,
    metadata?: grpc.Metadata
  ): Promise<ReplaceClientResponse>;
  /** ListClients lists all registered clients. */
  ListClients(
    request: DeepPartial<ListClientsRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListClientsResponse>;
  /**
   * RevokeClient revokes all persistent credentials and user-given scope
   * consent. This RPC should be used if it is believed the client or its data
   * have been compromised.
   */
  RevokeClient(
    request: DeepPartial<RevokeClientRequest>,
    metadata?: grpc.Metadata
  ): Promise<RevokeClientResponse>;
}

export class OAuth2AdminClientImpl implements OAuth2Admin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  ReplaceClient(
    request: DeepPartial<ReplaceClientRequest>,
    metadata?: grpc.Metadata
  ): Promise<ReplaceClientResponse> {
    return this.rpc.unary(
      OAuth2AdminReplaceClientDesc,
      ReplaceClientRequest.fromPartial(request),
      metadata
    );
  }

  ListClients(
    request: DeepPartial<ListClientsRequest>,
    metadata?: grpc.Metadata
  ): Promise<ListClientsResponse> {
    return this.rpc.unary(
      OAuth2AdminListClientsDesc,
      ListClientsRequest.fromPartial(request),
      metadata
    );
  }

  RevokeClient(
    request: DeepPartial<RevokeClientRequest>,
    metadata?: grpc.Metadata
  ): Promise<RevokeClientResponse> {
    return this.rpc.unary(
      OAuth2AdminRevokeClientDesc,
      RevokeClientRequest.fromPartial(request),
      metadata
    );
  }
}

export const OAuth2AdminDesc = {
  serviceName: "idp.oauth2.OAuth2Admin",
};

export const OAuth2AdminReplaceClientDesc: UnaryMethodDefinitionish = {
  methodName: "ReplaceClient",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReplaceClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ReplaceClientResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const OAuth2AdminListClientsDesc: UnaryMethodDefinitionish = {
  methodName: "ListClients",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListClientsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ListClientsResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const OAuth2AdminRevokeClientDesc: UnaryMethodDefinitionish = {
  methodName: "RevokeClient",
  service: OAuth2AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RevokeClientResponse.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
