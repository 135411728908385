import { grpc } from "@improbable-eng/grpc-web";
import React, { useMemo } from "react";
import MIMEType from "whatwg-mimetype";
import { Status } from "./generated/google/rpc/status";
import { Branding, GrpcWebImpl, IdentityProviderAdminClientImpl, IdentityProviderClientImpl } from "./generated/idp/api/idp";
import { OAuth2ClientImpl } from "./generated/idp/api/oauth2/api";
import { SAML2ClientImpl } from "./generated/idp/api/saml2/api";

function localStorageTest(){
    var test = 'mndjDwYqcwCPgBzcEIRQ7f'; // Use something unique
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export const hasLocalStorage = localStorageTest();

let metadata = new grpc.Metadata({session: hasLocalStorage ? (localStorage.getItem("session") ?? []) : []});

export function newSession(token: string) {
  metadata.set("session", token);
  if(hasLocalStorage)
    localStorage.setItem("session", token)
}

export function hasSession(): boolean {
  if(hasLocalStorage)
    return localStorage.getItem("session") !== null
  return (metadata.get("session") ?? []).length > 0
}

export function getSession(): string | null {
  if(hasLocalStorage)
    return localStorage.getItem("session")
  return metadata.get("session")[0] ?? null
}

export function clearSession() {
  metadata.delete("session");
  if(hasLocalStorage)
    localStorage.removeItem("session")
}

function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function pad(unpadded: string): string {
  while (unpadded.length % 4 !== 0) {
    unpadded += "=";
  }
  return unpadded;
}


export function getErrorDetail<T>(err: any, typ: { decode(input:  Uint8Array, length?: number): T, $type: string}): T | null {
  let rawB64Status: unknown = err.metadata?.headersMap?.["grpc-status-details-bin"]?.[0];
  if (typeof rawB64Status === "string") {
    let a = Status.decode(bytesFromBase64(pad(rawB64Status)));
    let raw = a.details.find(x => x.typeUrl.split("/")[1] === typ.$type);
    if (raw !== undefined) {
      return typ.decode(raw.value);
    }
    return null;
  }
  return null;
}

export function getInjectedData<T>(typ: { decode(input: Uint8Array, length?: number): T, $type: string }): T | null {
  const candidates = window.document.querySelectorAll("script");
    for (let elem of candidates) {
      const elemType = elem.getAttribute("type");
      if (elemType === null) continue;
      const mimeType = new MIMEType(elemType);
      if (mimeType.essence !== "application/x-protobuf") continue;
      if (mimeType.parameters.get("messagetype") !== typ.$type) continue;
      return typ.decode(bytesFromBase64(elem.innerText));
    }
    return null;
}

export function useInjectedData<T>(typ: { decode(input: Uint8Array, length?: number): T, $type: string }): T | null {
  return useMemo(() => {
    return getInjectedData(typ);
  }, [typ]);
}

export const BrandingCtx = React.createContext<Branding | null>(null);

const rpc = new GrpcWebImpl(window.location.origin, {
  debug: false,
  metadata,
});

export const idpc = new IdentityProviderClientImpl(rpc);
export const idpac = new IdentityProviderAdminClientImpl(rpc);
export const oauth2c = new OAuth2ClientImpl(rpc);
export const saml2c = new SAML2ClientImpl(rpc);