/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "google.protobuf";

/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 *  The JSON representation for `NullValue` is JSON `null`.
 */
export enum NullValue {
  /** NULL_VALUE - Null value. */
  NULL_VALUE = 0,
  UNRECOGNIZED = -1,
}

/**
 * `Struct` represents a structured data value, consisting of fields
 * which map to dynamically typed values. In some languages, `Struct`
 * might be supported by a native representation. For example, in
 * scripting languages like JS a struct is represented as an
 * object. The details of that representation are described together
 * with the proto support for the language.
 *
 * The JSON representation for `Struct` is JSON object.
 */
export interface Struct {
  $type: "google.protobuf.Struct";
  /** Unordered map of dynamically typed values. */
  fields: { [key: string]: Value };
}

export interface Struct_FieldsEntry {
  $type: "google.protobuf.Struct.FieldsEntry";
  key: string;
  value: Value | undefined;
}

/**
 * `Value` represents a dynamically typed value which can be either
 * null, a number, a string, a boolean, a recursive struct value, or a
 * list of values. A producer of value is expected to set one of these
 * variants. Absence of any variant indicates an error.
 *
 * The JSON representation for `Value` is JSON value.
 */
export interface Value {
  $type: "google.protobuf.Value";
  kind?:
    | { $case: "nullValue"; nullValue: NullValue }
    | { $case: "numberValue"; numberValue: number }
    | { $case: "stringValue"; stringValue: string }
    | { $case: "boolValue"; boolValue: boolean }
    | { $case: "structValue"; structValue: Struct }
    | { $case: "listValue"; listValue: ListValue };
}

/**
 * `ListValue` is a wrapper around a repeated field of values.
 *
 * The JSON representation for `ListValue` is JSON array.
 */
export interface ListValue {
  $type: "google.protobuf.ListValue";
  /** Repeated field of dynamically typed values. */
  values: Value[];
}

const baseStruct: object = { $type: "google.protobuf.Struct" };

export const Struct = {
  $type: "google.protobuf.Struct" as const,

  encode(message: Struct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.fields).forEach(([key, value]) => {
      Struct_FieldsEntry.encode(
        { $type: "google.protobuf.Struct.FieldsEntry", key: key as any, value },
        writer.uint32(10).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Struct {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStruct } as Struct;
    message.fields = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = Struct_FieldsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.fields[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Struct>): Struct {
    const message = { ...baseStruct } as Struct;
    message.fields = {};
    if (object.fields !== undefined && object.fields !== null) {
      Object.entries(object.fields).forEach(([key, value]) => {
        if (value !== undefined) {
          message.fields[key] = Value.fromPartial(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(Struct.$type, Struct);

const baseStruct_FieldsEntry: object = { $type: "google.protobuf.Struct.FieldsEntry", key: "" };

export const Struct_FieldsEntry = {
  $type: "google.protobuf.Struct.FieldsEntry" as const,

  encode(message: Struct_FieldsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Struct_FieldsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStruct_FieldsEntry } as Struct_FieldsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Value.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Struct_FieldsEntry>): Struct_FieldsEntry {
    const message = { ...baseStruct_FieldsEntry } as Struct_FieldsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = Value.fromPartial(object.value);
    }
    return message;
  },
};

messageTypeRegistry.set(Struct_FieldsEntry.$type, Struct_FieldsEntry);

const baseValue: object = { $type: "google.protobuf.Value" };

export const Value = {
  $type: "google.protobuf.Value" as const,

  encode(message: Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kind?.$case === "nullValue") {
      writer.uint32(8).int32(message.kind.nullValue);
    }
    if (message.kind?.$case === "numberValue") {
      writer.uint32(17).double(message.kind.numberValue);
    }
    if (message.kind?.$case === "stringValue") {
      writer.uint32(26).string(message.kind.stringValue);
    }
    if (message.kind?.$case === "boolValue") {
      writer.uint32(32).bool(message.kind.boolValue);
    }
    if (message.kind?.$case === "structValue") {
      Struct.encode(message.kind.structValue, writer.uint32(42).fork()).ldelim();
    }
    if (message.kind?.$case === "listValue") {
      ListValue.encode(message.kind.listValue, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseValue } as Value;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.kind = { $case: "nullValue", nullValue: reader.int32() as any };
          break;
        case 2:
          message.kind = { $case: "numberValue", numberValue: reader.double() };
          break;
        case 3:
          message.kind = { $case: "stringValue", stringValue: reader.string() };
          break;
        case 4:
          message.kind = { $case: "boolValue", boolValue: reader.bool() };
          break;
        case 5:
          message.kind = {
            $case: "structValue",
            structValue: Struct.decode(reader, reader.uint32()),
          };
          break;
        case 6:
          message.kind = {
            $case: "listValue",
            listValue: ListValue.decode(reader, reader.uint32()),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Value>): Value {
    const message = { ...baseValue } as Value;
    if (
      object.kind?.$case === "nullValue" &&
      object.kind?.nullValue !== undefined &&
      object.kind?.nullValue !== null
    ) {
      message.kind = { $case: "nullValue", nullValue: object.kind.nullValue };
    }
    if (
      object.kind?.$case === "numberValue" &&
      object.kind?.numberValue !== undefined &&
      object.kind?.numberValue !== null
    ) {
      message.kind = { $case: "numberValue", numberValue: object.kind.numberValue };
    }
    if (
      object.kind?.$case === "stringValue" &&
      object.kind?.stringValue !== undefined &&
      object.kind?.stringValue !== null
    ) {
      message.kind = { $case: "stringValue", stringValue: object.kind.stringValue };
    }
    if (
      object.kind?.$case === "boolValue" &&
      object.kind?.boolValue !== undefined &&
      object.kind?.boolValue !== null
    ) {
      message.kind = { $case: "boolValue", boolValue: object.kind.boolValue };
    }
    if (
      object.kind?.$case === "structValue" &&
      object.kind?.structValue !== undefined &&
      object.kind?.structValue !== null
    ) {
      message.kind = {
        $case: "structValue",
        structValue: Struct.fromPartial(object.kind.structValue),
      };
    }
    if (
      object.kind?.$case === "listValue" &&
      object.kind?.listValue !== undefined &&
      object.kind?.listValue !== null
    ) {
      message.kind = {
        $case: "listValue",
        listValue: ListValue.fromPartial(object.kind.listValue),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(Value.$type, Value);

const baseListValue: object = { $type: "google.protobuf.ListValue" };

export const ListValue = {
  $type: "google.protobuf.ListValue" as const,

  encode(message: ListValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      Value.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListValue {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListValue } as ListValue;
    message.values = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.values.push(Value.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListValue>): ListValue {
    const message = { ...baseListValue } as ListValue;
    message.values = [];
    if (object.values !== undefined && object.values !== null) {
      for (const e of object.values) {
        message.values.push(Value.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(ListValue.$type, ListValue);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
